// Container Breakpoints - Style | Starts
.tt-container {
  width: 100%;
  padding-right: var(--container-padding);
  padding-left: var(--container-padding);
  margin-right: auto;
  margin-left: auto;
}

// ::-webkit-scrollbar {
//     display: none !important;
//     scrollbar-width: none !important;
//     scrollbar-track-color: transparent;
// }

p,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
}

.preview-button,
.delete-button {
  border: none;
  background-color: transparent;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: var(--green);
}

@media (min-width: 576px) {
  .tt-container {
    max-width: var(--container-max-width-sm);
  }
}

@media (min-width: 768px) {
  .tt-container {
    max-width: var(--container-max-width-md);
  }
}

@media (min-width: 992px) {
  .tt-container {
    max-width: var(--container-max-width-lg);
  }
}

@media (min-width: 1200px) {
  .tt-container {
    max-width: var(--container-max-width-xl);
  }
}

@media (min-width: 1440px) {
  .tt-container {
    max-width: var(--container-max-width-xxl);
  }
}

@media (min-width: 1920px) {
  .tt-container {
    max-width: var(--container-max-width-xxxl);
  }
}

.ig {
  height: 1.5em;
  width: 1.5em;
}

.posted_card_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 370px));
  gap: 1rem;
}

.interview_card_container {
  display: grid;
  gap: 20px;
  margin-top: 2rem;
  grid-template-columns: repeat(2, 1fr);
}

.posted-card {
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--border-light);
  transition: border-color 0.3s ease;
  border: 1px solid var(--border-light);
  max-width: 390px;

  &:hover {
    border: 1px solid var(--green) !important;
  }
}

.tooltip {
  z-index: 100000000;
}

// Container Breakpoints - Style | Ends

body>iframe[style*="2147483647"] {
  display: none;
}

.green-link {
  color: var(--green);
  text-decoration: none;
  font-size: var(--link);
  cursor: pointer;

  &-big {
    font-size: var(--p2);
  }

  :hover {
    color: var(--highlight-light);
  }
}

input[type="file"]::-webkit-file-upload-button {
  display: none;
}

.check_container {
  display: block;
  position: relative;
  padding-left: 2.188em;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/*  custom checkbox */
.checkmark {
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25em;
  width: 1.25em;
  margin-top: 3px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.check_container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check_container input:checked~.checkmark {
  background-color: var(--green);
}

.form-check-input {
  height: 1.25em;
  min-width: 1.25em;
}

.form-check-input:checked {
  background-color: var(--green) !important;
  border-color: var(--green);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check_container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check_container .checkmark:after {
  left: 0.563em;
  top: 0.25em;
  width: 0.313em;
  height: 0.625em;
  border: solid white;
  border-width: 0 0.12em 0.12em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* @media only screen and (max-width: 1280px) {
  body{
    font-size: 12px !important;
  }
}
*/

/*
@media only screen and (max-width: 1600px) {
  body{
    font-size: 14px !important;
  }
} */

.ct-group {
  z-index: 9999;
}

.ct-toast-success {
  color: red;
}

.loader {
  height: 100vh;
  width: 100%;
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;

  &.my-profile_loader {
    height: 100% !important;
  }
}

.modal-body {
  .loader {
    height: 100%;
    position: absolute;
  }
}

.page-size-info {
  text-align: center;
}

.overflow-text-dot {
  white-space: pre-line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-wrap: break-word;
}

.truncate-1 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}

.truncate-2 {
  overflow-wrap: anywhere;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  /* number of lines to show */
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.truncate-3 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  /* number of lines to show */
  line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
}

.big-b {
  display: flex;
  align-items: center;
  height: 47px;
  padding: 0 37px;
  font-size: 18px;
}

.eval .form-input-group .input-group input {
  width: 100px;
  padding-left: 0;
}

.eval .btn:hover {
  color: var(--green);
}

.main-card:has(.bookmark) {
  border: 1px solid var(--red);
}

.text-orange {
  color: #fd815e;
}

.text-orange:hover {
  color: #fd815e;
}

.hand-hover {
  cursor: pointer;
  text-decoration: none;
}

.bg-success {
  background-color: var(--green) !important;
}

a.pref-loc {
  color: var(--green) !important;
  text-decoration: none;
  font-weight: 600;
}

.cards__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 290px));
  gap: 1rem;
}

.card__hover {
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.06);
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: #edf2f7;
  padding: 1.25rem;
  transition: border 0.3s ease;
  background-color: var(--white);
  max-width: 290px;

  &:hover {
    border-color: var(--green);
  }
}

.Toastify {
  z-index: 9999999;
  position: relative;
}

.modal-body {
  padding: 0.5rem 1.5rem !important;

  select {
    appearance: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.modal-footer {
  .btn {
    padding: 0.7rem 2rem !important;
  }
}

.card {
  border: none;
}

.progress {
  height: 0.7rem !important;
}

.job__card {
  .job__content {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    min-height: 80px;
  }

  .footer {
    border-top: 1px solid #f4f4f4;
    padding-top: 0.5rem;
  }
}

/* PAGINATION */

.pagination {
  font-size: var(--p1);
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.search__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.search__pagination input {
  width: 50px;
  height: 30px;
  border: 1px solid var(--gray4);
  border-radius: 5px;
  outline: none;
}

.search__pagination p {
  opacity: 0.8;
  font-size: 0.75em;
}

.pagination i {
  font-size: 1.25em;
}

.pagination__active {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  color: #fff;
  background-color: var(--green);
  border-radius: 50%;
}

/* STYLE SHEET NEW DEFINITION */

.heading_1 {
  font-size: 3em;
  font-weight: 600;
  color: var(--black1);
}

.heading_2 {
  font-size: 1.5em;
  font-weight: 600;
  color: var(--black1);
}

.heading_3 {
  font-size: 1.25em;
  font-weight: 400;
  color: var(--gray4);
}

.heading_4 {
  font-size: var(--p1);
  font-weight: 600;
  color: var(--black1);
}

hr {
  margin: 1rem 0 !important;
}

.paragraph_1 {
  font-size: var(--p1);
  font-weight: 700;
  color: var(--black1);
  text-transform: capitalize;
  height: 39px;
}

.paragraph_2 {
  font-size: var(--p1);
  font-weight: 400;
  color: var(--black1);
}

.paragraph_3 {
  font-size: var(--p);
  font-weight: 400;
  color: var(--black1);
}

.paragraph_4 {
  font-size: var(--p);
  font-weight: 400;
  color: var(--black1);
}

.job__card {
  // box-shadow: 0px 3px 15px #3141581f;
  // padding: 15px;
  // border-radius: 18px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .image_container {
    display: flex;
    width: 75%;
    justify-content: space-between;

    .featured__img {
      height: 30px;
      max-width: 70px;
      object-fit: scale-down;
    }
  }

  // gap: 10px;
  // min-height: 230px;
  &:hover {
    .hover-popup {
      opacity: 1;
      bottom: 0;
    }

    .circular-popup {
      opacity: 1;
      right: 4px;
    }
  }

  .circular-popup {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    border: 1px solid var(--gray5);
    padding: 0.8rem;
    border-radius: 50%;
    opacity: 0;
    justify-content: center;
    align-items: center;
    background: var(--white);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    position: absolute;
    right: -100%;
    bottom: 3rem;
    z-index: 999;

    p {
      font-size: 0;
      font-weight: 600;
      transition: all 0.3s ease-in-out;
      text-wrap: nowrap;
      color: var(--green);
    }

    i {
      font-size: var(--h6) !important;
      color: var(--green) !important;
    }

    &:hover {
      width: 7.5rem;
      border-radius: 25px;
      gap: 0.5rem;

      p {
        font-size: var(--p);
      }
    }
  }

  .job__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-height: 80px;
  }

  .paragraph_1 {
    font-weight: 600;
    font-size: var(--p1);
    height: 39px;
  }

  .paragraph_2 {
    font-size: var(--p);
    font-weight: 400;
    color: #9398a1;
  }

  .paragraph_3 {
    font-size: var(--p);
    font-weight: 400;
    color: #4b8bff;
  }

  .paragraph_4 {
    font-size: var(--p);
    font-weight: 400;
    color: #9398a1;
  }

  .footer {
    border-top: 1px solid #f4f4f4;
    padding-top: 0.5rem;
  }

  .hover-popup {
    position: absolute;
    bottom: -100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: fit-content;
    opacity: 0;
    padding: 0.5rem;
    transition: all 0.4s ease;

    .btn {
      display: flex;
      align-items: center;
      border: 1px solid var(--gray5);
      gap: 0.5rem;
      width: 100%;
      text-wrap: nowrap;
      font-weight: 600;
      padding: 0.3rem !important;
      background-color: var(--white);
      flex-direction: row-reverse;
      margin-inline: auto;
      justify-content: center;

      i {
        font-size: var(--h6);
      }

      &:hover {
        border-color: var(--green);
      }
    }
  }
}

.btn_1 {
  font-size: var(--p1);
  font-weight: 600;
  color: var(--white);
  background-color: var(--green);
  border-radius: 5px;
  padding: 10px 20px;
}

.image_container {
  display: flex;
  width: 75%;
  justify-content: space-between;

  .featured__img {
    height: 30px;
    max-width: 70px;
    object-fit: scale-down;
  }
}

.btn_2 {
  font-size: var(--p1);
  font-weight: 600;
  color: var(--green);
  background-color: var(--white);
  border-radius: 5px;
  padding: 10px 20px;
}

.feature_content {
  width: 100%;
  display: flex;
  flex-direction: column;

  .candidate-card-head {
    display: flex;
  }

  .candidate-card-basic {
    flex: 0.9 1;
    margin-bottom: 0.5rem !important;

    p {
      word-break: break-word;
      margin: 0 !important;
    }
  }

  .circle-rating {
    height: 40px;
    width: 40px;
    flex: 0.2 1;
  }

  p {
    color: var(--gray7);
  }

  .candidate-card-basic {
    flex: 0.9 1;
    margin-bottom: 0.5rem !important;

    p {
      word-break: break-word;
      margin-bottom: 0.4rem;
    }
  }

  .paragraph_1 {
    margin-bottom: 0 !important;
    color: var(--black1);
  }
}

.back-arrow {
  width: 25px;
  margin-right: 15px;
}

.required:after {
  content: " *";
  color: red;
}

.right {
  flex: 1 1 30%;
}

.btn {
  border-radius: 8px !important;
  text-wrap: nowrap;
}

.btn-group {
  overflow-x: auto;
  border: 1px solid var(--gray5);
}

.btn-group .btn {
  border-right: 1px solid var(--gray5) !important;
}

.fixed-content {
  position: fixed;
  display: block;
  z-index: 1;
  width: 100%;
  background-color: white;
  top: 53px;
}

.fixed-content .content {
  width: 83%;
  padding-top: 2em;
  /* margin: auto; */
  padding-bottom: 2em;
}

.form_buttons {
  display: flex;
  float: right;
  gap: 10px;
}

svg.green-done {
  color: var(--green) !important;
  height: 16px;
}

svg.done {
  height: 16px;
}

.fade-btn {
  opacity: 1.5;
}

.green-btnn {
  background-color: var(--green);
  color: var(--white);
  border: 0px;
  outline: none;
  padding: 0.313em 1.25em;
  font-family: "Plus Jakarta Sans", sans-serif;
  border-radius: var(--border-radius);
  font-size: 1.1em !important;
  font-weight: 500;
  white-space: nowrap;
}

.centralize-div {
  margin: 2em 10em;
}

.left-border {
  border-radius: 10px 0px 0px 10px !important;
}

.left {
  flex: 1 1 70%;
}

.no-border {
  border-radius: 0px !important;
}

.right-border {
  border-radius: 0px 10px 10px 0px !important;
}

/* .col-6 {
  width: 50% !important;
} */

.green-text {
  color: var(--green) !important;
}

.font-11 {
  h2 {
    font-size: var(--p) !important;
  }
}

.red-text {
  color: var(--red);
}

.available-cash {
  width: fit-content;
  padding: 0.8rem;
  background: var(--light-green);
  color: var(--green);
  margin-bottom: 0.5rem;
  border-radius: 8px;
  font-size: var(--p1);
}

.unavailable-cash {
  width: fit-content;
  padding: 0.8rem;
  background: var(--light-red);
  color: var(--red);
  border-radius: 8px;
  font-size: var(--p1);
  border: 1px dotted var(--red);
  text-wrap: nowrap;
}

.pagination .arrow {
  color: #000;
  display: flex;
  text-decoration: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.scroll-to-top {
  bottom: 95px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999 !important;
}

@media screen and (max-width: 767px) {
  .scroll-to-top {
    right: 10px !important;
    bottom: 68px !important;
  }
}

.no-scroll {
  &::-webkit-scrollbar {
    display: none;
  }
}

.blue-text {
  color: var(--blue) !important;
}

.white-background {
  background: #fff;
}

.f-700 {
  font-weight: 700 !important;
}

.img-border {
  border: 1px solid var(--gray5) !important;
}

tr {
  box-shadow: 0px 1px 0px #f2f2f2;
}

.list-styl-none {
  list-style-type: none;
}

.centre-div {
  text-align: center;
  align-items: center;
}

.list-group {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-radius: 0px;
}

.list-group::-webkit-scrollbar {
  display: none;
}

.list-group-item.active {
  background-color: white;
  border: none;
  font-size: var(--p1);
  font-weight: 600;
  color: var(--black3);
  border-bottom: 2px solid var(--green);
}

.list-group-item:hover {
  background-color: white;
  border-bottom: 2px solid var(--green);
}

.list-group-item {
  border-radius: 0px !important;
  color: var(--gray8);
  font-size: var(--p1);
  font-weight: 300;
  font-family: "Plus Jakarta Sans", sans-serif;
  border-bottom: 2px solid transparent;
  width: auto;
  margin-right: 37px;
  padding: 0.5em 0px;
}

.sticky {
  position: fixed !important;
  z-index: 12;
}

.sticky-effect {
  margin-left: 20%;
}

.box {
  position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* common */
.ribbon {
  width: 135px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  /* border: 5px solid var(--green); */
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: var(--green);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Plus Jakarta Sans", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  /* top: -10px;
  left: -10px; */
  z-index: 10000;
  position: fixed;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.success {
  color: #14bc9a !important;
}

.warning {
  color: #ffa803 !important;
}

.danger {
  color: #ea3e3e !important;
}

input[type="range"]#customRange3::-webkit-slider-thumb {
  /* box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 30px;
  width: 15px;
  border-radius: 5px; */
  background: var(--green);
  /* cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px; */
}

input[type="range"]#customRange3::-webkit-slider-runnable-track {
  /* width: 100%;
  
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000; */
  /* background: var(--green); */
  height: 6px;
  /* border-radius: 5px;
  border: 1px solid #000000; */
}

.whatsapp_float {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 10px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  font-size: 24px;
}

.whatsapp_float:hover {
  text-decoration: none;
  color: white;
}

.whatsapp-icon {
  margin-top: 9px;
}

.center {
  text-align: center;
}

.no-underline {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 10px;
    right: 10px;
    font-size: 22px;
  }

  :root {
    --modal-title: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .fixed-content .content {
    width: 100%;
  }

  .interview_card_container {
    grid-template-columns: 1fr;
  }
}

*:focus {
  box-shadow: none !important;
}

/* input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
} */

/* Calendat scheldule */

.rbc-header {
  /* background-color: var(--green); */
  /* color: white; */
  font-weight: 600 !important;
  font-size: 1em !important;
  padding: 10px 0px !important;
}

.rbc-time-view .rbc-row {
  min-height: 60px;
}

.rbc-timeslot-group {
  min-height: 60px !important;
}

.rbc-time-view .rbc-allday-cell {
  display: none;
}

.rbc-time-content {
  max-height: 60vh;
  overflow-y: scroll;
  scroll-snap-align: center;
}

.rbc-event {
  background-color: transparent !important;
  border: none !important;
  /* height: auto !important; */
}

.rbc-event-label {
  display: none !important;
}

.rbc-events-container {
  margin-right: 0px !important;
}

.rbc-toolbar {
  gap: 10px;
  justify-content: "flex-start" !important;
}

.rbc-time-content>.rbc-day-slot {
  min-width: 80px;
}

.rbc-time-header {
  width: 100%;
  overflow-x: auto;
}

.rbc-header {
  min-width: 80px;
  background-color: #14bc9a1a;
  color: #14bc9a;
  font-weight: 600 !important;
}

.rbc-btn-group .rbc-active {
  background-color: var(--green) !important;
  color: white !important;
  border-color: transparent;
  font-weight: 600;
}

.rbc-toolbar button:hover {
  color: white !important;
  background-color: var(--green) !important;
}

.rbc-time-slot,
.rbc-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-toolbar-label {
  font-weight: 600;
}

@media only screen and (max-width: 998px) {
  .posted_card_container {
    grid-template-columns: repeat(auto-fit, minmax(310px, 336px));
  }


}

@media only screen and (max-width: 1024px) {
  .rbc-toolbar {
    flex-direction: column !important;

    /* align-items: start !important; */
  }

  .card__hover,
  .view-similar {
    max-width: 100% !important;
  }

  .cards__container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media screen and (min-width: 1200px) and (max-width: 1450px) {
  .cards__container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 266px));
  }

  .posted_card_container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 360px));
  }
}

/* Calendar end */

.loader_container {
  position: fixed;
  z-index: 9999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.skill_suggestion_box {
  padding: 0;

  background: white;
  z-index: 200;
  padding: 0px;
  max-height: 150px;
  overflow-y: scroll;
  box-shadow: 0px 7px 8px -4px lightgrey;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.skill_suggestion_box p {
  padding: 10px;
  border-bottom: solid 2px #f5f6f7;
}

.highlight {
  background-color: var(--highlight-light);
}

.ai-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: var(--green) !important;
  width: auto;
  font-size: 0.6em !important;
  margin-right: 6px;
  margin-top: 0.313em;
  padding: 0.1em 0.25em;
}

.ql-editor {
  height: 150px;
  max-height: 150px;
  overflow: auto;
}

// React Big Calendar Overwrite
.calendar__wrapper {
  .rbc {
    &-btn-group {
      button {
        font-size: var(--p);
      }
    }
  }
}

input {
  overflow: auto !important;
}

// --------- pagination----------
.page-link {
  color: var(--green);
  border-radius: 4px;
  border: 1px solid var(--border-green);
  padding: 0.375rem 0.85rem !important;
}

.pagination .disabled {
  cursor: not-allowed;
}

.page-link:hover {
  z-index: 2;
  color: var(--green);
  background-color: var(--light-green);
  border-color: var(--border-green);
}

.page-item.active .page-link {
  background-color: var(--green);
  border-color: var(--green);
}

.mt-45 {
  margin-top: 45px;
}

.hover-green:hover {
  border-color: var(--green);
}

.modal-title {
  font-size: var(--h4);
}

.modal-header .btn-close {
  padding: 0.3rem 0.3rem !important;
  width: 0.3rem;
  height: 0.3rem;
  margin: 0.5rem 0.8rem 0.5rem auto;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-footer {
  border-top: 0 !important;
}

.sugg_p:hover {
  background-color: var(--gray2);
}


.mx-550 {
  max-width: 550px;
}