// register as a recruiter independent of Register.scss style
// common

// as a candidate only

// as a recruiter only

// register-steps
.registration-section {
  display: flex;
  flex-direction: column;
  &-header {
      display: flex;
      justify-content: space-between;
    align-items: start;
  }
  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &-logo {
        margin-bottom: 20px;
    }
    &-content {
        .title {
        margin-bottom: 20px;
        font-size: 22px;
      }
      .otp-sec {
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        gap: 8px;
        justify-content: center;
        .otp{
             display: inline-block;
          width: 50px;
          height: 50px;
          text-align: center;
          border-radius: 8px;
          padding: 10px 8px 10px 8px;
          border: 2px solid var(--green);
          color: var(--green);
          font-size: 2em;
          font-weight: 500;
          outline: none;
          
        }
      }
    }
}


}




