.forgot_container {
  // overflow-y: hidden;
  // overflow-y: hidden;
  width: 100% !important;
  display: flex;
  flex-direction: column;

  .visibility {
    // visibility: hidden;
    // position: absolute;
  }

  .main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    max-height: 100vh;
  }

  /* container */

  .main .left {
    flex: 0.2855 1;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    // background-color: var(--gray3);
    height: 100vh;
    max-height: 87vh;
    justify-content: center;
  }

  .left .cover_image {
    width: 82%;
    margin: auto auto 40px 10%;
  }

  .main .right {
    overflow-y: auto;
    flex: 1;

    .right_content {
      width: 100%;
    }
  }

  .content {
    font-size: 1.5em;
    font-weight: bold;
    color: var(--black2);
    text-align: center;
    margin: 0 20px 0px 20px;
  }

  .content_heading {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 10%;
  }

  .content span {
    margin-top: 68px;
    margin-left: 0px;
    font-weight: bold;
    color: var(--green);
  }

  .content-button {
    border: 1px solid;
    font-family: "Plus Jakarta Sans", sans-serif;
    color: var(--green);
    border-radius: 8px !important;
    font-size: var(--p1);
    font-weight: 600;
    margin: 2% 5% 30px 10%;
    padding: 0.625em;
    width: 78%;
  }

  .right {
    display: flex;
  }

  .right::-webkit-scrollbar {
    display: none;
  }

  .form-floating>label {
    margin-left: 0px;
    font-size: var(--p1);
    font-weight: 500;
    color: var(--black2);
    padding: 0.6rem 0.75rem;
  }

  .form-floating>.form-control,
  .form-floating>.form-select {
    height: 2.8em;
    line-height: 1.25;
  }

  .form-floating>.form-control:focus~label,
  .form-floating>.form-control:not(:placeholder-shown)~label,
  .form-floating>.form-select~label {
    opacity: 1;
    font-size: 0.94em;
    font-weight: 400;
    color: var(--gray8);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }

  .form-floating>.form-control {
    margin-left: 0px;
    background: transparent;
    border: 0px;
    box-shadow: none;
    font-size: 0.875em;
  }

  .input_section {
    margin: 12px 0px 5px 0px;
    background-color: var(--gray3);
    border-radius: var(--border-radius);
    display: flex;
    height: 4em;
    flex-direction: row;
    align-items: center;
  }

  .input_icon {
    margin: 0 10px;
    cursor: pointer;
  }

  .input_icon img {
    width: 1.25em;
  }

  .input_section .form-floating {
    width: 100%;
  }

  #checkbox1 {
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }

  #checkbox_div {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    p {
      font-size: var(--p);
      color: #000000;
    }
  }

  #checkbox_div img {
    width: 0.75em;
    margin-right: 5px;
  }

  #checkbox_div label {
    font-size: 1.25em;
    color: var(--black2);
    font-weight: 400;
  }

  .register_txt {
    a {
      font-size: 0.875em;
      text-decoration: none;
      font-weight: 500;
      color: var(--green);
    }
  }

  .form_button {
    background-color: var(--green);
    color: var(--white);
    border-radius: var(--border-radius);
    // font-size: 0.875em;
    // font-weight: 500;
    // border: 0px;
    // padding: 1em;
    width: 100%;
  }

  .register_txt {
    text-align: center;

    img {
      width: 0.875em;
    }
  }

  footer {
    position: fixed !important;
    bottom: 0px;
    width: 100vw;
    background-color: var(--black4);
    color: var(--white);
    font-size: var(--p1);
    text-align: center;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
  }

  @media only screen and (max-width: 990px) {
    // overflow-y: scroll;

    .navbar {
      min-height: 55px;
    }

    .navbar-collapse {
      padding: 15px 0;
    }

    body {
      overflow: scroll;
    }

    .content-button {
      width: auto;
    }

    .right_content {
      width: 80%;
    }

    footer {
      position: relative;
    }

    #checkbox1 {
      width: 12px;
      height: 12px;
      margin-right: 12px;
    }

    .content-button {
      width: auto;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .main {
      .left {
        flex-direction: unset;
        align-items: center;
        flex: 0.35 1;
      }

      .content-button {
        margin: 2% 5% 30px 20%;
      }

      .right_content {
        margin: 50px auto auto 25%;
      }

      .mobile-text {
        display: block !important;
      }

      .social {
        // margin: 24% 0% 0 auto !important;
        display: none;
      }
    }

    .input_section {
      margin: 12px 0px;
    }

    .body-links {
      font-size: 1.1em;
    }

    .content,
    .content_heading {
      font-size: 1.25em;
    }

    .content-button {
      font-size: 0.86em;
    }
  }

  @media only screen and (max-width: 767px) {
    max-height: none !important;

    .main {
      flex-direction: column;
      max-height: none !important;
    }

    .left {
      .cover_image {
        width: 68%;
        height: 60%;
        margin: 0px auto 20px auto;
        padding: 0 20px;
      }

      .left_content {
        margin: 0 auto;
        margin-bottom: 2rem;
        text-align: center;
      }
    }

    .content,
    .content_heading {
      font-size: 1.8em;
    }

    .content {
      margin: 15px 15px 0 15px;
      text-align: center;
    }

    .content {
      margin: 15px 15px 0 35px;
      text-align: center;
      width: 80%;
      font-size: 1.2em;
    }

    .content-button {
      width: 80%;
      margin: 15px 15px 10px 15px;
    }

    .right_content {
      margin: 20px auto;
      width: 80%;
    }

    .form-control {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .Forget_page {
    height: unset;
  }
}