.no-padding-row {
  padding: 0;
  margin: 0;
}


.no-result {
  width: 100vw;
  font-size: 16px;
  display: flex;
  align-items: start;
  justify-content: center;
  // padding-top: 18.438em;
}

.btn-primary-hover {
  background: var(--green);
}

// .search-type {
//   display: flex;
//   align-items: center;
// }

// .search-sub-type {
//   display: flex;
//   align-items: center;
// }

// .search-sub-type-main {
//   display: flex;
//   align-items: center;
// }

.common_items {
  .upload_cv {
    display: flex;
    justify-content: center;
    padding: 5em;
    border: 3px dotted #dbdbdb;
    border-radius: 1.25em;
    margin-bottom: 2.5em;
    // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23DBDBDBFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 0.625em;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    img.cover-pic-preview {
      width: 100% !important;
      margin-right: 0px !important;
      margin-left: 9em;
    }

    img {
      width: 1.25em;
      margin-right: 0.625em;
    }
  }

  .upload_cv {
    div {
      margin: 0 1.25em;
    }
  }

  .upload_resume {
    position: relative;
    margin: 0 0.5em;

    input {
      position: absolute;
      opacity: 0;
      width: 100%;
    }

    a {
      font-size: 1.125em;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-weight: 600;
      color: var(--green);
      text-decoration: none;
    }
  }

  .setDeleteClass {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px 10px 0 0;
    background-color: #efefef;
    border-radius: 50%;
    padding: 8px;
    width: 40px;
    height: 40px;
  }

  .profile_img {
    width: 5.625em;
    height: 5.625em;
    border-radius: 50%;
    margin-right: 20px;
    background-color: var(--gray2);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .upload_picture {
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      width: 100%;
    }
  }

  .profile_img img {
    width: inherit;
  }

  .input-group {
    border: 1px solid var(--gray5);
    border-radius: var(--border-radius);
    width: 90%;
    display: flex;
    background-color: white;
  }

  /// Table css

  tbody {
    border-top: none !important;
  }

  .table> :not(caption)>*>* {
    border: 0px;
    padding: 0.8em 1.5em;
    vertical-align: middle;
  }

  td.bold {
    font-weight: 600;
  }

  td {
    font-size: var(--p);
    font-weight: 500;
    color: var(--gray8);
  }

  th {
    font-size: var(--p1);
    color: var(--black5);
    font-weight: 600;
    white-space: nowrap;
  }

  .results {
    cursor: pointer;
    border-left: 1px solid var(--input-border);
  }

  .results:hover {
    background-color: var(--gray2);
    border-left: 3px solid var(--green);
  }

  .hover {
    cursor: pointer;
    border-left: 1px solid var(--input-border);
  }

  .hover:hover {
    background-color: var(--gray2);
    border-left: 3px solid var(--green);
  }
}

.common {
  .footer_upper {
    font-size: 16px;
    margin-top: 100px;
    position: fixed !important;
    bottom: 0;
    width: 100%;
  }
}

.blank_content {
  flex-direction: column;
  align-items: center;
  margin: 8.6em 0;
  // top: -140px;
  padding-top: 18.438em;

  position: relative;
  display: block;
  text-align: center;

  img {
    width: 3.125em;
  }

  h2 {
    font-size: 1.5em;
    font-weight: 600;
    color: #263238;
    margin: 0.75em;
  }

  p {
    font-size: 1.125em;
    font-weight: 500;
    color: #9398a1;
  }
}

.go-home {
  background-color: var(--green);
  color: var(--white);
  flex: 0.15 1;
  padding: 0.25em 0.5em;
  border: 0px;
  font-size: var(--p1);
  font-weight: 300;
  border-radius: var(--border-radius);
}

span.css-yrmx0p {
  margin: auto !important;
  top: 40%;
}

#overlay {
  position: fixed;
  width: 100%;
  height: 50%;
  // top: 350px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 3000;
  cursor: pointer;
  box-shadow: 5px 5px 5px 5px;
}

body {
  &#overlay {
    overflow-y: hidden;
  }

  // & p.message-error {background-color: yellow}
}

#text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.overlay__body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.right-align {
  display: flex;
  justify-content: flex-end;
  right: 30px;
  top: 15px;
  position: absolute;
}

.paywall-body {
  background-color: white;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100vw;
  font-size: 16px;
  z-index: 200;
  position: relative;

  // @at-root body {
  //   overflow: hidden;
  // }

  .body {
    display: flex;
    width: 75%;
    margin: auto;

    .left {
      flex: 0.5;
      margin: auto;

      img {
        width: 80%;
      }

      h2 {
        font-size: 2.125em;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 600;
        margin-bottom: 0.625em;
      }

      p {
        font-size: 1.063em;
        color: #9498a0;
        font-weight: 500;
        width: 85%;
      }

      ul {
        list-style: none;
        padding: 0px;
        margin: 40px 0;

        li {
          display: flex;
          margin: 1.25em 0;

          img {
            width: 1.5em;
            margin-right: 1.25em;
          }

          p {
            font-size: 1.188em;
            color: #263238;
            font-weight: 600;
          }
        }
      }
    }

    .right {
      flex: 0.5;
      padding: 30px 0;

      span.blue-text {
        color: var(--green);
      }

      button.form_button_login {
        margin-right: 20%;
        margin-top: 40px;
        margin-bottom: 10px;
        background-color: var(--green);
        color: var(--white);
        border-radius: var(--border-radius);
        font-size: 1.375em;
        font-weight: 500;
        border: 0px;
        padding: 0.625em;
        width: 80%;
      }

      button.form_button_register {
        border: 1px solid var(--green);
        color: var(--green);
        padding: 0.625em;
        border-radius: var(--border-radius);
        font-weight: 500;
        font-size: 1.125em;
        width: 80%;
        background: white;
      }

      h2 {
        width: 80%;
        font-size: 2.125em;
        font-weight: 600;
        font-family: "Plus Jakarta Sans", sans-serif;
      }

      .row {
        width: 80%;
        margin: 0.625em auto;

        .col {
          padding: 0.425em;
          display: flex;
          flex-direction: column;

          label {
            .red {
              color: #ea3e3e;
            }

            font-size: 1.125em;
            color: #2c2c2c;
            font-weight: 500;
          }

          input {
            border: 1px solid #dbdbdb;
            border-radius: 0.625em;
            box-shadow: none;
            height: 3.125em;
          }

          textarea {
            border: 1px solid #dbdbdb;
            border-radius: 0.625em;
            font-size: 1.125em;
            font-weight: 500;
            padding: 0.625em;
          }

          button {
            background-color: var(--green);
            color: var(--white);
            border: 0px;
            outline: none;
            padding: 7px 20px;
            font-family: "Plus Jakarta Sans", sans-serif;
            border-radius: 0.625em;
            font-size: 1.375em;
            font-weight: 500;
            margin-right: auto;
          }
        }
      }
    }

    input.PhoneInputInput {
      border: none !important;
      outline: none;
      background-color: #fff;
      box-shadow: none !important;
      height: 3.25em !important;
      font-size: 1.125em;
      font-weight: 500;
      color: #11263c;
    }

    .PhoneInputCountry {
      background: #fff;
    }

    .custom-tel {
      margin-bottom: 1.5em !important;
      border: 1px solid var(--gray5);
      border-radius: var(--border-radius);
    }
  }

  @media only screen and (max-width: 800px) {
    overflow-y: scroll !important;

    .body {
      flex-direction: column;
      // margin: 10px auto;
      width: 90%;
      height: fit-content;

      .left {
        height: fit-content;
        flex: none;
        margin: 0;
      }

      .right {
        flex: none;

        h2 {
          width: 90%;
        }

        .row {
          width: 90%;
        }
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;

    .sticky-search-bar-disabled {
      .banner_search {
        .b_s_search {
          .search_bar {
            .search_input {
              input {
                width: 50px !important;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }
}

@media only screen and (max-width: 800px) {
  .blank_content {
    margin: 20% 0 0 0;
  }
}

@media only screen and (max-width: 990px) {
  .nav-logo-div {
    width: 100%;
    padding: 6px 0;
  }

  // .logged-nav-menu-phone {
  //   padding: 0 1em !important;
  // }
}

.logged-nav-menu-phone {
  z-index: 1010 !important;
}

// JOB CARD
.box-hover {
  transition: all 0.4s;
  border: 1px solid transparent;
  // box-shadow: 0px 3px 15px #3141581f;

  // &:hover {
  //   border: 1px solid #14bc9a;
  // }
}

.bookmark {
  position: absolute;
  top: 0;
  right: 5px;
  width: fit-content !important;

  svg {
    filter: drop-shadow(3px 3px 2px #fd815e96);
  }
}

.container__box .job__card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 350px;

  .image_container {
    display: flex;
    width: 75%;
    justify-content: space-between;

    .featured__img {
      height: 30px;
      max-width: 70px;
      object-fit: scale-down;
    }
  }

  // gap: 10px;
  // min-height: 230px;
  &:hover {
    .hover-popup {
      opacity: 1;
      bottom: 0;
    }

    .circular-popup {
      opacity: 1;
      right: 4px;
    }
  }

  .circular-popup {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    border: 1px solid var(--gray5);
    padding: 0.8rem;
    border-radius: 50%;
    opacity: 0;
    justify-content: center;
    align-items: center;
    background: var(--white);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    position: absolute;
    right: -100%;
    bottom: 3rem;
    z-index: 999;

    p {
      font-size: 0;
      font-weight: 600;
      transition: all 0.3s ease-in-out;
      text-wrap: nowrap;
      color: var(--green);
    }

    i {
      font-size: var(--h6) !important;
      color: var(--green) !important;
    }

    &:hover {
      width: 7.5rem;
      border-radius: 25px;
      gap: 0.5rem;

      p {
        font-size: var(--p);
      }
    }
  }


  .job__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-height: 80px;
  }

  .paragraph_1 {
    font-weight: 600;
    font-size: var(--p) !important;
    height: 39px !important;
  }

  .paragraph_2 {
    font-size: var(--p0);
    font-weight: 400;
    color: #9398a1;
  }

  .paragraph_3 {
    font-size: var(--p0);
    font-weight: 400;
    color: #4b8bff;
  }

  .paragraph_4 {
    font-size: var(--p0);
    font-weight: 400;
    color: #9398a1;
  }


  .footer {
    border-top: 1px solid #f4f4f4;
    padding-top: 0.5rem;
  }

  .hover-popup {
    position: absolute;
    bottom: -100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: fit-content;
    opacity: 0;
    padding: 0.5rem;
    transition: all 0.4s ease;

    .btn {
      display: flex;
      align-items: center;
      border: 1px solid var(--gray5);
      gap: 0.5rem;
      width: 100%;
      text-wrap: nowrap;
      font-weight: 600;
      padding: 0.3rem !important;
      background-color: var(--white);
      flex-direction: row-reverse;
      margin-inline: auto;
      justify-content: center;

      i {
        font-size: var(--h6);
      }

      &:hover {
        border-color: var(--green);
      }
    }
  }
}

.read-or-hide {
  font-weight: bold;
  color: #0c2f55;
}

.paragraph_3_name {
  font-size: var(--p);
  font-weight: 400;
  width: 10em !important;
  color: var(--black1);
}

// .search-type {
//   label {
//     margin: 2px 20px 0 7px;
//     color: #9398a1;
//     font-size: 0.875em;
//     font-weight: 500;
//   }
// }

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
/* iPhone X,
  XS,
  11 Pro and 12 Pro */
only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3),
/* iPhone XS Max,
  11 Pro Max and 12 Pro Max */
only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
/* iPhone XR,
  11 and 12 mini */
only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)
/* iPhone 6/6s/7/8 and SE (2nd generation) */
  {

  /* CSS styles specific to iPhones */
  .sticky-search-item {
    margin-bottom: 3rem !important;
  }
}

// @media only screen and (max-width: 768px) {
//   .search-type {
//     margin-top: 130px !important;
//     display: block;
//   }

//   .candidate_page {
//     .search-type {
//       margin-top: 10px !important;
//       display: block;
//     }
//   }

//   .job_page {
//     .search-type {
//       margin-top: 10px !important;
//       display: block;
//     }
//   }

//   .search-sub-type-main {
//     margin-top: 10px !important;
//     display: flex;
//     flex-wrap: wrap;
//   }

//   .search-sub-type {
//     margin-top: 10px !important;
//     display: flex;
//   }
// }

// .sticky-search-bar-home {
//   padding-top: 0px !important;
//   width: 45% !important;

//   .sticky-search-bar {
//     width: 25vw !important;
//     border-radius: 40px !important;
//     height: 34px;

//     .search_bar {
//       width: 10vw !important;
//     }

//     .location-search-bar {
//       width: 10vw !important;
//       max-width: 10vw !important;
//     }

//     .b_s_search {
//       width: 25vw;
//       border-radius: 40px !important;
//     }

//     .btn-search {
//       width: 75% !important;
//       border-radius: 25px !important;
//       height: 25px !important;
//       margin-top: 4px !important;

//       img {
//         margin-right: 0px !important;
//       }
//     }
//   }
// }

.sticky-search-bar-disabled {
  // pointer-events: none;
  cursor: pointer;
  // opacity: 0.4;
  display: flex;

  .banner_search {
    .b_s_search {
      display: flex;
      border: 1px solid #e0e0e0;

      border-radius: 40px;

      .divider {
        border-right: 1px solid #e0e0e0;
      }

      .search_bar {
        cursor: pointer;
        flex: 1 1;
        padding: 0 10px;
        margin-right: 0px !important;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .search_input {
          display: flex;
          gap: 10px;
          width: 100%;

          input {
            width: 80px;
          }

          img {
            height: 16px;
          }
        }
      }

      .btn_search_bar {
        background-color: var(--green);
        border-radius: 40px;
        margin: 5px 5px 5px 0;
        height: fit-content;

        .btn {
          height: fit-content;
          padding: 5px 10px !important;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 14px !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .b_s_search {
      flex-direction: row;
    }

    .search_bar {
      padding: 0 5px !important;

      .search_input {
        input {
          width: 30px !important;
        }
      }
    }
  }
}

// GIVEAWAY MODAL
.giveaway_modal {
  z-index: 1200 !important;
}

.giveaway_modal_content {
  // z-index: 1200 !important;
  // border-radius: 8px;
  min-height: auto;
  width: auto;
  height: auto;
  /* min-height: 80%; */
  // background: white;
  margin: auto;
  z-index: 1000;
  position: fixed;
  translate: transform(-50%, -50%);
  /* bottom: 10%; */
  font-size: 16px;
  /* max-height: 80%; */
  overflow-y: auto;
  // padding: 10px;

  .modal_body {
    position: relative;
    width: 100% !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;

    // padd;
    .input-lebel {
      margin: 0.5rem 0;
    }
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  .giveaway-img {
    display: flex;
    // min-height: 500px;
    // height: 600px;
    max-width: 600px;
    align-items: center;
    justify-content: center;
    width: auto;
    object-fit: contain;
    // aspect-ratio: 1/1;
  }

  .btn-primary {
    font-size: var(--p1);
    font-weight: 500;
    padding: 8px 16px;
    background-color: var(--green);
    border: 0;
    outline: 0;
    white-space: nowrap;
    // margin: 10px 5px;
  }

  @media only screen and (max-width: 767px) {
    .giveaway-img {
      // min-height: 300px;
      // height: 300px;
    }
  }
}

/// REACT DATEPICKER

.react-datepicker-popper {
  z-index: 9999 !important;
}

.input-icon {
  width: var(--input-icon);
}