.user_chat {
    // padding-top: 5.75em;
    // padding-bottom: 2em;
    // max-height: 100vh;
    // overflow-y: hidden;
    // margin-right: 60px;
    // margin-left: 70px;


    .content {
        // width: 90%;
        margin: auto;
        display: flex;
        border-radius: 20px;
        box-shadow: 0px 3px 24px #00000019;
        min-height: 80vh;
        height: auto;
        max-height: 100vh;


        .left {
            padding: 20px 10px;
            overflow-y: scroll;
            background: #F8F8F8;
            flex: 1 1 30% !important;
            margin: 10px 0;
            border-radius: 15px 0 0 15px;

            .chat_profile {
                cursor: pointer;
                padding: 5px;
                border-radius: 4px;
                margin-bottom: 20px;

                &.active {
                    background: var(--light-green);
                }
            }

            .chat_username {
                span {
                    color: var(--gray4);
                }

                p {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .chat_preview {
                position: relative;
                padding-bottom: 5px;

                p {
                    color: var(--gray4)
                }



                i {
                    margin-left: auto;
                }

                .chat__unread__count {
                    background: var(--red);

                    padding: 5px 10px;
                    border-radius: 5px;
                    margin-left: auto;
                    position: absolute;
                    right: 0;
                    top: 0;

                    p {
                        font-size: 12px;
                        font-weight: 600;
                        color: white;
                    }
                }
            }
        }

        .right {
            padding: 0 20px;
            display: flex;
            position: relative;
            flex: 1 1 70%;

            .message_box {
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;

                .message_box__header {
                    display: flex;
                    padding: 10px 0;
                    justify-content: space-between;
                    // position: absolute;
                    width: 100%;
                    border-bottom: solid 1px var(--gray3);
                }

                .message_box__body {
                    position: relative;
                    overflow-y: scroll;
                    height: 100%;
                    max-height: 100%;

                    .message {
                        display: flex;
                        margin: 20px 0;
                        gap: 10px;
                        max-width: 60%;


                        &.other {
                            justify-content: flex-start;
                            align-items: flex-start;
                            margin-right: auto;

                            .message__content__text {
                                background-color: var(--gray2);
                            }
                        }

                        &.me {
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-direction: row-reverse;
                            margin-left: auto;
                            margin-right: 20px;

                            .message__content__image {
                                margin-left: auto;
                                width: fit-content;
                            }

                            .message__content__text {
                                background-color: var(--green);
                                margin-left: auto;

                                p {
                                    color: white;
                                }

                                a {
                                    color: white;
                                    text-decoration: none;
                                }
                            }

                            .message__profile {
                                justify-content: flex-end;
                            }
                        }

                        .message__content {
                            width: 100%;
                        }


                        .message__profile {
                            display: flex;
                        }

                        .message_box__header {
                            display: flex;

                        }

                        .message__profile {
                            align-items: center;
                            font-size: 0.8125em;

                            p {
                                font-weight: 600;
                            }

                            span {
                                color: var(--gray4);
                                font-size: 0.68em;
                                margin-left: 10px;
                            }
                        }

                        .message__content__text {
                            padding: 5px 10px;
                            border-radius: 5px;
                            width: fit-content;

                            p {
                                font-size: 0.875em;
                                font-weight: 600;
                            }

                            a {
                                font-size: 14px;
                                font-weight: 600;
                                text-decoration: none;
                                color: black;
                            }
                        }

                        .media-message {
                            border: 1px solid #ECEEF5 !important;
                            border-radius: var(--border-radius);
                            margin: 2px;
                            width: 75%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 10px;

                            .media-pdf-message {
                                display: flex;
                                align-items: center;
                            }

                            .media-message-img {
                                width: 50px;
                            }

                            .media-message-text {
                                padding-left: 15px;
                            }

                            .media-message-text span {
                                font-size: small;
                                font-weight: 700;
                                color: #1A1C1D;
                            }

                            .media-message-text p {
                                font-size: small;
                                color: #8083A3;
                            }

                            .media-message-download img {
                                width: 40px;
                            }
                        }

                        .message__content__image {
                            padding: 5px;



                            img {
                                width: 100px;
                                height: 100px;
                                object-fit: cover;
                                border-radius: 8px;
                            }
                        }

                        .message__content__doc {
                            width: 100%;
                            display: flex;
                            border: solid 1px var(--gray5);
                            border-radius: 8px;
                            padding: 10px;
                            gap: 10px;

                            .icon {
                                padding: 10px;
                                border-radius: 8px;
                            }

                            button {
                                border: solid 1px var(--gray5);
                                margin-left: auto;
                                border-radius: 8px;

                                i {
                                    font-size: 18px;
                                }
                            }
                        }



                    }
                }

                .message_box__footer {
                    display: flex;
                    flex-direction: column;
                    padding: 10px;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    border-top: solid 1px #ECEEF5;

                    .message__content__image {
                        display: inline-block;
                        margin-left: 10px;
                    }

                    input {
                        width: 100%;
                        border: 0;
                        padding: 10px;
                        border-radius: 8px;
                        font-size: 0.875em;

                        &:focus {
                            outline: solid 1px var(--green);
                        }
                    }

                    .send__action {
                        display: flex;
                        width: fit-content;
                        gap: 15px;
                        padding-left: 10px;
                        align-items: center;
                        justify-content: center;

                        i {
                            color: var(--gray4);
                            font-weight: 600;
                            font-size: 16px;
                        }

                        button {
                            display: flex;
                            background-color: var(--green) !important;
                            padding: 6px 10px;
                            border-radius: 4px;
                            align-items: center;
                            justify-content: center;
                            background: transparent;
                            border: 0;

                            i {
                                color: white;
                            }
                        }
                    }
                }

                // .back-home-btn {
                //     background-color: var(--green);
                //     color: var(--white);
                //     width: 40%;
                //     padding: 0.625em 1.25em;
                //     border: 0;
                //     font-size: var(--p1);
                //     font-weight: 500;
                //     border-radius: 11px;
                //     text-align: center;
                //     margin: auto;
                //     margin-top: 30px;
                // }
            }
        }
    }

    .chat_content {
        width: 100%;
        padding-left: 20px;
    }
    .empty-msg{
        &-wrapper{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-skeleton{
             width: 30%;
            padding: 20px 10px;
            overflow-y: scroll;
            background: #F8F8F8;
            @media screen and (max-width:768px) {
                display: none;
            }
            
        }
        &-content{
             width: 70%;
            padding: 0 20px;
            max-height: 100%;
           
        }

    }

    @media only screen and (max-width:1440px) {
        .chat_content {
            width: 80%;
            padding-left: 5px;
        }
    }

    @media only screen and (max-width:1024px) {
        .chat_content {
            width: 80%;
            padding-left: 5px;
        }
    }

    @media only screen and (max-width:1024px) {
        .chat_content {
            width: 70%;
            padding-left: 5px;
        }
    }

    @media only screen and (min-width: 768px) {
        .chat-back-arrow {
            display: none;
        }

        .view-job-mob {
            display: none;
        }

    }

    @media only screen and (min-width:768px) and (max-width: 1199px) {
        margin-right: 15px;
        margin-left: 15px;

        .content {
            width: 100%;

            .left {
                width: 40%;

                .chat_profile .icon img {
                    width: 30px !important;
                    height: 30px !important;
                }

                .chat_content p {
                    font-size: 14px;
                }

                .chat_content {
                    width: 80%;
                }
            }

            .right {
                width: 60%;

                .message_box .message_box__body .message {
                    max-width: 70%;
                }

                .message_box .message_box__body .message .message__profile p {
                    font-size: 14px;
                }

                .message_box .message_box__body .message .message__content__text p,
                .message_box .message_box__body .message .message__profile span {
                    font-size: 12px;
                }

                .message__icon img {
                    width: 30px !important;
                    height: 30px !important;
                }

                .message_box .message_box__body .message .media-message .media-message-text span {
                    font-size: 12px;
                }

                .message_box .message_box__body .message .media-message .media-message-img,
                .message_box .message_box__body .message .media-message .media-message-download img {
                    width: 30px;
                }
            }
        }

    }

    @media only screen and (max-width: 767px) {
        margin: 0;
        padding-top: 4em;
        padding-bottom: 0;

        .view-job-desk {
            display: none;
        }

        .view-app-mob {
            margin-left: 10px;
            font-size: 14px;

            h6 {
                margin-left: 0 !important;
                font-size: 16px;
            }
        }

        .content {
            .left {
                width: 100%;

                .chat_content .chat_username p {
                    font-size: 16px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .chat_username span {
                    font-size: 14px !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .chat_preview p {
                    font-size: 12px !important;
                }

                .chat_content {
                    width: 85%;
                }

                .chat_profile .icon img {
                    width: 30px !important;
                    height: 30px !important;
                }
            }

            .right {
                width: 100%;
                display: none;
                padding: 0;
                flex: 1 1 70% !important;

                .message_box .message_box__body .message {
                    max-width: 100%;
                }

                .message_box {
                    height: 100%;

                    .message_box__header {
                        box-shadow: 1px 5px 6px rgba(213, 213, 213, 0.6);
                        padding: 10px 20px;
                    }

                    .message_box__body {
                        padding: 0 20px;
                    }
                }

                .message_box .message_box__body .message .message__profile p {
                    font-size: 14px;
                }

                .message_box .message_box__body .message .message__content__text p,
                .message_box .message_box__body .message .message__profile span {
                    font-size: 12px;
                }

                .message__icon img {
                    min-width: 30px !important;
                    width: 30px !important;
                    height: 30px !important;
                }

                .message_box .message_box__body .message .media-message .media-message-text span {
                    font-size: 10px;
                }

                .message_box .message_box__body .message .media-message .media-message-img,
                .message_box .message_box__body .message .media-message .media-message-download img {
                    width: 20px;
                }

                .message_box .message_box__body .message .media-message {
                    padding: 8px;
                }
            }
        }
        
        .content {
            width: 100%;
            margin: auto;
            display: flex;
            border: none;
            border-radius: 0px;
            // box-shadow: none;
            min-height: 80vh;
            max-height: 80vh;
        }
    }

    @media only screen and (max-width: 374px) {
        .content {
            .left {
                .chat_content .chat_username p {
                    font-size: 14px;
                }

                .chat_username span {
                    font-size: 12px !important;
                }

                .chat_preview p {
                    font-size: 12px !important;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px var(--gray5);
        border-radius: var(--border-radius);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--gray5);
        border-radius: var(--border-radius);
    }

    .dropdown-toggle::after {
        display: none;
    }

    #dropdownMenuLink {
        padding: 0;
    }
}
