.form-input-group {

  // margin-bottom: 0.5rem;
  label {
    font-size: var(--p);
    font-weight: 400;
    color: var(--gray4);
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 0.3rem;
  }

  .input-group {
    border: 1px solid var(--gray5);
    border-radius: var(--border-radius);
    align-items: center;
    flex-wrap: nowrap !important;

    input {
      border: none;
      // font-size: 0.8rem;
      outline: none;
      background-color: transparent;
      box-shadow: none;
      font-size: var(--p1);
      font-weight: 500;
      color: #11263c;
      padding: 8px 12px;
    }

    span {
      // background-color: transparent;
      // border: none;
      // height: 100%;
      //I have commented - it coz date arrow disappear on MyAccount Page Date Picker
    }

    &-text {
      border: none !important;
      background: transparent !important;
    }
}
select{
  font-size: var(--p1);
  padding:.575rem 2.25rem .575rem .75rem !important;
  border: 1px solid #ced4da;
  color: var(--gray8);
}

  input {
    font-size: var(--p1) !important;
  }
}
.disabled-white{
  background-color: transparent !important;
  cursor: not-allowed;
  color: var(--gray4) !important;
  border: none !important;
  outline: none !important;
}
.active-white{
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  outline: none !important;
}