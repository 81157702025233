section {
  padding: 2.5em 0px;
}

.bg_green {
  background: #14bc9a09;
}

.bg_green_absolute {
  position: absolute;
  height: 100%;
  // width: 200vw;
  left: -30%;
  background: #14bc9a09;
  z-index: -200;
}

.bg_container_box {
  position: relative;
}


.branding_logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 10px #3141581f;
  border-radius: 12px;
  opacity: 1;
  height: 83px;
  border: solid 1px #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home {
  width: 100%;
  font-size: 16px;
  padding-top: 3.5em;

  .sticky-search {
    width: 100%;
    background-color: var(--white);
    box-shadow: 0px 1px 5px var(--black);
    // padding: 18px 10em 10px 10rem;
    border: 1px solid #f2f2f2;
    position: fixed;
    z-index: 1000;
    display: block;
    left: 0;
    top: 50px;

    .sticky-search-item {
      display: flex;
      width: 100%;
      flex-direction: column;

      ul {
        grid-gap: 0px !important;
        gap: 0px !important;
        border: none !important;
        border-radius: 8px 8px 0 0;

        li:first-child {
          button.find-btn {
            // border-radius: 8px 0px 0px 8px !important;
          }
        }

        li {
          button.find-btn {
            border: none !important;
            border-radius: 0px !important;
            height: 100%;
            background: #f7f7f7 0% 0% no-repeat padding-box;
          }
        }
      }

      .banner_search {
        box-shadow: none !important;
        border: 1px solid #f2f2f2;
        border-radius: 0px 8px 8px 8px !important;

        .b_s_search {
          width: 100%;
          height: 48px;
        }

        .skill-search-bar {
          width: 45%;
          max-width: 45%;
        }

        .location-search-bar {
          width: 45%;
          max-width: 45%;
        }
      }

      .btn_search_bar {
        padding: 0px !important;

        button {
          border-radius: 0px 8px 8px 0px;
        }
      }
    }
  }

  .bg-color {
    // background: transparent radial-gradient(closest-side at 20% 26%, #FFFFFF 0%, #EFFFFC34 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .light-green-bg {
    background: transparent radial-gradient(closest-side at 20% 26%, #ffffff 0%, #effffc7b 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .section_color {
    background: #ffffff 0% 0% no-repeat padding-box;
  }

  .container__box {
    margin: auto;
    max-width: 1660px;

    .brand_images {
      text-overflow: ellipsis !important;

      .brand-image__card {
        display: inline-block;
        padding: 10px;
        flex: 0 0 auto !important;
        width: 12.5%;
      }
    }


  }

  .banner {
    display: flex;
    position: relative;

    .banner_left {
      padding: 40px 10px;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading_1 {
        text-align: center;
      }
    }

    .banner_right {
      flex: 0.3;
    }
  }

  .section {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .categories__pill {
    margin-top: 30px;

    .categories__pill__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
      padding: 0 20px;

      .categories__pill__container__item {
        padding: 10px;

        &.active {
          border-bottom: 3px solid var(--green);
          color: var(--green);
        }
      }
    }
  }

  .gigs__card {
    box-shadow: 0px 3px 15px #3141581f;
    border-radius: 18px;
    padding: 20px 15px;
  }

  .border-right {
    border-right: solid 1px #dbdbdb;
  }

  .search_bar_div {
    width: 80%;
    margin: auto;

    .search_bar_new {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .search_bar_input_div {
        width: 35%;
        display: flex;

        .search_bar_input {
          display: flex;
          width: 100%;
          border: 1px solid var(--gray5);
          border-radius: var(--border-radius);
          align-items: center;

          .input_icon {
            height: 1.25em;
            margin: 0 10px;
            flex: 0.1 1;
          }

          .pill_box {
            display: flex;
            max-width: 80%;
            overflow: scroll;
          }

          input {
            outline: none;
            height: 100%;
            flex: 0.9 1;
            border: 0px;
            border-radius: 20px;
            font-size: 1.125em;
            font-weight: 500;
          }
        }
      }

      .search_button {
        width: 20%;

        button {
          width: 100%;
        }
      }
    }
  }

  .job__card__section {
    justify-content: center;
  }

  .banner_u {
    display: flex;
  }

  .banner_carousel {
    flex: 0.5;
    width: 50%;
    display: flex;
    margin: auto 0;
  }

  .banner_img {
    flex: 0.5;
    width: 50%;
    display: flex;
  }

  .banner_img img {
    margin: auto;
    width: 45%;
  }

  .missing-info {
    display: flex;
    flex-direction: column;
    padding: 18px 10% 10px 10%;
    background-color: #263238;
    text-align: center;
  }

  .banner_d {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    width: 100%;
    max-width: 992px;
  }

  /* courasel */

  .txt_green {
    color: var(--green);
  }

  .carousel-caption h2 {
    font-size: 2.375em;
    font-weight: 600;
    width: 85%;
  }

  .carousel-caption p {
    font-size: 1.563em;
    font-weight: 300;
    color: var(--gray4);
  }

  .indicators.active {
    background-color: var(--green) !important;
  }

  .indicators {
    margin-right: 10px !important;
  }

  #carouselCaptions {
    min-width: 30%;
    height: fit-content;
    margin-top: 15%;
  }

  .carousel-caption {
    position: relative;
    right: 15%;
    bottom: 1.25rem;
    left: 0%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000;
    text-align: left;
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -15px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 1rem;
    margin-left: 0%;
    list-style: none;
  }

  .swiper-pagination {
    width: fit-content !important;
  }

  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: black;
    background-clip: padding-box;
    border: 0;
    border-radius: 50%;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
  }

  /* banner_down */

  .btn_search_bar {
    // padding: 8px;
    margin-left: auto;
    display: flex;
    width: 10%;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 8px 8px 0px;

      img {
        margin-right: 10px;
        width: 12px;
      }
    }
  }

  .banner_search {
    flex: 1;
    max-width: 100%;
    border-top-left-radius: 0px !important;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #f2f2f2;
    border-radius: 0px 8px 8px 8px;
    height: 48px;
    width: 100%;
  }

  .b_s_search {
    display: flex;
    border-radius: var(--border-radius);
    // width: 57.7vw;
    height: 100%;
    background-color: var(--white);

    &.active {
      border-bottom-left-radius: 0px;
    }

    .tab__pane {
      width: 100%;
    }
  }

  .a label {
    cursor: pointer;
  }

  .search_bar {
    width: 40%;
    position: relative;
    max-width: 40%;
  }

  .skill-search-bar {
    width: 45%;
    max-width: 45%;
  }

  .location-search-bar {
    width: 45%;
    max-width: 45%;
  }

  .b_s_search .search_input {
    height: 100%;
    display: flex;
    border-radius: var(--border-radius);
    align-items: center;
    overflow-wrap: break-word;
    input {
      outline: none;
      height: 100%;
      flex: 0.9 1;
      border: 0px;
      min-width: 10%;
      border-radius: 0px;
      font-size: 0.875em;
      font-weight: 500;
      color: var(--gray4);
    }
  }

  .search_input img {
    height: 1.25em;
    margin: 0 10px;
    flex: 0.1 1;
    // margin-right: 0px !important;
  }

  .btn-search {
    background-color: var(--green);
    color: var(--white);
    // flex: 0%;
    width: 100%;
    padding: 0.85em 1.25em;
    border: 0px;
    font-size: 0.875em;
    font-weight: 500;
    border-radius: var(--border-radius);
  }

  .b_s_find {
    flex-wrap: nowrap;
    width: fit-content;
    display: flex;
    overflow: hidden;
    gap: 2px;
    border: none !important;

    li {
      width: 100%;
    }
  }

  .find-btn {
    width: fit-content;
    border: none;
    background-color: #f7f7f7;
    padding: 10px 38px;
    font-size: var(--p);
    font-weight: 500;
    color: var(--black2);
    white-space: nowrap;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    grid-gap: 20px;
    gap: 20px;
    // border: 1px solid var(--gray5);
  }

  .find-btn.active {
    background-color: var(--green) !important;
    color: var(--white);
  }

  .sec_title {
    font-size: 2.5em;
    font-weight: 600;
    margin-bottom: 0.5em;
    color: var(--black3);
    text-align: center;
  }

  /* BRAND */

  .brand {
    width: 100vw;
    background-color: var(--gray3);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .brand_images {
    width: 100%;
    text-align: center;
  }

  .brand_images a img {
    padding: 0 0.938em;
    max-height: 70px;
    max-width: 160px;
    margin: 1.5em 0;
  }

  /* Featured Jobs */

  .featured_jobs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #featured-3 {
    max-width: none !important;
    width: 90%;
    margin: auto;
    margin-top: 20px;
  }

  .feature {
    border: 1px solid #efefef;
    margin: 10px 0;
    width: 100%;
    padding: 15px;
    cursor: pointer;
    border-radius: 18px;
    display: flex;
    flex-direction: column;

    &:hover {
      box-shadow: 2px 2px 13px var(--gray5);
    }
  }

  .feature_icon {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
    height: 60px;

    span {
      background-color: var(--gray3);
      padding: 5px 10px;
      font-size: 0.875em;
      color: var(--gray8);
      font-weight: 500;
      border-radius: var(--border-radius);
      margin-right: 10px;
      width: fit-content;
      margin-left: auto;
      height: fit-content;
    }
  }

  .feature_icon p {
    flex: 0.8;
    text-align: right;
    font-size: 0.75em;
    color: var(--gray7);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .feature_brand_img {
    top: 0;
    left: 0;
    max-height: 3em;
    max-width: 8.5em;
    width: auto;
    height: auto;
  }

  .featured_brand_icon {
    width: 40px;
  }

  .feature_content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .feature_content_tags {
      flex-wrap: wrap;
      display: flex;

      span {
        margin: 5px 0;
        white-space: nowrap;
      }
    }

    h6 {
      max-height: 7.25em;
      overflow: hidden;
    }
  }

  .feature_buttons {
    justify-content: space-between;
    margin-top: auto;
  }

  .feature_content h2 {
    font-size: 1.25em;
    color: var(--black3);
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
  }

  .feature_content h6 {
    margin-top: auto;
    font-size: var(--p1);
    font-weight: 600;
    color: var(--black3);
    margin-bottom: 1.325em;
  }

  .feature_content p {
    word-break: break-word;
    font-size: var(--p1);
    font-weight: 400;
    color: var(--gray7);
    display: flex;
    margin-bottom: 1.25em;
    overflow: hidden;
    height: 3em;
  }

  .feature_content .feature_content_tags span {
    background-color: var(--gray3);
    padding: 5px 10px;
    font-size: 0.875em;
    color: var(--gray8);
    font-weight: 500;
    border-radius: var(--border-radius);
    margin-right: 10px;
  }

  .feature_buttons button {
    padding: 0.625em 0em;
    font-size: 1.125em;
    font-weight: 500;
    border: 0;
    border-radius: var(--border-radius);
  }

  button.view-details {
    background-color: var(--gray3) !important;
    color: var(--black3) !important;
    margin-left: auto;
  }

  .feature_buttons :nth-child(1) {
    background-color: var(--green);
    color: white;
    width: 46%;
  }

  .feature_buttons :nth-child(2) {
    background-color: var(--gray3);
    color: var(--black3);
    width: 46%;
  }

  /* JOB categories */
  .job_categories {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img.word_cloud {
    width: 50%;
    cursor: pointer;
  }

  .job_items {
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    vertical-align: middle;
    flex-direction: column;
    align-items: center;
  }

  .job_item {
    width: 24%;
    border: 1px solid var(--gray5);
    height: 100px;
    margin: 5px 0.5%;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    text-decoration: none;
    cursor: pointer;
  }

  .job_item:hover p {
    color: var(--green);
  }

  .job_item p {
    margin: auto;
    text-align: center;
    font-size: 1.25em;
    font-weight: 600;
    color: var(--black3);
  }

  .container {
    max-width: 100% !important;
  }

  // .main-div,
  // .hero-header {
  //   padding: 0px 10rem;
  // }

  /* footer */

  footer {
    margin-top: 30px;
    background-color: var(--black4);
    color: var(--white);
  }

  .div_footer h5 {
    font-size: 1.25em;
    font-weight: 500;
    color: var(--white);
  }

  .div_footer .nav li a {
    font-size: 17px;
    font-weight: 300;
    color: var(--white) !important;
    opacity: 0.6;
  }

  .footer_btm {
    border-top: 1px solid var(--gray8);
    font-size: var(--p1);
  }

  .f_t_box {
    display: flex;
    max-width: 90%;
    height: 100;
    width: fit-content !important;
    align-items: center;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .f_t_box::-webkit-scrollbar {
    display: none;
  }

  .f_t {
    display: flex;
    align-items: center;
    background-color: var(--light-blue);
    width: fit-content;
    padding: 3px 1.25em;
    border-radius: 20px;
    margin-right: 0.938em;
    white-space: nowrap;
  }

  .f_t p {
    color: var(--green);
    font-size: var(--p1);
    font-weight: 500;
    margin-right: 0.75em;
    margin-bottom: 0;
  }

  .f_t img {
    width: 10px;
    margin: 0px;
  }

  .del_jt {
    background: none;
    border: none;
  }

  .suggestion_box {
    padding: 0;
    position: absolute;
    background: white;
    z-index: 200;
    padding: 0px;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    // margin-top: -10px;
    box-shadow: 0px 7px 8px -4px lightgrey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &.active {
      border-top: solid 2px #dbdbdb;
    }

    p {
      padding: 10px;
      border-bottom: solid 2px #f5f6f7;
      cursor: pointer;

      &:hover {
        background-color: var(--gray2);
      }
    }
  }

  .suggestion_box::-webkit-scrollbar {
    display: none;
  }

  .new-line {
    white-space: pre-line;
  }

  @media only screen and (max-width: 576px) {

    .hero-header {
      padding: 0px 1em;
    }

    .banner {
      display: block;
    }
  }

  @media only screen and (max-width: 768px) {
    .banner {
      display: unset;
    }

    .hero-header {
      // margin-top: 42px;
      padding-top: 42px;
    }

    .banner_search {
      flex: 0.9;
      height: fit-content;
    }
  }

  @media only screen and (max-width: 800px) {
    body {
      overflow-x: auto;
    }

    .word-art {
      width: 80% !important;
    }

    .sec_title {
      margin-bottom: 1.5em;
    }

    .banner_u {
      flex-direction: column;
    }

    #carouselCaptions {
      width: 90%;
      margin-left: 10%;
    }

    .carousel-caption {
      display: block !important;
    }

    .banner_carousel {
      width: 100%;
    }

    .banner_img {
      width: 100%;
    }

    .banner_img img {
      width: 85%;
    }

    .feature {
      width: 90%;
      margin: 10px auto;
    }

    .job_item {
      width: 90%;
      margin: 10px auto;
    }

    .brand_images {
      flex-wrap: wrap;
      display: flex !important;

      a {
        display: flex;
        justify-content: center;
        // margin: auto;

        img {
          width: 80%;
        }
      }
    }

    .brand_images img {
      margin: 5px 10px;
    }



    .btn-search {
      width: 100%;
    }

    .btn-search {
      width: auto !important;
    }

    .brand_images img {
      width: 50%;
      padding: 0;
    }
  }

  @media only screen and (max-width: 900px) {
    .banner {
      display: unset;
    }

    .hero-header {
      // margin-top: 42px;
      padding-top: 42px;
    }
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;

    .branding_logo {
      height: 52px !important;
    }

    .banner_right .img {
      width: 80% !important;
      margin-top: 20px !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;

    .branding_logo {
      height: 50px !important;
    }

    .brand_images a img {
      max-height: 46px !important;
      max-width: 87px !important;
    }

    .banner_right .img {
      width: 80% !important;
      margin-top: 20px !important;
    }
  }

  @media only screen and (min-width: 1601px) {
    .container__box {
      max-width: unset;
    }
  }

  @media (min-width: 1550px) and (max-width: 1600px) {
    .brand_images {
      width: 90%;
      margin-left: 5%;
    }
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;

    .branding_logo {
      height: 70px;
    }

    .brand_images a img {
      max-height: 60px;
      max-width: 130px;
    }

    .carousel-caption h2 {
      width: 99%;
    }

    .feature_content {
      width: 100%;
    }
  }

  @media (min-width: 1600px) and (max-width: 1679px) {
    .branding_logo {
      height: 75px;
    }
  }

  @media (width: 1680px) {
    .branding_logo {
      height: 78px;
    }
  }

  @media only screen and (min-width: 2560px) {
    .branding_logo {
      height: 95px;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner {
      .banner_left {
        width: 60%;
        flex: auto;
      }

      .banner_right {
        width: 40%;
        flex: auto;
      }
    }

    .b_s_search {
      // width: 45.7vw;
    }

    // .search_bar {
    //   width: 35%;
    //   max-width: 35%;
    // }

    .container__box .brand-image__card {
      width: 16%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner {
      .banner_left {
        flex: auto;
      }

      .banner_right {
        flex: auto;
      }
    }

    .b_s_search {
      width: 100%;
    }

    // .search_bar {
    //   width: 35%;
    //   max-width: 35%;
    // }

    .container__box .brand-image__card {
      width: 25%;
    }

    .banner_right {
      margin-top: 40px;
    }
  }

  @media only screen and (max-width: 767px) {

    .sticky-search {
      padding: 20px 10px !important;
      // margin-top: 4%;

      .b_s_search {
        width: 100%;
        flex-direction: column;
        height: auto !important;
      }

      .b_s_search .search_input input {
        font-size: var(--p1);
      }

      .btn_search_bar {
        padding: 0;
        width: 100%;
      }

      .btn-search {
        width: 100% !important;
        border-radius: 0 0 12px 12px !important;
        padding: 10px;
        font-size: 10px;
      }

      .banner_search {
        border: 1px solid #dbdbdb;
        border-radius: 0 0 12px 12px;
        border-top: 0;
      }

      .btn_search_bar button img {
        margin-right: 5px;
        width: 10px;
      }

      .search_bar {
        width: 100%;
        max-width: 100%;
        border: 1px solid #dbdbdb;
        // border-right: none;
        height: 60px;
      }

      .skill-search-bar {
        width: 100% !important;
        max-width: 100% !important;
        height: 40px;
      }

      .location-search-bar {
        width: 100% !important;
        max-width: 100% !important;
        height: 40px;
        // display: none;
      }

      .sticky-search {
        padding: 0 1em !important;
        margin-top: 6%;
      }

      .sticky-search-item {
        flex-direction: column;
        height: 100% !important;
      }

      .find-btn {
        padding: 6px 15px;
      }

      .b_s_search .search_input input {
        font-size: 0.75em;
      }
    }

    .find-btn {
      padding: 11px 15px;
      width: 100%;
      font-size: 14px;
    }

    .b_s_find {
      width: 100%;
    }

    .banner_right {
      margin-top: 40px;
    }

    .banner_left {
      .heading_1 {
        font-size: 2.3em;
      }

      .heading_3 {
        margin-top: 10px !important;
        font-size: 1.2em;
      }

      .banner_d {
        padding-top: 20px;
      }
    }

    .b_s_search {
      width: 100%;
      flex-direction: column;
    }

    .b_s_search .search_input input {
      font-size: var(--p1);
    }

    .btn_search_bar {
      padding: 0;
      width: 100%;
    }

    .btn-search {
      width: 100% !important;
      border-radius: 0 0 12px 12px !important;
      padding: 14px;
      font-size: 14px;
    }

    .banner_search {
      border: 1px solid #dbdbdb;
      border-radius: 0 0 12px 12px;
      border-top: 0;
    }

    .btn_search_bar button img {
      margin-right: 5px;
      width: 10px;
    }

    .search_bar {
      width: 100%;
      max-width: 100%;
      border: 1px solid #dbdbdb;
      // border-right: none;
      height: 60px;
    }

    .container__box {
      .brand-image__card {
        width: 33.3333% !important;
      }

      .container__box .brand_images {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}