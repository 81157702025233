.list-group {
    display: flex;
    flex-direction: row;
    height: auto;
}

.list-group-item.active {
    background-color: white;
    border: none;
    font-size: var(--p1);
    font-weight: 500;
    border-radius: 0px;
    color: var(--black3);
    border-bottom: 2px solid var(--green);
}

.list-group-item:hover {
    background-color: white;
    border-bottom: 3px solid var(--green);
}

.list-group-item {
    border-radius: 0px !important;
    color: var(--gray8);
    font-size: var(--p1);
    font-weight: 400;
    font-family: 'Plus Jakarta Sans', sans-serif;
    border: 0px;
    width: auto;
    margin-right: 37px;
    padding: 0.5em 0px;
    white-space: nowrap;
}

.tab-content {
    margin-top: 2.188em;
}

.tab-content>.tab-pane {
    min-height: 35em;
    // overflow-y: scroll;
}

.tab-content>.tab-pane::-webkit-scrollbar {
    display: none;
}

.tab-content>.tab-pane {
    -ms-overflow-style: none;
    scrollbar-width: none;
}