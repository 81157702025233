.oneTemplate ul li {
  list-style-position: inside;
  list-style-type: none;
  &:before {
    content: "• ";
  }
}
.oneTemplate ol li {
  list-style-position: inside;
  list-style-type: none;
  counter-increment: my-counter;
  &:before {
    content: counter(my-counter) ". ";
  }
}
.avoid-page-break {
  page-break-inside: avoid;
  break-inside: avoid-page;
}

