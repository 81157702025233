.mainWrapper {
  padding: 2rem;
  /* width: 533px;
  margin-left: auto;
  margin-right: auto; */
  /* background-image: url("../../../../src/Assets/svgs/bg-template-2.svg"); */
  background-repeat: no-repeat;
  background-position: right top;
  /* background-color: #f6f8fd; */
  font-family: "Manrope";
  min-height: 900px;
  word-break: break-word;
}

.mainWrapper ul li {
  list-style-position: inside;
  list-style-type: none;
  &:before {
    content: "• ";
  }
}
.mainWrapper ol li {
  list-style-position: inside;
  list-style-type: none;
  counter-increment: my-counter;
  &:before {
    content: counter(my-counter) ". ";
  }
}
.wrapper {
  display: grid;
  grid-template-columns: 1fr 180px;
}
.hightlightTitle {
  color: #00356b !important;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.leftMenu {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  padding: 0rem 0rem 0rem 0rem;
}
.leftMenu > .secOne > .name {
  font-size: 1.5rem;
  font-weight: 600;
}
h2 {
  font-size: 1.2rem;
  font-weight: 600;
}
.value {
  font-size: 0.7rem;
  opacity: 0.7;
  font-weight: 400 !important;
}
.valueCap {
  font-size: 0.7rem;
  opacity: 0.7;
  font-weight: 400 !important;
  text-transform: capitalize;
}
.row {
  margin-bottom: 0.5rem;
}

.label {
  font-size: 12px !important;
  font-weight: 400 !important;
}
.flexRow1 {
  margin-bottom: 1rem;
}
.Wrapper {
  width: 100%;
  height: 100%;
  /* padding: 2rem 5rem;  */
  margin: 2rem;
  /* padding: 2rem; */
}
.bgImg {
  /* background-image: url("../public/logo192.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.header {
  /* border-bottom: 1px solid #e2e2e2; */
  /* margin-bottom: 2rem; */
  /* margin: 2rem; */
  margin-left: 9.5rem;
}

.header > h2 {
  font-size: 2.5rem;
  font-weight: 600;
}
.header > p {
  font-size: 0.8rem;
  opacity: 0.8;
}
.flexRow1 {
  margin-bottom: 1rem;
}
.mainMenu {
  display: grid;
  grid-template-columns: 30% 50%;
  place-content: center;
  /* grid-gap: 1rem; */
}
.leftMenu {
  display: flex;
  flex-direction: column;
  /* border-right: 1px solid #e2e2e2;
   border-top: 1px solid #9f9f9fa1; */
  /* margin-right: 1rem; */
}
.rightMenu {
  /* border-top: 1px solid #9f9f9fa1; */
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}
.companyName {
  font-size: 0.875rem;
  font-weight: 600;
}
.designation {
  font-size: 0.675rem;
}
.from {
  font-size: 0.675rem;
}
.descPoints {
  font-size: 0.675rem;
}

.dot {
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #404040;
  margin-right: 0.5rem;
}
.flexRow {
  display: flex;
  align-items: center;
}
.skillWrp,
.language {
  max-width: 25rem;
}
.exp {
  margin-bottom: 0.5rem;
}
.content {
  page-break-after: always;
}
.PageBreak {
  page-break-after: always;
}
.firstSec > .name {
  font-size: 1.625rem;
  font-weight: 600;
  color: #133467;
}
.firstSec > p {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
  font-size: 12px;
}
