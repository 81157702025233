.contact_page {
  // min-height: 100vh;
  // width: 100vw;
  padding: 110px 0;
  font-size: 16px;
  display: flex;

  .body {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    // margin: auto;

    .left {
      flex: 0.5;
      margin: auto;

      img {
        width: 80%;
      }

      h2 {
        font-size: 1.75em;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 600;
        margin-bottom: 0.625em;
      }

      p {
        font-size: var(--p1);
        color: #9498a0;
        font-weight: 500;
        width: 85%;
      }

      ul {
        list-style: none;
        padding: 0px;
        margin: 40px 0;

        li {
          display: flex;
          margin: 1.25em 0;
          align-items: center;

          img {
            width: 2.5em;
            margin-right: 1.25em;
          }

          p {
            font-size: var(--p1);
            color: #263238;
            font-weight: 600;
          }

          a:hover {
            color: var(--green);
          }
        }
      }
    }

    .right {
      flex: 0.5;
      background-color: #f8f8f8;
      padding: 30px 0;
      border-radius: 8px;

      h2 {
        width: 80%;
        margin: auto;
        padding: 0.313em;
        font-size: 1.75em;
        font-weight: 600;
        font-family: "Plus Jakarta Sans", sans-serif;
      }

      .row {
        width: 80%;
        margin: 0.625em auto;

        .col {
          padding: 0.425em;
          display: flex;
          flex-direction: column;

          label {
            font-size: 0.875em;
            color: #2c2c2c;
            font-weight: 500;

            .red {
              color: #ea3e3e;
            }
          }

          input {
            border: 1px solid #dbdbdb;
            border-radius: 0.625em;
            box-shadow: none;
            height: 3em;
            font-size: var(--p1);
          }

          textarea {
            border: 1px solid #dbdbdb;
            border-radius: 0.625em;
            font-size: var(--p1);
            font-weight: 500;
            padding: 0.625em;
          }

          button {
            background-color: var(--green);
            color: var(--white);
            border: 0px;
            outline: none;
            padding: 7px 20px;
            font-family: "Plus Jakarta Sans", sans-serif;
            border-radius: 0.625em;
            font-size: 0.875em;
            font-weight: 500;
            margin-right: auto;
          }
        }
      }
    }

    input.PhoneInputInput {
      border: none !important;
      outline: none;
      background-color: #fff;
      box-shadow: none !important;
      height: 3em !important;
      font-size: 1.125em;
      font-weight: 500;
      color: #11263c;
    }

    .PhoneInputCountry {
      background: #fff;
    }

    .custom-tel {
      margin-bottom: 1.5em !important;
      border: 1px solid var(--gray5);
      border-radius: var(--border-radius);
      background-color: white;
    }
  }

  @media only screen and (max-width: 800px) {

    .body {
      flex-direction: column;
      margin: 10px auto;
      width: 90%;
      height: fit-content;

      .left {
        height: fit-content;
        flex: none;
        margin: 0;
      }

      .right {
        flex: none;

        h2 {
          width: 90%;
        }

        .row {
          width: 90%;
        }
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }
}