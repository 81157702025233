// Search Bar Style 

// .search-section__wrapper{
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   flex: 1 1;
//    
// }

.search_section {
  display: flex;
  justify-content: center;
  border-radius: var(--border-radius);
  background-color: var(--white);
  width: 100%;
  height: 46px;
  font-size: var(--p1);

  .add_pills {
    display: flex;
    max-width: 50%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    span {
      display: flex;
      white-space: nowrap;
      align-items: center;
      justify-content: space-between;
      flex: 0.3 1;
      border: 1px solid var(--gray5);
      padding: 5px;
      margin-right: 13px;
      border-radius: var(--border-radius);

      p {
        font-weight: 500;
        color: var(--green);
        font-size: var(--p1);
      }

      button {
        border: none;
        background: none;

        img {
          width: 0.625em;
          margin-right: 0px;
        }
      }
    }
  }

  // .add_pills::-webkit-scrollbar {
  //   display: none;
  // }
}


.job_search {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 50%;
  border: 1px solid #f2f2f2;
  overflow: hidden;

  .search_box {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      height: 1.25em;
      margin: 0 10px;
      flex: 0.1;
    }

    input {
      outline: none;
      height: 100%;
      flex: 0.9 1;
      border: 0px;
      border-radius: 20px;
      // font-size: 0.875em;
      font-weight: 500;
      min-width: 10%;
      padding-left: 5px;
      color: var(--gray4);
    }
  }

  span img {
    width: 10px;
  }

  span p {
    font-weight: 500;
    color: var(--green);
    font-size: var(--p1);
  }
}


.location_search {
  width: 50%;
  flex-direction: column;
  border: 1px solid #f2f2f2;
  overflow: hidden;

  .search_box {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      height: 1.25em;
      margin: 0 10px;
      flex: 0.1;
    }

    input {
      outline: none;
      height: 100%;
      flex: 0.9;
      border: 0px;
      border-radius: 20px;
      // font-size: 0.875em;
      font-weight: 500;
      min-width: 10%;
      color: var(--gray4);
      padding-left: 5px;
    }
  }
}

.search_button {
  background-color: var(--green);
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 70px;
  white-space: nowrap;

  p {
    margin-bottom: 0px;
    color: var(--white) !important;
    font-weight: 500;
    font-size: var(--p1);
  }
}


.suggestion_box {
  position: absolute;
  background: #fff;
  z-index: 200;
  padding: 0;
  width: fit-content;
  max-width: 500px;
  max-height: 150px;
  overflow-y: scroll;
  margin-top: 0.75em;
  box-shadow: 0 7px 8px -4px #d3d3d3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  p {
    padding: 10px;
    font-size: 14px;
    border-bottom: solid 2px #f5f6f7;
  }

  // &::-webkit-scrollbar {
  //   display: none;
  // }
}


.f_t_box {
  display: flex;
  max-width: 50%;
  overflow-x: auto;
  -ms-overflow-style: none;
}

.f_t_box::-webkit-scrollbar {
  scrollbar-width: none;
  display: none;
}

.f_t {
  display: flex;
  align-items: center;
  background-color: var(--light-blue);
  width: fit-content;
  padding: 3px 1.15em;
  border-radius: 20px;
  margin-right: 0.938em;
  white-space: nowrap;

  p {
    color: var(--green) !important;
    font-size: 0.675em;
    font-weight: 500;
    margin-right: 0.75em;
  }

  img {
    width: 10px;
    margin: 0px !important;
  }
}



// Search Type -- Style
.search-type {
  display: flex;
  align-items: center;

  label {
    margin: 2px 20px 0 7px;
    color: #9398a1;
    // font-size: 0.875em;
    font-weight: 500;
  }
}

.search-sub-type {
  display: flex;
  align-items: center;
}

.search-sub-type-main {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .search-type {
    margin-top: 10px !important;
    flex-wrap: wrap;
  }

  .search-sub-type-main {
    margin-top: 10px !important;
    display: flex;
    flex-wrap: wrap;
  }

  .search_section {
    flex-direction: column;
    height: auto !important;

    .job_search {
      width: 100%;
      height: 60px;
      border-radius: 8px 8px 0 0;
    }

    .location_search {
      width: 100%;
      height: 60px;
    }

    .search_button {
      width: 100%;
      height: 60px;
      border-radius: 0 0 8px 8px;
    }
  }
}