.company-profile {
    // padding: 100px 10rem;
    font-size: 13px;

    .banner {
        .profile_img {
            width: 5.625em;
            height: 5.625em;
            border-radius: 50%;
            margin-right: 20px;
            background-color: var(--gray2);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            position: absolute;
            bottom: -30px;
            left: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
            }
        }
    }

    .rounded-card {
        border-radius: 10px !important;
    }

    .recruiter-name {
        color: #202020;
        font-size: 1.5em;
        font-weight: 700;
        padding: 10px;
    }

    .send-message {
        background: #14BC9A 0% 0% no-repeat padding-box;
        border-radius: 30px;
        padding: 10px 20px 10px 20px;
        border: none;
        margin: 14px;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .setDeleteClass {
        position: absolute;
        right: 0;
        margin: 5px 10px 0 0;
        background-color: #efefef;
        border-radius: 50%;
        padding: 8px;
        width: 40px;
        height: 40px;
        color: #14bc9a;
    }

    .deleteIcon {
        // width: 15px;
        // height: 15px;       
    }

    .tabs {
        margin-top: 50px;
        display: flex;
        padding: 10px;
        flex-direction: inherit !important;
        overflow-x: auto;
        width: 100%;
        gap: 1rem;

        .tab__list {
            padding: 10px;
            font-weight: 600;
            font-size: var(--p);
            color: var(--gray8);
            white-space: nowrap;

            &.active {
                border-bottom: 2px solid var(--green);
                color: black;
            }
        }
    }

    .tab__pane {
        padding-top: 15px;

        .tab_1 {
            display: flex;
        }

        .left {
            width: 70%;

            .gen-details {
                p {
                    font-size: 0.8em;
                }
            }

            h2 {
                font-weight: 600;
                font-size: 1.5em;
            }

            h4 {
                font-weight: 600;
                font-size: var(--p1);
            }

            .desc {
                font-size: 0.875em;
            }

            .video__frame {
                width: 100%;
                display: flex;
            }

            .desc {
                text-align: justify;
            }

            .photos {

                display: flex;
                flex-wrap: wrap;

                .photo {
                    padding: 10px;
                }

                .photo img {
                    border-radius: 8px;
                    object-fit: contain;
                    cursor: pointer;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    width: 160px;
                    height: 160px;
                }
            }
        }

        .right {
            width: 30%;

            h4 {
                font-weight: 600;
                font-size: 1.5em;
            }

            h6 {
                font-weight: 600;
                font-size: 0.875em;
            }

            p {
                font-size: var(--p1);
                color: #263238;
            }

            a {
                color: var(--green);
                text-decoration: underline;
                font-size: var(--p1);
            }
        }

        .featured_jobs {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        #featured-3 {
            max-width: none !important;
            width: 100%;
            margin: auto;
            padding-bottom: 50px;
        }

        .feature {
            padding: 0 0.8em;
            display: flex;
            flex-direction: column;
            margin: 0px 0 20px 0;
            cursor: pointer;

            .feature-col-br {
                border: 1px solid #efefef;
                padding: 1.875em 1.563em;
                border-radius: var(--border-radius);
            }
        }

        .feature_icon {
            margin-bottom: 1.563em;
            display: flex;
            justify-content: space-between;
            position: relative;
            align-items: center;
            height: 3em;
        }

        .feature_icon p {
            flex: 0.8;
            text-align: right;
            font-size: 0.75em;
            color: var(--gray7);
            font-weight: 500;
        }

        .feature_brand_img {
            top: 0;
            left: 0;
            max-height: 3em;
            max-width: 9em;
            width: auto;
            height: auto;
        }

        .featured_brand_icon {
            width: 40px;
        }

        .feature_content {
            width: 90%;

            .feature_content_tags {
                margin-bottom: 1.25em;
            }
        }

        .recruiter-feature {
            border-radius: 10px;
            border: solid 1px #f2f2f2;
            transition: all 0.4s ease-in-out;

            &:hover {
                border: 1px solid var(--green);
            }

            .feature_content.hand-hover.recruiter-content {
                padding: 10px 0 10px 0;

                .name {
                    color: #263238;
                }

                .jobs-count {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #14BC9A;
                    border-radius: 8px;
                    padding: 4px 8px 4px 8px;
                    font-size: 0.875em;
                }
            }
        }

        .feature_buttons {
            justify-content: space-between;
            margin-top: auto;
        }

        .feature_content h2 {
            font-size: 1.5em;
            color: var(--black3);
            font-weight: 600;
            margin-bottom: 0.625em;
            overflow: hidden;
            height: 2.5em;
        }

        .feature_content h6 {
            font-size: var(--p1);
            font-weight: 500;
            color: var(--black3);
            margin-bottom: 0.625em;
            overflow: hidden;
            height: 1.25em;
        }

        .feature_content p {
            font-size: var(--p1);
            font-weight: 400;
            color: var(--gray7);
            margin-bottom: 1.25em;
            max-height: 18em;
            overflow: hidden;
        }

        .feature_content .feature_content_tags span {
            background-color: var(--gray3);
            padding: 5px 10px;
            font-size: 0.875em;
            color: var(--gray8);
            font-weight: 500;
            border-radius: var(--border-radius);
            margin-right: 10px;
            white-space: nowrap;
        }

        .feature_buttons button {
            padding: 0.625em 0em;
            font-size: 1.125em;
            font-weight: 500;
            border: 0;
            border-radius: var(--border-radius);
        }

        .feature_buttons :nth-child(2) {
            background-color: var(--green);
            color: white;
            width: 45%;
        }

        .feature_buttons :nth-child(1) {
            background-color: var(--gray3);
            color: var(--black3);
            width: 45%;
            margin-left: auto;
        }
    }

    .course-img {
        width: 220px;
        height: 110px;
        border-radius: 8px;
        margin-bottom: 8px;
    }

    @media only screen and (max-width: 991px) {
        padding: 70px 1em;

        .tab__pane {
            flex-direction: column;
            padding-top: 10px;

            .left {
                width: 100%;
                padding: 20px !important;

                h2 {
                    font-size: 1.4em;
                }

                h4 {
                    font-size: 1.2em;
                }

                .photos .photo img {
                    width: 90px;
                    height: 90px;
                }
            }

            .right {
                width: 100%;

                div {
                    padding: 20px !important;
                }
            }

            .video__frame iframe {
                width: 100%;
            }

            .feature_content {
                width: 100%;

                h2 {
                    font-size: 1.2em;
                    height: 2em;
                }
            }

            .feature_icon {
                height: 2em;
                margin-bottom: 1em;
            }
        }

        .tabs .tab__list {
            // font-size: 1.3em;
        }
    }

}

.no_jobs {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 3.125em;
    }

    h2 {
        font-size: 1.5em;
        font-weight: 600;
        color: #263238;
        margin: 1.25em;
    }
}

.no_gigs {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 3.125em;
    }

    h2 {
        font-size: 1.5em;
        font-weight: 600;
        color: #263238;
        margin: 1.25em;
    }
}


// 

.box-wrapper {
    border: 1px solid var(--gray);
    border-radius: 16px;
    padding: 30px;

}

.banner-img {
    height: 200px;
    border: 1px solid var(--gray);
    border-radius: 16px 16px 0 0;
    position: relative;
}

.company-info {
    border: 1px solid var(--gray);
    border-radius: 16px;
    padding: 30px;

    .gen-details {
        gap: 5px;
        margin-top: 10px;
    }
}

.text-gray8 {
    color: var(--gray8);
}