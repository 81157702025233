.modal {
  position: fixed;
  inset: 0% 0px 0px 0%;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  z-index: 9999;
  margin-top: 0px;
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.quickAmt {
  font-size: var(--p1);
}

.login-required-modal {
  width: 50% !important;
  left: 25% !important;
  top: 25% !important;
}

.info_modal_content {
  width: 50% !important;
  // min-height: 50% !important;
  left: 25% !important;
  top: 25% !important;
  // bottom: 25% !important;
  margin-bottom: 0px !important;

  .modal_body {
    width: 90%;
    min-height: auto !important;
    margin: auto;
    .loader {
      height: 100% !important;
      position: absolute;
      width: 100%;
    }
    ::-webkit-scrollbar {
      display: none;
    }

    h4 {
      font-size: 1.25em;
      font-weight: 600;
      color: #263238;
      margin-bottom: 10px;
    }

    .info-content {
      min-height: 24vh;
    }
  }
}

.modal_content {
  width: 80%;
  // min-height: 80%;
  background: white;
  margin: auto;
  z-index: 10000;
  position: fixed;
  left: 10%;
  top: 10%;
  // bottom: 10%;
  // margin-bottom: 100px;
  font-size: 16px;

  max-height: 80%;
  overflow-y: auto;

  hr {
    margin: 0px 0 1.25em 0;
    background-color: #dbdbdb;
    height: 2px;
  }
}
.modal_body .table__wrapper {
  min-height: 50vh !important;
}

.modal_top {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 0.8rem 0px;

  h2 {
    font-size: 1.125em;
    font-weight: 600;
    color: #263238;
    margin-bottom: 0px;
  }

  button {
    background: none;
    border: 0px;

    img {
      width: 1.125em;
    }
  }
}

.partner-candidates {
  min-height: 500px;

  .loader {
    margin: auto;
    width: 0% !important;
  }
}

.t_details {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  thead tr th:nth-child(3) {
    text-align: center;
  }

  .no_jobs {
    height: 400px !important;
  }

  th {
    white-space: nowrap;
  }

  td {
    white-space: nowrap;
  }
}

.t_details::-webkit-scrollbar {
  display: none;
}

.modal_body {
  width: 100%;
  // min-height: 64vh;
  margin: auto;

  .modal_data_rows {
    min-height: 57vh;
  }

  .suggestion_box {
    padding: 0;
    position: absolute;
    background: white;
    z-index: 2;
    padding: 0px;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 7px 8px -4px lightgrey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    p {
      border-bottom: solid 1px #dbdbdb;
      padding: 7px 14px;
      cursor: pointer;
    }
  }

  .row {
    margin-bottom: 0.75em;
  }

  .form-control {
    font-size: var(--p1);
    font-weight: 500;
    color: var(--text-color);
    box-shadow: none !important;
    outline: none;
    border-radius: var(--border-radius);
    border: 1px solid var(--gray5);
    // height: 3em;
  }

  .form-textbox {
    height: 8.5em;
  }

  .input-lebel {
    font-size: 0.875em;
    color: var(--black-3);
    font-weight: 400;
    margin: 0.575em 0;
  }

  .input-lebel2,
  .form-input-group label {
    font-size: 0.875em;
    color: var(--gray4);
    font-weight: 400;
    margin-bottom: 0.375em;
  }

  .input-lebel3 {
    font-size: var(--p1);
    font-weight: 600;
    color: #11263c;
  }

  .input-group {
    border: 1px solid var(--gray5);
    border-radius: var(--border-radius);
  }

  .react-datepicker-wrapper {
    flex: 1;
    height: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
  }

  .input-group .input-group-text {
    background-color: transparent !important;
    border: none !important;
    font-size: 1.25em;
    font-weight: 600;
  }

  .input-group span img {
    width: var(--input-icon);
  }

  .input-group input {
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    // height: 3em;
    font-size: var(--p1);
    color: #11263c;
    font-weight: 500;
    width: inherit;
  }

  .form-select {
    border-radius: var(--border-radius);
    border: 1px solid var(--gray5);
    font-size: var(--p1);
    font-weight: 500;
    color: #11263c;
    box-shadow: none;
    // height: 3em;
  }

  .form-control::placeholder,
  .input-group input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9398a1;
    font-size: 1.125em;
    font-weight: 500;
    opacity: 1;
    /* Firefox */
  }

  .form-control:-ms-input-placeholder,
  .input-group input::placeholder {
    /* Internet Explorer 10-11 */
    color: #9398a1;
    font-size: 1.125em;
    font-weight: 500;
  }

  .form-control::-ms-input-placeholder,
  .input-group input::placeholder {
    /* Microsoft Edge */
    color: #9398a1;
    font-size: 1.125em;
    font-weight: 500;
  }

  .form_buttons {
    display: flex;
    margin: 1.25em 0px !important;
    justify-content: flex-end;
  }

  .form_buttons button {
    background-color: var(--light-blue);
    color: var(--green);
    border: 0px;
    outline: none;
    padding: 8px 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 0.875em;
    border-radius: var(--border-radius);
    font-weight: 600;
    margin-right: 0;
    margin-left: 20px;
  }

  .form_buttons input {
    background-color: var(--green);
    color: var(--white);
    border: 0px;
    outline: none;
    padding: 8px 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    border-radius: 0.625em;
    font-size: 0.875em;
    font-weight: 600;
  }

  .present-check {
    width: 1em;
    margin: auto 0;
    margin-right: 0.625em;
    margin-top: 0.7rem;
    & + .input-lebel3 {
      margin-top: 0.5rem;
    }
  }

  a.add-more {
    text-decoration: none;
    color: var(--green);
    font-size: var(--p1);
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .add-more img {
    width: 15px;
    margin-right: 5px;
  }

  a.delete-row {
    text-decoration: none;
    color: red;
    font-size: var(--p1);
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .delete-row img {
    width: 15px;
    margin-right: 5px;
  }

  .user_profile {
    width: 100% !important;
  }

  .contact-details {
    list-style: none;
    padding: 0px;
    margin: 40px 0;

    li {
      display: flex;
      margin: 1.25em 0;

      img {
        width: 2.5em;
        margin-right: 1.25em;
      }

      p {
        margin-top: 5px;
      }
    }
  }
}

// .extra{
//   max-height: 200px;
//   overflow-y: scroll;
// }
// .extra::-webkit-scrollbar {
//   display: none;
// }

// .extra {
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
// }

.moda_content {
  @media only screen and (max-width: 800px) {
    width: 90% !important;
    left: 5% !important;

    .btn_toolbar {
      overflow: auto;
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .modal_body {
      .row .col:empty {
        display: none;
      }

      .row .col {
        flex: auto;
        width: 50%;
        margin-bottom: 10px;
      }

      .form-control,
      .input-group input {
        font-size: var(--p1);
      }

      .row.mb-5 {
        margin-bottom: 0.75em !important;
      }

      a.delete-row,
      a.add-more {
        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .makeStyles-copyContainer-5 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 14px;
      align-items: flex-start;
    }

    #text {
      position: unset;
      transform: none;
    }

    .makeStyles-copyIcon-7 {
      right: 10px;
    }

    .makeStyles-copyUrl-6 {
      max-width: calc(100% - 40px);
    }

    .modal_body {
      .row .col:empty {
        display: none;
      }

      .row .col {
        flex: auto;
        margin-bottom: 10px;
      }

      .form-control,
      .input-group input {
        font-size: var(--p1);
      }

      .row.mb-5 {
        margin-bottom: 0.75em !important;
      }

      a.delete-row,
      a.add-more {
        img {
          width: 15px;
        }
      }
    }
  }
}

.makeStyles-iconContainer-3 {
  padding-top: 10px !important;
}

.makeStyles-container-1 {
  margin-bottom: 20px;
}

.socialMediaButton {
  margin-right: 10px;
}

.makeStyles-copyContainer-5 {
  color: #263238;
  width: 100%;
  border: 1px solid grey;
  cursor: text;
  display: inline-flex;
  padding: 14px;
  position: relative;
  font-size: 14px;
  background: black;
  box-sizing: border-box;
  font-style: normal;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: normal;
  border-radius: 4px;
  letter-spacing: -0.05px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.makeStyles-copyUrl-6 {
  color: white !important;
  font-size: 16px !important;
  max-width: calc(100% - 55px);
  overflow-x: auto;
  line-height: 24px;
  white-space: nowrap;
}

.makeStyles-copyIcon-7 {
  color: #0c66ff;
  right: 30px;
  width: auto;
  position: absolute;
  font-weight: bold;
}

.makeStyles-copyIcon-7 a {
  text-decoration: none;
}

button.btn.btn-primary.btn-offline.mt-2 {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

button.btn.btn-success.mt-2 {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.react-datepicker__month-container {
  height: 300px;
}

.preview-media-message-img {
  width: 200px;
}

.preview-media-message-text {
  margin-top: 20px;
}

.preview-media-message-text span {
  font-size: larger;
  font-weight: 700;
  color: #1a1c1d;
}

.preview-media-message-text p {
  font-size: larger;
  color: #8083a3;
}

.preview-download-btn {
  background-color: var(--green);
  color: var(--white);
  padding: 10px 20px 10px 20px;
  border-radius: var(--border-radius);
  font-weight: 500;
  font-size: 1.125em;
  text-decoration: none;
  margin-top: 23px;
}

.preview-download-btn:hover {
  color: var(--white);
}

.preview-media-message.row {
  margin-bottom: 3em !important;
}

.due-amount-bar {
  background-color: var(--light-blue);
  border: 1px dashed var(--green);
  border-radius: var(--border-radius);
  text-align: center;
  color: var(--green);
}

.red {
  color: #fd5049;
}

.location-filter-modal {
  overflow-y: hidden !important;
  width: 80%;
  left: 10%;
}

ul.FilterDirectory-indices {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  text-transform: uppercase;
}

.FilterDirectory-indices li {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  // display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;
  color: #adaeaf;
  font-weight: 700;
  font-size: 13px;
}

.search-filter {
  width: 100%;
  border-radius: var(--border-radius);
  padding: 8px;
  outline: none;
  height: 3.125em;
  font-size: 0.9em;
  font-weight: 600;
  border: 1px solid #ccc;
}

.list-disabled {
  opacity: 0.2;
  cursor: default;
}

.modal-header--sticky {
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 1055;
}

.FilterDirectory-list {
  padding: 20px 18px 10px 0 !important;
  margin-right: 18px !important;
  margin-left: 10px !important;
  height: 425px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.FilterDirectory-listTitle {
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #282c3f;
}

.FilterDirectory-list li {
  margin: 0 10px 8px 0;
  font-size: 14px;
  padding-left: 10px;
}

.search-leftContainer li {
  font-size: 14px;
  margin-bottom: 7px;
}

.modal_body.giveaway .form_buttons {
  margin: 0.25em 0px !important;
}

.modal_body.giveaway .close-btn {
  border: none;
  background: none;
  position: absolute;
  padding-left: 70%;
  padding-top: 1%;
}

.giveaway-img {
  max-height: 64.7vh;
  overflow: hidden;
  justify-content: center;

  img {
    width: auto;
    height: fit-content;
    max-height: 64.7vh;
  }
}

.photos {
  grid-gap: 0px !important;

  .photo {
    border: none !important;
    width: auto !important;
    height: auto !important;
  }
}

.refer-success {
  justify-content: center;
  text-align: center;

  img {
    height: auto;
    width: auto;
  }

  p {
    font-weight: 700;
    font-size: 1.25em;
    color: var(--green);
  }

  span {
    font-weight: 400;
    font-size: 0.875em;
    color: #4b5157;
  }

  .boost-job {
    font-size: var(--h3);
    font-weight: 600;
    // width: 40%;

    span {
      font-size: var(--h3);
      font-weight: 600;
    }
  }

  .form_buttons {
    float: none !important;
    justify-content: center !important;

    button,
    input[type="submit"] {
      width: 35%;
    }
  }
}

.refer-confirm-heading {
  font-size: var(--p1);
  font-weight: 500;
  color: #2c2c2c;
}

.refer-data-show {
  label,
  p {
    margin: 0 0 0 0 !important;
  }
}

.feature-job-data {
  padding: 16px;

  .check-div {
    display: flex;
  }

  label,
  p {
    // font-size: 0.875em;
    font-weight: 500;
  }
  .form-check-label {
    margin-left: 0.5rem;
  }

  label {
    color: #1b2124;
    margin: 0 0 0.5rem 0;
    text-wrap: wrap;
  }

  p {
    color: #9398a1;
    margin: 4px 0 0 26px;
  }

  .requirement-text {
    border: none;
    width: 100%;
    height: 30px;
    outline: none;
  }
}

.referral-data {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 25px !important;

  .check-div {
    display: flex;
  }

  label,
  p {
    // font-size: 0.875em;
    font-weight: 500;
  }

  label {
    color: #1b2124;
    margin: 0 0 0 10px;
    text-wrap: wrap;
  }

  p {
    color: #9398a1;
    margin: 4px 0 0 26px;
  }

  .requirement-text {
    border: none;
    width: 100%;
    height: 30px;
    outline: none;
  }
}

.apply-referral {
  text-align: center;

  p {
    font-size: 1.12em !important;
    font-weight: 500;
    color: #2c2c2c !important;
  }

  h5 {
    font-size: 1.25em;
    font-weight: 700;
    color: #263238;
    width: 50%;
    margin: auto;
  }

  img {
    height: 30px;
    max-width: 70px;
    object-fit: scale-down;
    margin: auto;
  }
}

.times_wrapper {
  // max-height: 180px;
  // overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .time_label {
    border: 1px solid #eaecf0;
    border-radius: 5px;
    padding: 8px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-right: 14px;
    margin-bottom: 14px;
    display: inline-block;
    width: 200px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .active-slot {
    background-color: var(--light-blue);
    color: var(--green) !important;
  }
}
