.drawer {
  max-height: 100vh;
  position: relative;
  z-index: 1001;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--gray);
  

  &__toggle-btn {
    background-color: #000000;
    color: #fff;
    font-weight: 700;
    display: flex;
    padding: 15px 30px;
    white-space: nowrap;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: fixed;
    top: 50%;
    right: -85px;
    z-index: 999;
    transform: rotate(-90deg);
    transition-duration: 500ms;
    cursor: pointer;
    translate: -4px;
    transform: rotate(-90deg);
    animation: slideDrawer 1s infinite;

    &:hover {
      cursor: pointer;
      translate: -4px;
      transform: rotate(-90deg);
    }
  }

  &__overlay {
    width: 100%;
    background: #00000080;
    position: fixed;
    z-index: 1000;
    inset: 0;
  }

  &__wrapper {
    right: -100%;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 9999;
    transition: all 0.4s ease-in-out;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    h2 {
      font-size: 1em;
      line-height: normal;
      color: var(--black3);
    }

    &-clear {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 20px;

      button {
        color: #14bc9a;
        cursor: pointer;
        font-weight: 600;

        &:focus-visible {
          outline: none;
        }
      }
    }

  }

  &__body {
    height: calc(100vh - 40px);
    overflow-y: scroll;
    padding: 2rem;
    background-color: var(--gray2);

    .result_filter {

      .filter-category {
        margin-bottom: 1.5rem;

        .filter_title-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;

          // font-size: 1rem;
          line-height: normal;
          font-weight: 600;
          color: var(--black3);

          .clearLocationBtn {
            color: var(--green);
            cursor: pointer;
            font-weight: 600;
          }

        }

        .filter-search {
          border-radius: var(--border-radius);
          margin-bottom: 0.625em;
          background-color: var(--white);
          border: 1px solid var(--gray5);

          input {
            border: none;
            width: 100%;
            border-radius: var(--border-radius);
            padding: 8px;
            outline: none;
            height: 3em;
            font-size: 0.875em;
            font-weight: 600;
          }

          .suggestion_box {
            width: 100%;
          }
        }

        .checkbox_div,
        .checkbox_div_c {
          display: flex;
          align-items: center;
          margin-bottom: 0.5em;
        }

        input[type=checkbox] {
          margin-right: 0.625em;
        }

        // .form-check-input:checked {
        //   border-color: var(--green);
        //   background-color: var(--green);
        // }

        .switch {
          position: relative;
          display: inline-block;
          width: 3.438em;
          height: 1.563em;
          margin-bottom: 8px;
        }

        .more p {
          font-size: .95em;
          font-weight: 600;
          color: var(--green);
          cursor: pointer;
        }

        .input-group {
          border: 1px solid var(--gray5);
          border-radius: var(--border-radius);
          background-color: #fff;
          width: 100%;

          input {
            border: none;
            outline: none;
            background-color: transparent;
            box-shadow: none;
            height: 3em;
            font-size: var(--p);
            color: #11263c;
            font-weight: 600;
            width: inherit;
            padding-left: 0;
          }

          span {
            background-color: transparent;
            border: none;
          }
        }
      }



      &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
      }

      &:hover::-webkit-scrollbar {
        background: lightgray;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: gray;
      }
    }
  }

  @keyframes slideDrawer {
    0% {
      transform: translateX(0) rotate(-90deg);
    }
    50% {
      transform: translateX(-4px) rotate(-90deg);
    }
    100% {
      transform: translateX(0) rotate(-90deg);
    }
  }
  //overwrite
  .react-datepicker__year-read-view {
    visibility: visible !important;
  }

  .react-datepicker__year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
    width: 80px !important;
  }

  .react-datepicker__year-option--selected {
    position: absolute;
    left: 4px !important;
  }

  .react-datepicker__year-dropdown {
    left: -4px;
    top: 0;
  }
}

@media only screen and (max-width: 400px) {
  .drawer {
    width: 320px !important;
  }
}