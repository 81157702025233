.overlay {
  position: fixed;
  inset: 0% 0px 0px 0%;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  z-index: 1000;
  margin-top: 0px;
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.candidate_page {
  // width: 100vw;
  font-size: 16px;
  // padding-top: 5.75em;
  
  .sticky-search {
    width: 100%;
    background-color: var(--white);
    box-shadow: 0px 1px 5px var(--black);
    padding: 18px 10em 10px 10rem;
    border: 1px solid #f2f2f2;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 40px;

    .sticky-search-item {
      display: flex;
      height: 48px;
      width: 100%;
    }
  }

  .body_section {
    background-color: var(--white);
    // height: 90vh;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    // padding: 0px 10rem;

    // margin-left: 70px;
    // margin-right: 90px;
  }

  .padding-search {
    .search_section {
      margin-top: 6em !important;
    }
  }

  /* Search bar section */

  .search_section {
    width: 100%;
    min-height: 3.75em;
    background-color: var(--white);

    // margin: 20px auto;
    display: flex;
    justify-content: start;
  }

  .search_section {
    display: flex;
    border-radius: var(--border-radius);
    // margin: 20px auto 15px auto;
    // padding: 0px 10rem;

    .add_pills {
      display: flex;
      max-width: 50%;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      span {
        display: flex;
        white-space: nowrap;
        align-items: center;
        justify-content: space-between;
        flex: 0.3 1;
        border: 1px solid var(--gray5);
        padding: 5px;
        // height: 35px;
        /* margin: auto 10px; */
        margin-right: 13px;
        border-radius: var(--border-radius);

        p {
          font-weight: 500;
          color: var(--green);
          font-size: var(--p1);
        }

        button {
          border: none;
          background: none;

          img {
            width: 0.625em;
            margin-right: 0px;
          }
        }
      }
    }

    .add_pills::-webkit-scrollbar {
      display: none;
    }
  }

  .job_search {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 40%;
    border: 1px solid #f2f2f2;

    .search_box {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 1.25em;
        margin: 0 10px;
        flex: 0.1;
      }

      input {
        outline: none;
        height: 100%;
        flex: 0.9 1;
        border: 0px;
        border-radius: 20px;
        font-size: 0.875em;
        font-weight: 500;
        min-width: 10%;
        color: var(--gray4);
      }
    }
  }

  /* .job_search::-webkit-scrollbar {
        display: none;
      } */

  // .job_search span {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   flex: 0.3 1;
  //   border: 1px solid var(--gray5);
  //   padding: 5px;
  //   // height: 35px;
  //   /* margin: auto 10px; */
  //   margin-right: 13px;
  //   border-radius: var(--border-radius);
  // }

  .job_search span p {
    font-weight: 500;
    color: var(--green);
    font-size: var(--p1);
  }

  .job_search span img {
    width: 10px;
  }

  .location_search {
    width: 40%;
    flex-direction: column;
    border: 1px solid #f2f2f2;

    .search_box {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 1.25em;
        margin: 0 10px;
        flex: 0.1;
      }

      input {
        outline: none;
        height: 100%;
        flex: 0.9;
        border: 0px;
        border-radius: 20px;
        font-size: 0.875em;
        font-weight: 500;
        min-width: 10%;
        color: var(--gray4);
      }
    }
  }

  .job_type_search {
    border-right: 2px solid var(--gray5);
    flex: 0.3;

    .search_box {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 1.25em;
        margin: 0 10px;
        flex: 0.1;
      }

      input {
        outline: none;
        height: 100%;
        flex: 0.9;
        border: 0px;
        border-radius: 20px;
        font-size: 1.125em;
        font-weight: 500;
        color: var(--gray4);
        min-width: 10%;
      }
    }
  }

  .search_button {
    background-color: var(--green);
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 70px;
    white-space: nowrap;
    // flex: 1 1 20%;
  }

  .search_button p {
    margin-bottom: 0px;
    color: var(--white);
    font-weight: 500;
    font-size: 0.875em;
  }

  /*  Result section */

  .result_section {
    width: 100%;
    height: 88%;
    background-color: var(--white);
    // margin: auto;
    display: flex;
    margin-top: 1.75em;
    // padding: 0px 10rem;
  }

  .titleCls {
    position: relative;
  }

  .clearBtn {
    // position: absolute;
    right: 0;
    color: #14bc9a;
    font-size: 0.875em !important;
    // padding-right: 1em;
    cursor: pointer;
  }

  .p-clr {
    padding-right: 1.5em;
  }

  .topTitle {
    // margin-left: 22px;
  }

  .job__card {
    min-height: 200px;
    .paragraph_1 {
      height: auto !important;
    }
  }

  .blank_content {
    flex-direction: column;
    align-items: center;
    margin-left: 0px !important;
    margin-top: 10% !important;
    padding-top: 0px !important;

    img {
      width: 3.125em;
    }

    h2 {
      font-size: 1.5em;
      font-weight: 600;
      color: #263238;
      margin: 1.25em;
    }

    p {
      font-size: 1.125em;
      font-weight: 500;
      color: #9398a1;
    }
  }

  .result_filter {
    // margin-right: 40px;
    margin-bottom: auto;
    // flex: 0.2;
    width: 100%;
    position: relative;
    padding-bottom: 2.5em;
    // overflow-y: scroll;
    scrollbar-width: none;
    background-color: var(--gray2);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -ms-overflow-style: none;

    .input-group {
      border: 1px solid var(--gray5);
      border-radius: var(--border-radius);
      width: 90%;
      background-color: white;
    }

    .react-datepicker-wrapper {
      flex: 1;
      height: 100%;
    }

    .react-datepicker__input-container {
      height: 100%;
    }

    .input-group span {
      background-color: transparent;
      border: none;
      font-size: 1.25em;
      font-weight: 600;
    }

    .input-group span img {
      width: 1.125em;
    }

    .input-group input {
      border: none;
      outline: none;
      background-color: transparent;
      box-shadow: none;
      height: 3em;
      font-size: 0.875em;
      color: #11263c;
      font-weight: 600;
      width: inherit;
    }
  }

  .result_feed::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  .result_filter::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  .result_filter:hover::-webkit-scrollbar {
    width: 5px;
    background-color: lightgray;
  }

  .result_filter:hover::-webkit-scrollbar-thumb {
    background-color: gray;
  }

  .result_feed {
    position: relative;
    flex: 1;
    width: 100%;
    // display: flex;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .result_feed .result_header,
  .result_filter .result_header {
    flex: 1;
    position: relative;
    z-index: 100;

    h2 {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .h2-clr {
      font-weight: bold;
      font-size: 12px;
    }
  }

  .result_feed .result_header {
    width: 100%;
    padding: 10px 0px;
    // padding-left: 0px;
    // padding-right: 40px;
    background-color: var(--white);
  }

  .result_feed .result_header h2 {
    font-size: 1.25em;
    font-weight: 600;
    color: var(--black3);
  }

  .result_feed .result_header p {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 0.875em;
  }

  .result_filter .result_header {
    display: flex;
    width: 100%;
    padding: 1em 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--gray);
  }

  .result_filter .result_header h2 {
    font-size: 0.94em;
    margin-left: 10%;
    font-weight: 600;
    margin-bottom: 0px;
    color: var(--black3);
  }

  .dropdown {
    display: flex;
    align-items: center;
    position: relative;
  }

  #dropdownMenuLink {
    background-color: transparent;
    border: none;
    color: black;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 0;
    padding-left: 10px;
  }

  .dropdown-menu {
    padding: 0px;
    margin-left: -3.438em;
    margin-top: 0.5em;
  }

  .dropdown-menu li {
    display: flex;
    border-bottom: 1px solid var(--gray5);
    padding: 5px 5px;

    a {
      font-size: 0.75em !important;
      font-weight: 600;
    }

    a:hover {
      color: black;
      background: none;
      cursor: pointer;
    }
  }

  .result_header_sort {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    P {
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 1.125em;
      display: flex;
      white-space: nowrap;
      align-items: center;
    }

    .dropdown {
      box-shadow: none;
      padding: 2px 0.5rem;

      a {
        font-size: 0.875em;
        font-weight: 600;
      }
    }
  }

  .travel_percentage_div {
    width: 70%;
    border-radius: var(--border-radius);
    margin: 10px 0px;
    background-color: var(--white);
    border: 1px solid var(--gray4);
  }

  .travel_percentage_div img {
    margin-left: 10px;
  }

  #travel_percentage {
    border: none;
    width: 90%;
    border-radius: var(--border-radius);
    padding: 8px;
    outline: none;
  }

  .result_filter form {
    padding-left: 10%;
    padding-top: 20px;

    input[type="checkbox"] {
      margin-right: 0.625em;
    }

    .checkbox_div {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;
    }

    .checkbox_div_c {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;
    }
  }

  .s_heading {
    margin-bottom: 5px !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .location_filter_search {
    width: 90%;
    border-radius: var(--border-radius);
    margin-bottom: 0.625em;
    background-color: var(--white);
    border: 1px solid var(--gray5);

    input {
      border: none;
      width: 100%;
      border-radius: var(--border-radius);
      padding: 8px;
      outline: none;
      height: 3em;
      font-size: 0.875em;
      font-weight: 600;
    }

    .suggestion_box {
      width: 100%;
    }
  }

  .form-label {
    font-size: 0.875em;
    color: var(--gray6);
    font-weight: 600;
    margin-bottom: 0px;
  }

  .result_filter form p {
    font-size: 0.875em;
    color: var(--black3);
    font-weight: 600;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-bottom: 1.125em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;

    img {
      width: 16px;
      height: 100%;
      margin-left: auto;
      margin-right: 10px;
    }

    .downCls {
      margin-left: 8px;
      width: 14px;
    }

    .upCls {
      margin-left: 0px;
      width: 22px;
    }
  }

  .result_filter form .more p {
    font-size: 0.75em;
    font-weight: 600;
    color: var(--green);
  }

  /* toggle switch */

  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 20px;
    // margin: 10px 0;
    float: right;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: 0px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 5px;
    bottom: 50%;
    transform: translateY(50%);
    background-color: white;
    transition: 0.4s all ease-in-out;
  }

  input:checked+.slider {
    background-color: var(--green);
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked+.slider:before {
    left: auto;
    right: 5px;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .range_label {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 0.75em;
    color: var(--gray6);
    font-weight: 600;
  }

  /* Feature section */

  .featured_jobs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #featured-3 {
    overflow-x: auto;
    width: 100%;
    margin: auto;
    // margin-top: 2em;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .py-3 {
      padding-top: 0.7rem !important;
    }
  }

  .all-filters {
    row-gap: 10px;
    max-width: 100% !important;
    // margin-top: 25px !important;
    margin-left: 0px;
    z-index: 99;
    position: relative;

    .f_t {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e2e2e2;
      border-radius: 100px;

      p {
        color: #9398a1;
      }
    }
  }

  .feature {
    padding: 1em 1em;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    margin: 10px 1.16%;
  }

  .feature_icon {
    margin-bottom: 1.563em;
    display: flex;
    justify-content: space-between;
  }

  .feature_icon p {
    flex: 0.8;
    text-align: right;
    font-size: 0.75em;
    color: var(--gray7);
    font-weight: 500;
  }

  .featured_brand_icon {
    width: 40px;
  }

  .feature_content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .candidate-card-head {
      display: flex;

    }

    .candidate-card-basic {
      flex: 0.9 1;
      margin-bottom: 0.5rem !important;

      p {
        word-break: break-word;
        margin: 0 !important;
      }
    }

    .circle-rating {
      height: 40px;
      width: 40px;
      flex: 0.2 1;
    }


  }

  .feature_buttons {
    justify-content: space-between;
    margin-top: auto;
  }

  .feature_content h2 {
    font-size: 1.5em;
    color: var(--black3);
    font-weight: 600;
    margin-bottom: 0.625em;
    overflow: hidden;
  }

  .feature_content h6 {
    font-size: var(--p1);
    font-weight: 500;
    color: var(--black3);
    margin-bottom: 0.625em;
  }

  .feature_content p {
    color: var(--gray7);

    .paragraph_3 {
      font-size: 0.87em;
      font-weight: 400;
    }
  }

  .paragraph_1 {
    font-weight: 700;
    color: var(--black3) !important;
    overflow: hidden;
    margin-bottom: 0 !important;
  }


  .feature_content .feature_content_tags span {
    background-color: var(--gray3);
    padding: 5px 10px;
    font-size: 0.875em;
    color: var(--gray8);
    font-weight: 500;
    border-radius: var(--border-radius);
    margin-right: 10px;
  }

  .feature_buttons button {
    padding: 0.625em 0em;
    font-size: 1.125em;
    font-weight: 500;
    border: 0;
    border-radius: var(--border-radius);
  }

  .feature_buttons :nth-child(1) {
    background-color: var(--green);
    color: white;
    width: 45%;
  }

  .feature_buttons :nth-child(2) {
    background-color: var(--gray3);
    color: var(--black3);
    width: 45%;
  }

  .f_t_box {
    display: flex;
    max-width: 90%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: 0;
  }

  .f_t_box::-webkit-scrollbar {
    display: none;
  }

  .f_t {
    display: flex;
    align-items: center;
    background-color: var(--light-blue);
    width: fit-content;
    padding: 3px 1.15em;
    border-radius: 20px;
    margin-right: 0.938em;
    white-space: nowrap;
  }

  .f_t p {
    color: var(--green);
    font-size: 0.675em;
    font-weight: 500;
    margin-right: 0.75em;
    margin-bottom: 0;
  }

  .f_t img {
    width: 10px;
    margin: 0px !important;
  }

  .del_jt {
    background: none;
    border: none;
  }


  .hover:hover {
    background-color: var(--gray2);
    border-left: 3px solid var(--green);
  }

  .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: green !important;
  }

  .custom-checkbox .custom-control-input:active~.custom-control-label::before {
    background-color: #c8ffc8;
  }

  .job_footer {
    visibility: hidden;
    position: absolute;
    height: 1px;
    overflow: hidden;
  }

  // .page-size-info {
  //   text-align: center;
  // }

  @media (min-width: 1200px) {
    .candidate-list .col-xl-4 {
      flex: 0 0 auto;
      width: 31%;
    }
  }

  @media only screen and (max-width: 576px) {
    .body_section {
      padding-top: 5em !important;
    }

    .body_section {
      padding: 0px 2em;
    }

    // .search_section{
    //   padding: 0px 2em;
    // }
  }

  @media only screen and (max-width: 990px) {
    // .result_filter {
    //   display: none;
    // }

    .body_section {
      min-height: auto;
      margin-bottom: 30px;
    }

    .blank_content {
      margin: 0%;
      padding: 0;
    }



    .job_type_search {
      visibility: hidden;
      position: absolute;
    }

    .sticky-effect {
      margin-left: 0% !important;
    }

    .job_footer {
      visibility: visible !important;
      position: fixed;
      bottom: 0;
      height: auto !important;
      flex-direction: column;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2000;
      overflow: auto;

      .job_footer_button {
        box-shadow: 3px 9px 12px 3px var(--black);
        display: flex;
        align-items: center;
        background: none;
        background: white;
        border: none;
        padding: 15px 0px;
        justify-content: center;
        width: 100%;

        img {
          width: 20px;
          margin-right: 10px;
        }

        h2 {
          margin-bottom: 0px;
          font-size: 1.875em;
          font-weight: 600;
        }
      }

      .job_footer_button.show {
        visibility: hidden;
        z-index: -10;
        position: absolute;
      }

      .result_filter_job {
        display: visible;
        position: relative !important;
        height: 70vh;
        overflow-y: scroll;
        width: 100%;
        background: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        .input-group {
          border: 1px solid var(--gray5);
          border-radius: var(--border-radius);
          width: 90%;
        }

        .react-datepicker-popper {
          z-index: 1;
        }

        .react-datepicker-wrapper {
          flex: 1;
          height: 100%;
        }

        .react-datepicker__input-container {
          height: 100%;
        }

        .input-group span {
          background-color: transparent;
          border: none;
          font-size: 1.25em;
          font-weight: 600;
        }

        .input-group span img {
          width: 1.125em;
        }

        .input-group input {
          border: none;
          outline: none;
          background-color: transparent;
          box-shadow: none;
          height: 3.125em;
          font-size: 1.125em;
          color: #11263c;
          font-weight: 500;
          width: inherit;
        }

        .result_header {
          width: 100vw;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          position: fixed;
          padding-left: 5%;
          padding-top: 10px;
          z-index: 200;
          background: white;

          h2 {
            font-size: 1.875em;
            font-weight: 600;
            color: var(--black3);
          }
        }

        form {
          padding: 20px 5%;

          .more {
            p {
              color: var(--green);
            }
          }

          input {
            margin-right: 10px;
            margin-top: 0;
          }

          .location_filter_search input[type="text"] {
            height: 3.9em;
          }

          label {
            margin-bottom: 0;
          }

          .checkbox_div {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }

          .location_filter_search {
            margin-bottom: 1.625em;
          }
        }

        .more p {
          font-size: 0.9em;
        }

        p {
          font-size: 1.5em;
          color: var(--black3);
          font-weight: 600;
          font-family: "Plus Jakarta Sans", sans-serif;
          margin-bottom: 20px;
          width: 95%;
        }

        .result_button {
          button {
            background: var(--green);
            width: 80%;
            margin: auto;
            font-size: 1.25em;
            border-radius: 20px;
            margin-bottom: 20px;
            color: white;
            box-shadow: none;
          }
        }
      }

      .result_filter_job.hidden {
        visibility: hidden;
        opacity: none;
        z-index: -10;
        position: absolute !important;
        height: 1px;
        overflow-y: scroll;
        width: 1px;
        background: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
    }

    .result_feed {
      flex-direction: column;

      .result_header {
        align-items: center;
        width: inherit;
        // margin-top: -10px;
        // margin-left: -1px;
        position: relative;
        padding-right: 0px;

        h2 {
          font-size: 1.5em !important;
          margin-bottom: 0px;
        }
      }
    }

    #featured-3 {
      padding: 0 0 30px 0;
      //  margin-bottom: 40px ;
      padding-bottom: 70px !important;

      .row {
        margin: 0 2px;
      }
    }

    .result_feed {
      flex: 1;
    }

    .suggestion_box {
      width: 90%;
    }

    .footer_upper {
      display: none;
      visibility: hidden;
      position: absolute;
      height: 1px;
      overflow: hidden;
    }

    .feature {
      margin: 10px 0;
    }
  }

  span.react-datepicker__month-read-view--down-arrow {
    top: 5px !important;
    border-color: #ccc !important;
    border-style: solid !important;
    border-width: 3px 3px 0 0 !important;
  }

  span.react-datepicker__year-read-view--down-arrow {
    top: 5px !important;
    border-color: #ccc !important;
    border-style: solid !important;
    border-width: 3px 3px 0 0 !important;
  }

  .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--scroll {
    left: 20px;
    position: relative;
  }

  .react-datepicker__year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
    width: 180%;
    top: 10px;
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 990px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (min-width: 2200px) {
    .result_filter .result_header {
      width: 17.4% !important;
    }
  }

  .suggestion_box {
    padding: 0;
    position: absolute;
    background: white;
    z-index: 200;
    padding: 0px;
    width: 32.5em;
    max-height: 150px;
    overflow-y: scroll;
    margin-top: 0.75em;
    box-shadow: 0px 7px 8px -4px lightgrey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
      padding: 10px;
      border-bottom: solid 2px #f5f6f7;
    }
  }

  .suggestion_box::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    .topTitle {
      margin-left: 0;
    }

    .filter_tab_header .list-group .list-group-item {
      margin-left: 10px;
    }

    .sticky-search {
      padding: 42px 0 20px 0;
    }

    .sticky-search .search-type {
      padding: 24px 3em 0 3em;
    }

    .result_header_sort {
      margin-right: 0;
    }

    .filter_tab_header .list-group .round {
      width: 2.125em;
      height: 2.125em;
    }

    #featured-3 {
      margin-bottom: 0px;
      padding-bottom: 20px !important;
    }

    .result_feed .result_header h2 {
      font-size: 1.2em !important;
    }

    .search_button p {
      font-size: 12px;
    }

    .job_search .search_box input {
      padding-left: 5px;
    }

    .search_button {
      padding: 0 20px;
    }

    .search_box input {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .candidate_page+.footer_upper {
    padding-bottom: 30px;
  }
}

.sticky {
  width: 16.3% !important;
}

.sticky-effect {
  margin-left: 20%;
}