.brands-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  flex-wrap: wrap;
  .individual-brand-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 10px rgba(49, 65, 88, 0.1215686275);
    border-radius: 12px;
    opacity: 1;
    border: solid 1px #fff;
    img{
        max-height: 60px;
        max-width: 130px;
        object-fit: contain;
        padding: 0 0 0.938em;
    }
  }
}
