// input {
//   outline: none;
//   border: 0;
//   background: transparent;
//   box-shadow: none;
//   font-size: var(--p1);
//   font-weight: 500;
//   color: var(--text-color);
//   &.bg-transparent{
//     background-color: transparent !important;
//   }
// }
//   .left_content_3 .input-group input {
//     // background-color: transparent;

//   }
//   .form-control{
//     line-height: 2;
//   }
/* Hide the password input by default */
#passwordInput,
#newPasswordInput,
#confirmPasswordInput {
  -webkit-text-security: disc;
  /* Safari-specific property to hide password */
  text-security: disc;
  /* Hide password */
  padding-right: 30px;
  /* Space for the toggle button */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.password_input {
  position: relative;
}

/* Style for the eye icon */
.togglePassword {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
}

/* Icon for showing the password */
.togglePassword:before {
  content: "🔒";
}

/* Style when the password is hidden */
.showpass .password_input .togglePassword:before {
  content: "👁️" !important;
}

.showpass .password_input #passwordInput,
#newPasswordInput,
#confirmPasswordInput {
  -webkit-text-security: none !important;
  /* Safari-specific property to hide password */
  text-security: none !important;
  /* Hide password */
}

.passbtn {
  position: relative;
}

.password-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.show-password-btn {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
}

.login_sub_heading,
label {
  color: var(--black);
  font-size: var(--p);
  font-weight: 500;
  line-height: 21px;
}

.form-control {
  padding: 0.575rem 0rem;
  font-size: var(--p);
  font-weight: 600;
  line-height: 1.5;
}

.input-group-text {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid var(--border-light);
  border-radius: 0;
}

.form-control:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}