.apply_page {
  padding-top: 75px;
  font-size: 16px;

  .cards__container{
    grid-template-columns: repeat(auto-fit, minmax(180px, 245px));
  }

  .sec_one {

    width: 100%;
    // margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .sec_head {

    width: 100%;
    margin-bottom: 10px;
    // margin: auto;
    display: flex;
    // gap: 10px;
    align-items: center;
  }

  .sec_one_one {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 20px;
  }

  .sec_one_two {
    // flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-bottom: auto;
    // padding-top: 50px;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .sec_one_three {
    // flex: 0.8;
    padding-top: 1.875em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .round {
    background-color: var(--white2);
    border-radius: 50%;
    padding: 0.625em;
    width: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 35px;
    margin-left: 0.625em;
  }

  .posted span {
    margin-right: 0.625em;
    color: var(--gray4);
    font-size: var(--p1);
    font-weight: 500;
  }

  .posted {
    font-size: var(--p1);
    font-weight: 500;
    margin-top: 0.625em;
  }

  .round img {
    width: 2.25em;
  }

  .logo-microsoft {
    height: 3.5em;
  }

  .logo-content {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--black3);
  }

  .logo-para {
    font-size: 0.875em;
    font-weight: 400;
    color: var(--black4);

    .bi.bi-dot {
      color: var(--gray4);
    }
  }

  .btn-apply {
    background-color: var(--green);
    color: var(--white);
    font-size: 1.125em;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    border-radius: 8px !important;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 140px;

    img {
      margin-right: 10px;
      margin-bottom: 3px;
    }
  }

  .btn-closee {
    background-color: #fff2ef;
    color: var(--red);
    padding: 0.375em 1.25em;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 1.375em;
  }

  .btn-apply:hover {
    background-color: var(--green);
  }

  .btn-default {
    background-color: #ffffff;
    color: var(--green);
    border: 1px solid var(--gray5);
    // padding: 0.375em 1.25em;
    font-size: var(--p1);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    height: 40px;
    width: 130px;
  }

  .btn-default:hover {
    // background-color: var(--green);
    // color: var(--white);
  }

  /* second section*/

  .second-section {
    display: flex;
    width: 100%;
    // margin: auto;

    padding-top: 1.25em;
    padding-bottom: 5em;
  }

  .third-section {
    width: 100%;
    // margin: auto;
  }

  .second-section .left {
    flex: 0.7;
  }

  .second-section .right {
    flex: 0.3;
    margin-left: 1.25em;
    margin-bottom: 1.25em;
  }

  .second-section .right .about-company {
    padding: 1.25em 1.25em;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #00000010;
    border-radius: 12px;
    height: fit-content;
    margin-bottom: inherit;
  }

  .about-company-name {
    text-decoration: none;
    color: black;
  }

  .right h2 {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--black3);
  }

  .right p:nth-child(2) {
    font-size: var(--p1);
    font-weight: 500;
  }

  .right .para_2 {
    font-size: var(--p1);
    font-weight: 400;
    text-align: justify;
  }

  .right .para_3 {
    font-size: var(--p1);
    font-weight: 500;
  }

  .green-txt {
    font-size: 1.125em;
    font-weight: 600;
    color: var(--green);
  }

  .comp-website {
    text-decoration: none;
  }

  .work-upper {
    display: flex;
    justify-content: space-between;
    // border: 1px solid var(--gray5);
    // border-top-left-radius: 0.625em;
    // border-top-right-radius: 0.625em;
    overflow-wrap: anywhere;
  }

  .work-upper div {
    flex: 0.25;
    padding: 1.25em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-wrap: anywhere;
  }

  .work-upper div p:nth-child(1) {
    color: var(--gray8);
    font-size: var(--p1);
    font-weight: 500;
  }

  .work-upper div p:nth-child(2) {
    font-size: 1.125em;
    font-weight: 500;
    color: var(--black3);
  }

  .work-lower {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--gray5);
    // border-bottom-left-radius: 0.625em;
    // border-bottom-right-radius: 0.625em;
    overflow-wrap: anywhere;
  }

  .work-lower div {
    flex: 0.25;
    padding: 1.25em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-wrap: anywhere;
  }

  .work-lower div p:nth-child(1) {
    color: var(--gray8);
    font-size: var(--p1);
    font-weight: 500;
  }

  .work-lower div p:nth-child(2) {
    font-size: 1.125em;
    font-weight: 500;
    color: var(--black3);
  }

  .work_exp {
    border-right: 1px solid var(--gray5);
  }

  .work_job {
    border-right: 1px solid var(--gray5);
  }

  .work_loc {
    border-right: 1px solid var(--gray5);
  }

  .work_telecommute {
    border-right: 1px solid var(--gray5);
  }

  .job_joining {
    border-right: 1px solid var(--gray5);
  }

  .overview {
    padding: 0em 20px;
    text-align: justify;
  }

  .overview h2 {
    font-size: 1.25em;
    font-weight: 600;
    margin-top: 1rem;
  }

  .overview p {
    font-size: var(--p1);
    font-weight: 400;
    // word-break: break-all;
  }

  /* section 3 */

  .respon {
    margin: 1em 20px;
  }

  .respon h2 {
    font-size: 1.25em;
    font-weight: 600;
    margin-top: 1rem;
    padding-bottom: 0.25em;
  }

  .respon p {
    font-size: var(--p1);
    font-weight: 600;
    padding-bottom: 1em;
  }

  .respon .job-type-span {
    font-size: var(--p1);
  }

  .respon ul {
    list-style: none;
    padding: 0px;
  }

  .respon ul li {
    font-size: var(--p1);
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }

  .circle {
    border-radius: 50%;
    width: 0.625em;
    height: 0.625em;
    padding: 3px;
    display: flex;
    margin-right: 1.25em;
    border: 2px solid var(--green);
  }

  .benifits {
    padding: 2.5em 0px;
    padding-bottom: 5em;
  }

  .benifits h2 {
    font-size: 1.5em;
    font-weight: 600;
    padding-bottom: 1.25em;
  }

  .benifits ul {
    list-style: none;
    padding: 0px;
  }

  .benifits ul li {
    display: flex;
    align-items: center;
    font-size: 1.125em;
    font-weight: 600;
    margin-bottom: 1em;
  }

  .bgBorderShadow {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #00000010;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 10px;
  }

  .no_jobs {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 3.125em;
    }

    h2 {
      font-size: 1.5em;
      font-weight: 600;
      color: #263238;
      margin: 1.25em;
    }
  }

  // @media only screen and (max-width: 1600px) {
  //   .sec_one_two {
  //     padding-top: 40px;
  //   }
  // }

  @media only screen and (max-width: 1030px) {
    .sec_one_two {
      padding-top: 38px;
    }
  }

  @media only screen and (max-width: 800px) {
    .second-section {
      flex-direction: column;

      .right {
        margin: 0px;
      }
    }

    .sec_one_two {
      margin-top: 0.75em;
      padding-top: 0;
    }

    .round img {
      width: 2.5em;
    }

    .work {
      overflow: auto;

      .work_job {
        white-space: nowrap;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .sec_one_three {
      margin-top: 7em;
    }

    .sec_one_two {
      padding-top: 10px;
    }
  }

  @media only screen and (max-width: 576px) {
    .sec_one_three {
      margin-top: 7em;
    }

    .second-section {
      padding: 2em !important;
    }

    .sec_one {
      padding: 0em 2em;
    }

    .sec_head {
      padding: 0em 2em;
    }
  }

  @media only screen and (max-width: 310px) {
    .sec_one_three {
      margin-top: 12em;
      font-size: x-small;
    }
  }

  /*  Result section */

  .result_section {
      /* width: 90%; */
      background-color: var(--white);
      display: flex;
      /* margin-left: 20px; */
      /* margin-right: 20px; */
      padding: 0em 20px;

    div {
      width: 100%;
    }

    .view-candidate {
      text-decoration: none;
      color: black;
    }

    .view-candidate:hover {
      color: blue;
    }
  }

  .result_header_sort {
    .dropdown-toggle {
      background-color: transparent;
      border: none;
      color: black;
      display: flex;
      text-decoration: none;
      align-items: center;
      padding: 0;
      font-weight: 600;
      font-size: 1.063em;
    }
  }

  .list-group {
    display: flex;
    flex-direction: row;
    height: 55px;
  }

  .list-group-item.active {
    background-color: white;
    border: none;
    font-size: 1.5em;
    font-weight: 600;
    border-radius: 0px;
    color: var(--black3);
    border-bottom: 3px solid var(--green);
  }

  .list-group-item:hover {
    background-color: white;
    border-bottom: 3px solid var(--green);
  }

  .list-group-item {
    border-radius: 0px !important;
    color: var(--gray8);
    font-size: 1.5em;
    font-weight: 400;
    font-family: "Plus Jakarta Sans", sans-serif;
    border: 0px;
    width: auto;
    margin-right: 37px;
    padding: 0.5em 0px;
  }

  .tab-content {
    margin-top: 2.188em;
  }

  .tab-content>.tab-pane {
    height: 24.625em;
    overflow: auto;
  }

  .tab-content>.tab-pane::-webkit-scrollbar {
    display: none;
  }

  .tab-content>.tab-pane {
    -ms-overflow-style: none;
    scrollbar-width: thin;
  }


  .results {
    cursor: pointer;
    border-left: 1px solid var(--input-border);
  }

  .results:hover {
    background-color: var(--gray2);
    border-left: 3px solid var(--green);
    margin: -5px;
    cursor: pointer;
  }

  .viewed-profile {
    background-color: var(--viewed);
  }

  .action-icon {
    width: .8rem;
    cursor: pointer;
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .sec_one_two {
      flex: 1;
    }

    .btn-apply,
    .btn-closee {
      padding: 0.375em 0.51em;
      font-size: 1.2em;
    }
  }

  @media only screen and (min-width: 577px) and (max-width: 767px) {
    .second-section {
      padding-top: 50px;
    }

    .sec_one_two {
      padding-left: 35px;
    }
  }

  @media only screen and (max-width: 767px) {
    .sec_one_three {
      position: absolute;
      right: 5%;
    }

    .posted {
      flex-direction: column;
    }

    .sec_one_one {
      flex: 1;
    }

    .sec_one {
      flex-direction: column;
    }

    .result_section .filter_tab {
      overflow-x: auto;
    }

    .result_section div::-webkit-scrollbar {
      display: none;
    }

    .result_section div {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .btn-apply,
    .btn-closee {
      padding: 0.375em 1em;
      font-size: 1.2em;
    }

    .work-lower,
    .work-upper {
      flex-direction: column;

      div {
        border-bottom: 1px solid var(--gray5);
        border-right: none;
      }

      div:last-child {
        border-bottom: none;
      }
    }
  }
}