// .register_page
.register_pagee {
  // width: 100%;
  // overflow-y: hidden;
  // padding-top: 3rem;
  // min-height: calc(100vh - 160px);
  // padding-bottom: 100px;
  // max-height: 100vh;

  .main {
    // width: 100%;
    // display: flex;
    // gap: 20px;
    // height: 100vh;
    // max-height: 100vh;
  }

  .select_profile {
    // max-width: 100%;
  }

  /* container */

  .main .left {
    // flex: 0.4 1;
    // width: 50%;
    // display: flex;
    // flex-direction: column;
    // background: url(../../Assets/svgs/bg.svg), no-repeat;
    // background-size: cover;
    // background-position: center;
    // background-color: var(--gray3);
    // height: 100vh;
    // min-height: 100vh;
    // max-height: 87vh;
    // justify-content: center;

    .left_content.margin-t {
      margin-top: 15rem;
    }
  }

  .left .cover_image {
    width: 82%;
    margin: auto auto 40px 10%;
  }

  .main .right {
    // margin-bottom: 30px;
    // overflow: scroll;
    // flex: 1;
    width: 50%;
    display: flex;
    // max-height: 88vh;
  }

  .content {
    font-size: 1.5em;
    font-weight: bold;
    color: var(--black2);
    text-align: center;
    margin: 0 20px 0px 20px;
  }

  .content span {
    margin-top: 68px;
    margin-left: 0px;
    font-weight: bold;
    color: var(--green);
  }

  .content-button {
    border: 1px solid;
    font-family: "Plus Jakarta Sans", sans-serif;
    color: var(--green);
    border-radius: 8px !important;
    font-size: var(--p1);
    font-weight: 600;
    margin: 2% 5% 30px 10%;
    padding: 0.625em;
    width: 78%;
  }

  .right::-webkit-scrollbar {
    display: none;
  }

  .right .login {
    display: flex;
  }

  .login {
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-top: 110px;
    margin-left: 190px;
  }

  .right {
    display: flex;
    overflow-y: scroll;
  }

  

  .company_heading {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 50px;
  }

  .filter_tab {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 0.875em;
      color: #2c2c2c;
      font-weight: 500;
      margin-right: 10px;
    }
  }

  .list-group {
    display: flex;
    flex-direction: row;
  }

  .list-group-item:hover {
    background: white;
  }

  .list-group-item.active {
    color: white !important;
    font-size: 0.875em;
    font-weight: 500;
    color: var(--black3);
    background-color: var(--green);
    transition: 0.3s;
  }

  .list-group-item {
    border: 1px solid var(--gray5) !important;
    padding: 8px 12px;
    border-radius: 10px !important;
    color: #2c2c2c;
    display: flex;
    height: fit-content;
    font-size: 0.875em;
    font-weight: 500;
    text-decoration: none;
    align-items: center;
    width: auto;
    margin-right: 25px;
  }

  .list-group-item .circle {
    height: 1.25em;
    border: 1px solid gray;
    border-radius: 50%;
    background-color: white;
    width: 1.25em;
    margin-right: 10px;
  }

  .list-group-item .circle .checked {
    border: 2px solid var(--green);
    border-top: none;
    border-right: none;
    content: "";
    height: 0.313em;
    left: 15px;
    opacity: 0;
    position: absolute;
    top: 15px;
    transform: rotate(-45deg);
    width: 0.75em;
  }

  .list-group-item.active .circle .checked {
    opacity: 1;
    transition: 0.3s;
  }

  .form-floating>label {
    margin-left: 0px;
    font-size: var(--p1);
    font-weight: 500;
    color: var(--black2);
    padding: 0.6rem 0.75rem;
    white-space: nowrap;
  }

  .form-floating>.form-control,
  .form-floating>.form-select {
    height: 3em;
    line-height: 1.25;
  }

  // .PhoneInput input {
  //   border: none;
  //   outline: none;
  //   background-color: #f7f7f7;
  //   box-shadow: none !important;
  //   // min-width: 188%;
  //   height: 3em !important;
  //   font-size: 0.875em;
  //   font-weight: 500;
  //   color: var(--black2);
  // }

  .form-floating>.form-control:focus~label,
  .form-floating>.form-control:not(:placeholder-shown)~label,
  .form-floating>.form-select~label {
    opacity: 1;
    font-size: 0.94em;
    font-weight: 400;
    color: var(--gray8);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }

  .form-floating>.form-control {
    margin-left: 0px;
    background: transparent;
    border: 0px;
    box-shadow: none;
    font-size: 0.875em;
    font-weight: 500;
    color: var(--black2);
  }

  .input_section {
    height: 4em;
    margin-top: 20px;
    background-color: var(--gray3);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .form-floating>.form-select {
    padding: 12px 0 0 10px;
    margin-top: 20px;
    background-color: var(--gray3);
    border: 0px;
    color: var(--black2);
    border-radius: var(--border-radius);
    box-shadow: none;
    font-size: 0.875em;
    font-weight: 500;
    height: 4em;
  }

  .input_icon {
    margin: 0 10px;
    cursor: pointer;
  }

  .input_icon img {
    width: 15px;
  }

  .input_section .form-floating {
    width: 100%;
  }

  .mobile-text {
    display: none;
  }

  .form_button {
    background-color: var(--green);
    color: var(--white);
    border-radius: var(--border-radius);
    font-size: 0.875em;
    font-weight: 500;
    border: 0px;
    padding: 1em;
    width: 100%;
  }

  // .or_line {
  //   position: relative;
  //   text-align: center;
  // }

  // .or_line:after {
  //   position: absolute;
  //   content: "";
  //   background-color: #dcdcdc;
  //   height: 1px;
  //   width: 44%;
  //   left: 0;
  //   right: auto;
  //   bottom: 10px;
  // }

  // .or_line:before {
  //   z-index: 1;
  //   position: absolute;
  //   content: "";
  //   background-color: #dcdcdc;
  //   height: 1px;
  //   width: 44%;
  //   left: auto;
  //   right: 0;
  //   bottom: 10px;
  // }

  .agree_div {
    display: flex;
    align-items: center;
  }

  #checkbox_agree {
    height: 15px;
    width: 15px;
    margin-right: 8px;
  }

  .agree_txt {
    font-size: 0.75em;
    font-weight: 400;
    margin-top: 1%;
  }

  .login_txt {
    font-size: 0.875em;
    font-weight: 400;
  }

  .body-links {
    cursor: pointer;
    text-decoration: none;
    font-weight: 400;
    color: var(--green);
  }

  footer {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    background-color: var(--black4);
    color: var(--white);
    font-size: var(--p1);
    text-align: center;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
  }

  /* round check box */

  .round {
    position: relative;
  }

  .round label {
    background-color: #fff;
    border: 1px solid var(--gray4);
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }

  .round label:after {
    border: 2px solid var(--green);
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked+label {
    background-color: var(--white);
    border-color: var(--white);
  }

  .round input[type="checkbox"]:checked+label:after {
    opacity: 1;
  }

  .suggestion_box {
    background: white;
    z-index: 1000;
    padding: 0px;
    width: inherit;
    position: absolute;
    max-height: 150px;
    overflow-y: scroll;
    box-shadow: 0px 7px 8px -4px lightgrey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    p {
      padding: 0.625em;
      border-bottom: solid 2px #f5f6f7;
    }
  }

  .select_profile {
    .main_heading {
      font-size: 1.5em;
      font-weight: 600;
      margin-bottom: 10%;
    }

    .select_profile-content {
      column-gap: 20px;
      row-gap: 20px;
      flex-wrap: nowrap !important;

      .select_profile-card {
        // width: 248px;
        // height: 244px;
        border: 1px solid #dbdbdb;
        border-radius: 24px;
        text-align: center;
        padding: 20px 10px 10px 10px;
        width: 180px !important;

        p {
          margin: 20px;
          color: var(--gray4);
          font-size: 0.875em;
        }

        span {
          color: var(--gray4);
          font-size: 0.75em;
        }
      }

      .select_profile-card.active {
        border: 2px solid var(--green) !important;
      }
    }
  }



  .candidate-form {
    display: flex;
    justify-content: center;
  }

  // .social {
  //   display: flex;
  //   flex: 0.26 1;
  //   margin: 2% 0% 0 auto;
  //   height: 100vh;
  //   flex-direction: column;
  // }

  .social-text {
    margin: 20% 0px 0px 50%;
    height: 45%;
    display: flex;
  }

  .seperator {
    position: relative;
    height: 150px;
    width: 5px;
    display: block;
    text-align: center;
    padding-top: 135px;
    color: #898989;
  }

  .seperator:before {
    border-left: 1px solid #898989;
    content: "";
    display: block;
    height: 133px;
    left: 20px;
    position: absolute;
    top: 0px;
    width: 1px;
    z-index: 0;
  }

  .seperator:after {
    border-left: 1px solid #898989;
    content: "";
    display: block;
    height: 133px;
    left: 20px;
    position: absolute;
    top: 155px;
    width: 1px;
    z-index: 0;
  }

  .ss-b {
    text-align: center;
    margin-bottom: 20px;
  }

  .social-btn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    color: black;

    img {
      width: 13px;
      margin: 0px 5px 2px 0px;
    }
  }

  @media only screen and (max-width: 990px) {
    // overflow-y: scroll;

    .navbar {
      min-height: 55px;
    }

    .navbar-collapse {
      padding: 15px 0;
    }

    body {
      overflow: scroll;
    }

    .main {
      .left {
        background-size: 615px !important;
        background-position: bottom 0px right -100px !important;

        .left_content {
          margin-bottom: 2rem;
        }
      }
    }

    .content-button {
      width: 80%;
    }

    .right_content {
      width: 80%;
    }

    footer {
      position: relative;
    }

    .right_content {
      margin: 50px auto;
    }

    #checkbox1 {
      width: 12px;
      height: 12px;
      margin-right: 12px;
    }

    .checkbox_section {
      flex-direction: column;
    }

    .checkbox_item1,
    .checkbox_item2 {
      margin: 10px 0px;
    }

    .form-floating>.form-control,
    .form-floating>.form-select {
      height: 3.9em;
    }

    .filter_tab {
      p {
        white-space: nowrap;
        margin-right: 1.25em;
      }
    }

    .company_heading {
      margin-top: 10px;
    }

    #checkbox_agree {
      height: 1.75em;
      width: 1.75em;
      margin-right: 8px;
    }
  }

  @media only screen and (min-height: 657px) and (max-height: 800px) {
    .main {
      .right .right_content {
        position: relative;
        top: 2rem;
        // justify-content: flex-start;
      }

      .select_profile {
        top: -2rem;
        position: relative;
      }

      .right_content:has(div.select_profile) {
        justify-content: center;
      }
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {

    .content,
    .content_heading {
      font-size: 1.25em;
    }
  }

  @media only screen and (min-width: 375px) and (max-width: 768px) {
    .file-upload-wrapper {
      width: 300px !important;

      .file-upload {
        width: 300px !important;
      }
    }
  }

  @media only screen and (max-width: 320px) {
    .file-upload-wrapper {
      // width: 250px !important;
      // margin-left: 35px;

      .file-upload {
        // width: 250px !important;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .main {
      .left {
        flex-direction: unset;
        align-items: center;
        flex: 0.35 1;
      }

      .content-button {
        margin: 2% 5% 30px 10%;
      }

      .right_content {
        // margin: 50px auto auto 17%;
      }

      .select_profile-content {
        width: 90%;
        padding: 0% 0% 0% 0%;
      }

      .mobile-text {
        display: block !important;
      }

      .social {
        // margin: 24% 0% 0 auto !important;
        display: none;
      }
    }

    .input_section {
      margin: 12px 0px;
    }

    .body-links {
      font-size: 1.1em;
    }

    .content,
    .content_heading {
      font-size: 1.25em;
    }

    .content-button {
      font-size: 0.875em;
    }

    .form-floating>.form-select {
      height: 4em;
      font-size: 0.9em;
    }
  }

  @media only screen and (max-width: 767px) {
    max-height: none !important;

    .main {
      flex-direction: column;
      max-height: none !important;

      .main_heading {
        margin-bottom: 1rem !important;
        font-size: 1.25em;
      }

      .left {
        .left_content {
          margin: 0 auto;
          margin-bottom: 2rem;
          text-align: center;
          max-height: none !important;
        }

        .cover_image {
          width: 68%;
          height: 60%;
          margin: 20px auto 20px auto;
          padding: 0 20px;
        }

        .left_content.margin-t {
          margin-top: 0;
        }
      }

      .right {
        max-height: none !important;

        .select_profile-content {
          flex-direction: column;
          // width: 80%;
        }
      }
    }

    .input_section {
      margin: 12px 0px;
    }

    .body-links {
      font-size: 1.1em;
    }

    .content,
    .content_heading {
      font-size: 1.8em;
    }

    .content {
      margin: 15px 15px 0 35px;
      text-align: center;
      width: 80%;
      font-size: 1.2em;
    }

    .content-button {
      width: 80%;
      margin: 15px 15px 0 15px;
    }

    .select_profile-content {
      padding: 0% 0% 0% 10%;
    }

    .right_content {
      margin: 20px auto;
      width: 100%;

      .form-div {
        padding: 20px 20px 20px 20px;
      }

      .form-div.header {
        padding: 20px 20px 20px 0 !important;
      }

      .right_heading {
        margin-bottom: 1rem !important;
        font-size: 1.25em;
      }

      .mobile-text {
        display: block !important;
      }
    }

    .form-control {
      font-size: var(--p1);
    }

    .social {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }
}