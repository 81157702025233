.wrapper {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 1rem;
  /* max-width: 533px !important;
  margin-left: auto;
  margin-right: auto; */
  font-family: "Plus Jakarta Sans", sans-serif;
  min-height: 700px;
  width: 100%;
  background-color: white;
}
.mainMenu {
  display: grid;
  grid-template-columns: 30% 50%;
  place-content: center;
  /* grid-gap: 1rem; */
}
.leftMenu {
  display: flex;
  flex-direction: column;
  /* background-color: #5c6976; */
  flex-direction: column;
  /* color: #fff; */
  /* color: #fff; */

  padding-left: 2rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  /* margin-right: 1rem; */
  .secTwo{
    margin-top: 6px;
  }
}
.rightMenu {
  display: flex;
  overflow: hidden;
  word-wrap: break-word;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  /* width: 3.2%; */
}

.leftMenu > .secOne > .name {
  font-size: 1.5rem;
  font-weight: 600;
}
.leftMenu > .secOne > .des {
  font-size: 13px;
  opacity: 0.6;
  text-transform: capitalize;
}
.profile {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  page-break-inside: avoid;
  break-inside: avoid-page;
}
.hightlightTitle {
  margin-top: 20px !important;
  margin-bottom: 14px !important;
}
h2 {
  font-size: 1.00rem;
  font-weight: 600 !important;
  /* margin-top: 20px !important; */
}
.profileP {
  font-size: 12px !important;
  opacity: 0.6;
}

.value {
  font-size: 12px;
  opacity: 0.6;
}
.valueCap {
  font-size: 0.8rem;
  opacity: 0.6;
  text-transform: capitalize;
}
.row {
  margin-bottom: 0.5rem;
}

.label {
  font-size: 12px !important;
  font-weight: 300 !important;
}
.flexRow1 {
  margin-bottom: 1rem;
}
.Wrapper {
  width: 100%;
  height: 100%;
  /* padding: 2rem 5rem;  */
  margin: 2rem;
  /* padding: 2rem; */
}
.bgImg {
  /* background-image: url("../public/logo192.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.heading {
  margin-top: 8px;
  margin-bottom: 4px;
}

.header {
  /* border-bottom: 1px solid #e2e2e2; */
  /* margin-bottom: 2rem; */
  /* margin: 2rem; */
  margin-left: 9.5rem;
}
.header > h2 {
  font-size: 2.5rem;
  font-weight: 500;
}
.header > p {
  font-size: 1rem;
  opacity: 0.8;
}
.label {
  font-size: 14px;
  font-weight: 500;
}
.flexRow1 {
  margin-bottom: 1rem;
}

.box {
  margin-bottom: 1rem !important;
}
.companyName {
  font-size: 0.875rem;
  font-weight: 500;
}

.designation {
  font-size: 0.75rem;
  text-transform: capitalize;
}

.from {
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.9;
}

.descPoints {
  font-size: 12px !important;
}

.dot {
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #404040;
  margin-right: 0.5rem;
}
.flexRow {
  display: flex;
  align-items: center;
}
.skillWrp,
.language {
  max-width: 25rem;
}
.exp {
  margin-bottom: 0.5rem;
}
.content {
  page-break-after: always;
}
.PageBreak {
  page-break-after: always;
}
.wrapper ul li {
  list-style-position: inside;
  list-style-type: none;
  &:before {
    content: "• ";
  }
}
.wrapper ol li {
  list-style-position: inside;
  list-style-type: none;
  counter-increment: my-counter;
  &:before {
    content: counter(my-counter) ". ";
  }
}
