.work-profile {
  width: 100%;
  // padding-top: 3.95em;
  font-size: 16px;

  p {
    color: var(--light-text, #9398a1);
    font-size: 14px;
    margin-top: 0 !important;
    margin-bottom: 0;
  }

  &-content {
    display: flex;
    // padding: 0px 10rem;
    margin-top: -10px;
    flex-direction: column;
  }

  &-progress {
    position: sticky;
    top: 53px;
    width: 100%;
    background: #fff;
    z-index: 1;

    // display: block;
    // z-index: 1;
    // background : #fff;
    // width: 100%;
    // padding-top: 20px;
    // padding-bottom: 20px;
    &-content {
      width: 100%;
      padding-top: 2.75em;
      padding-bottom: 2em;

      h2 {
        font-size: 1.25rem;
        font-weight: 600;
        color: var(--black3);
        // margin-bottom: 30px;
        display: flex;
        align-items: center;
      }

      .form-buttons {
        display: flex;
        float: right;
        gap: 10px;
      }
    }

    .redirect-button {
      display: flex;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;

    h2 {
      font-size: 1.125rem !important;
      font-weight: 600 !important;
      color: var(--black3);
      margin-bottom: 10px;
    }

    .personal-details {
      margin-top: 20px;

      .upload {
        display: flex;
        align-items: center;
        color: var(--green);
      }

      .profile-upload p {
        font-weight: 600;
        color: var(--green);
        cursor: pointer;
      }
    }
  }

  &-form {}

  &-sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }

  .profile-img {
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }
}