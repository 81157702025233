.time_slot_wrapper {
  display: flex;
  gap: 30px;

  .calender {
    display: flex;
    justify-content: center;
  }

  .available_time_slots {
    flex: 0.8 1;
    margin: 0 auto;
    // padding-left: 100px !important;
    // padding-top: 30px !important;
    display: flex;
    flex-direction: column;
    // align-items: center;
    .tab-content .tab-pane {
      min-height: 31vh;
    }
  }

  .pills-wrapper {
    border: 1px solid #eaecf0;
    padding: 5px;
    border-radius: 4px;
    width: fit-content;
    margin-bottom: 24px;
  }

  .tab-item {
    .nav-link {
      color: black !important;
    }

    .active {
      background-color: #14bc9a !important;
      border-radius: 5px;
      color: white !important;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .available-slots-tab {
    width: 100%;
  }

  .times_wrapper {
    max-height: 250px;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .no-slot {
      align-items: center;
      display: flex;
      background-color: #f2f2f2;
      border-radius: 8px;
      padding: 12px 30px;

      img {
        margin-right: 20px;
      }

      p {
        color: #344054;
        font-size: 0.875em;
        font-weight: 400;
      }
    }

    .active-slot {
      // border: 1px solid var(--green) !important;
      background-color: var(--light-blue);
      color: var(--green) !important;
    }

    .time_label {
      border: 1px solid #eaecf0;
      border-radius: 5px;
      padding: 8px;
      color: black;
      font-size: 14px;
      font-weight: 400;
      margin-right: 14px;
      margin-bottom: 14px;
      display: inline-block;
      width: 160px;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }

  .book_slot_heading {
    color: black;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .appointment_button_wrapper {
    // margin-right: 200px;
    margin-right: 0 !important;
    font-display: flex;
    justify-content: center;
    align-items: center;
  }

  .back_btn {
    font-size: 14px !important;
    font-weight: 500;
  }

  .tab-content {
    flex: 1 !important;
    display: flex;
    // align-items: center;
    margin-top: 15px;
  }

  .form-select-lg {
    font-size: 0.875em !important;
  }

  @media only screen and (min-width: 768px) {
    .time_slot_wrapper {
      .calender {
        width: fit-content;
        justify-content: start;
      }

      .available_time_slots {
        padding-top: 0px !important;
        padding-left: 100px !important;
        align-items: start;
      }
    }
  }
}
