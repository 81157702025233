.main-body {
  padding-top: 92px;
}

.text-pm {
  color: var(--green);
}

.title {
  font-size: 16px;
}

.tt-text-primary {
  color: var(--green);
}

textarea {
  border: 1px solid #dbdbdb;
  box-shadow: none;
  font-size: 1.125em;
  font-weight: 500;
  color: #11263c;
  width: 100%;
  border-radius: 0.625em;
  padding: 0.625em 1.25em;
}

.add-btn {
  display: flex;
  align-items: center;
  font-size: var(--p1);
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  color: var(--green);
  margin-top: 20px;
  border: 0;
  background: none;

  img {
    margin-right: 10px;
    width: 20px;
  }
}

.row-gap-2 {
  row-gap: 8px;
}

.tab-content {
  margin-top: 0 !important;

  .tab-pane {
    margin-top: 1.5rem !important;
  }
}

.form-control {
  padding: 0.575rem 0.75rem;
}

.emp_box {
  display: flex;
  margin: 20px 0px;

  button {
    background: none;
    outline: none;
    border: none;
    height: 100%;
    margin: auto 5px;
  }
}

.emp_box div {
  text-align: left;
  display: flex;
  align-items: center;
  padding: 3px 10px;
}

.emp_box_det {
  flex: 1;
  justify-content: space-between;
  border: 1px solid var(--gray5);
  border-radius: var(--border-radius);
  text-align: left;
}

.emp_box:hover .emp_box_det {
  border: 1px solid var(--green);
}

.emp_box p {
  font-size: var(--p1);
  font-weight: 500;
  color: var(--gray4);
}

.emp_box .green_text {
  color: var(--black3);
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: var(--p1);
  font-weight: 600;
}

.emp_box.active .green_text {
  color: var(--green);
}

.emp_box_det div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.trash {
  width: 15px;
}

.emp_box img {
  margin-right: 3px;
}

@media screen and (max-width: 576px) {
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px;
  }
}

.view-similar {
  // box-shadow: 0px 3px 15px #3141581f;
  // padding: 15px;
  // border-radius: 18px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.06) 2px 4px 4px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: var(--border-light);
  padding: 1.25rem;
  transition: all 0.3s ease 0s;
  max-width: 290px;

  // gap: 10px;
  // min-height: 230px;
  &:hover {
    border-color: var(--green);
    .hover-popup {
      opacity: 1;
      bottom: 0;
    }

    .circular-popup {
      opacity: 1;
      right: 4px;
    }
  }

  .circular-popup {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    border: 1px solid var(--gray5);
    padding: 0.8rem;
    border-radius: 50%;
    opacity: 0;
    justify-content: center;
    align-items: center;
    background: var(--white);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    position: absolute;
    right: -100%;
    bottom: 3rem;
    z-index: 999;

    p {
      font-size: 0;
      font-weight: 600;
      transition: all 0.3s ease-in-out;
      text-wrap: nowrap;
      color: var(--green);
    }

    i {
      font-size: var(--h6) !important;
      color: var(--green) !important;
    }

    &:hover {
      width: 7.5rem;
      border-radius: 25px;
      gap: 0.5rem;

      p {
        font-size: var(--p);
      }
    }
  }

  .hover-popup {
    position: absolute;
    bottom: -100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: fit-content;
    opacity: 0;
    padding: 0.5rem;
    transition: all 0.4s ease;

    .btn {
      display: flex;
      align-items: center;
      border: 1px solid var(--gray5);
      gap: 0.5rem;
      width: 100%;
      text-wrap: nowrap;
      font-weight: 600;
      padding: 0.3rem !important;
      background-color: var(--white);
      flex-direction: row-reverse;
      margin-inline: auto;
      justify-content: center;

      i {
        font-size: var(--h6);
      }

      &:hover {
        border-color: var(--green);
      }
    }
  }
}
