.sort_by {
    margin-left: auto;

    p {
        white-space: nowrap;
        font-weight: 400;

        strong {
            font-weight: 600;
            font-family: 'Plus Jakarta Sans', sans-serif;
        }
    }
}

// .result_header_sort {
//     display: flex;

//     P {
//         font-family: 'Plus Jakarta Sans', sans-serif;
//         font-size: 1.125em;
//         display: flex;
//         white-space: nowrap;
//         align-items: center;
//     }

//     .dropdown {
//         box-shadow: none;

//         .dropdown-toggle {
//             font-size: 1.125em;
//             font-weight: 600;
//         }

//     }
// }

.dropdown {
    display: flex;
    align-items: center;
}

#dropdownMenuLink {
    background-color: transparent;
    border: none;
    color: black;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 0;
    padding-left: 10px;
}

.dropdown-menu {
    padding: 0px;
    margin-left: -3.438em;
    margin-top: 0.5em;
    border: none;
    box-shadow: 0px 3px 6px var(--gray5);
}

.dropdown-menu li {
    display: flex;
    border-bottom: 1px solid var(--gray5);
    padding: 5px 10px;

    a {
        font-size: 0.8125em;
        color: #0B264C;
        font-weight: 600;
    }

    a:hover {
        color: black;
        background: none;
        cursor: pointer;
    }
}