.Wrapper {
  /* width: 100%; */
  /* display: grid;
  grid-template-columns: 220px 1fr;
  grid-gap: 1rem;
  
  page-break-before: always;
  page-break-after: always; */
  font-family: "trirong";
  padding: 2rem;
  display: flex !important;
  grid-template-columns: 180px 1fr;
  min-height: 700px;
  /* padding: 0.5rem; */
  /* width: 533px;
  margin-left: auto;
  margin-right: auto; */
}
.Wrapper ul li {
  list-style-position: inside;
  list-style-type: none;
  &:before {
    content: "• ";
  }
}
.Wrapper ol li {
  list-style-position: inside;
  list-style-type: none;
  counter-increment: my-counter;
  &:before {
    content: counter(my-counter) ". ";
  }
}
.rowFlex {
  display: flex;
  align-items: center;
}
.exp {
  margin-bottom: 10px !important;
}
.leftMenu > .subMenu {
  /* width: 100%; */
}

.value {
  font-size: 12px;
}
.valueCap {
  font-size: 12px;
  text-transform: capitalize;
}
.thirdSec {
  max-width: 12rem;
}
.subMenu > .secOne {
  margin-bottom: 2rem;
}
.secondSec {
  margin-bottom: 2rem;
}
.secondSec p{
  font-size: 12px;
}
.secondSec > .flex {
  margin-bottom: 0.5rem;
}
.description {
  opacity: 0.9;
  font-size: 12px;
  page-break-inside: avoid;
  break-inside: avoid-page;
}
.title {
  font-size: 1rem;
  max-width: 13rem;
  font-weight: 600;
  text-transform: capitalize;

}
.titleBy {
  font-size: 1rem;
  max-width: 13rem;
  font-weight: 600;
  text-transform: capitalize;
}
.loc {
  font-size: 13px;
  max-width: 13rem;
}
.download-icon {
  margin-top: 100px !important;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexRow p{
  font-size: 12px;
}
.flex {
  font-size: 14px;
  display: flex;
  gap: 5px;
  align-items: center;
}
.subMenu > .secOne > .name {
  font-size: 1.5rem;
  font-weight: 600;
}
.skillRow {
  margin-bottom: 0.5rem;
}
.summary {
  margin-bottom: 1rem;
}
.head {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 4px !important;
  margin-top: 8px !important;
}

.mainTitle {
  font-size: 1.2rem;
  font-weight: 600;
}

.employeeInfo {
  margin-bottom: 1rem;
}
.descInfo,
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 11rem;
  margin: 0.5rem 0rem;
}
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #303030;
  margin-right: 0.5rem;
}
.education > .title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: 1.2rem;
  font-weight: 600;
}
.break {
  margin-bottom: 1rem;
}
.rightMenu {
  margin-left: 1rem;
  overflow: hidden;
  word-wrap: break-word;
}
/* .employeeInfo > .flexRow{
  align-items: flex-start !important;
} */

.row > .flex {
  font-size: 12px;
}
.designation{
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
}
