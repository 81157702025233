.add_gig {
  padding-top: 48px;
  width: 100vw;
  font-size: 16px;

  .content {
    padding-top: 2rem;
    margin: auto;
  }

  .fixed-content {
    position: fixed;
    display: block;
    z-index: 4;
    width: 100%;
    background-color: white;

    .content {

      padding-top: 2.75em;
      margin: auto;
      padding-bottom: 2em;
      display: flex;
      align-items: center;
    }
  }

  .pr-1 {
    padding-right: 1.5em;
  }

  .main_t {
    font-weight: 600;
    font-size: 1.25em;
    margin-bottom: 0;
  }

  h2 {
    font-size: var(--p1);
    font-weight: 600;
    color: var(--black3);
  }

  hr {
    margin: 0.625em 0px;
    height: 2px;
    background-color: #00000029;
  }

  .list_profile_heading p {
    font-size: 0.875em;
    color: var(--gray8);
    font-weight: 500;
  }

  .input-lebel {
    font-size: 1.25em;
    font-weight: 600;
    color: #11263c;
  }

  .form-control {
    font-size: var(--p1);
    padding: 0.375em 0.75em;
    font-weight: 500;
    color: #11263c;
    box-shadow: none !important;
    outline: none;
    border-radius: var(--border-radius);
  }

  .input-group {
    border: 1px solid var(--gray5);
    border-radius: var(--border-radius);
    margin-bottom: 0.5em;
  }

  .input-group span {
    background-color: transparent;
    border: none;
    font-size: 1.15em;
    font-weight: 600;
    padding: 0.375em 1.25em;
  }

  // .input-group span img {
  //   width: 1.15em;
  // }

  .input-group input {
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    height: 3em;
  }

  .f_t_box {
    flex-wrap: wrap;
  }

  .f_t {
    display: flex;
    align-items: center;
    background-color: var(--light-blue);
    width: fit-content;
    padding: 3px 1.25em;
    border-radius: 20px;
    margin-right: 0.938em;
    margin-bottom: 0.5em;
  }

  .f_t p {
    color: var(--green);
    font-size: var(--p1);
    font-weight: 500;
    margin-right: 0.75em;
    margin-bottom: 0;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .f_t img {
    width: 10px;
  }

  .dot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--green);
    margin-right: 3px;
  }

  .del_jt {
    background: none;
    border: none;
  }



  .form-check-label {
    font-size: var(--p1);
    font-weight: 600;
    color: var(--gray6);
    white-space: nowrap;
  }

  .form-check {
    margin-bottom: 0.125rem;
    min-width: 33%;
    padding-left: 2.5em;
  }

  .form_buttons {
    display: flex;
    float: right;
  }

  .form_buttons button {
    background-color: #effffc;
    color: var(--green);
    border: 0px;
    outline: none;
    padding: 0.613em 1.25em;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 0.875em;
    border-radius: var(--border-radius);
    font-weight: 500;
    margin-right: 0.5em;
  }


  .form_buttons input {
    background-color: var(--green);
    color: var(--white);
    border: 0px;
    outline: none;
    padding: 0.613em 1.25em;
    font-family: 'Plus Jakarta Sans', sans-serif;
    border-radius: var(--border-radius);
    font-size: 0.875em;
    font-weight: 500;
  }

  .form-select {
    border-radius: var(--border-radius);
    border: 1px solid var(--gray5);
    font-size: var(--p1);
    font-weight: 500;
    color: #11263c;
    box-shadow: none;
    height: 3em;

  }

  // switch

  .switch {
    position: relative;
    display: inline-block;
    width: 3.438em;
    height: 1.563em;
    margin: auto 10px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: 0px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.188em;
    width: 1.188em;
    left: 0.25em;
    bottom: 0.188em;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: var(--green);
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(1.625em);
    -ms-transform: translateX(1.625em);
    transform: translateX(1.625em);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }


  .input-lebel-switch {
    font-size: 1.25em;
    font-weight: 600;
    color: #11263c;
    min-width: 45%;
  }

  hr {
    margin: 40px 0;
  }

  .con_1 {
    //margin-bottom: 1.25em;
  }

  textarea {
    width: 100%;
    font-size: 1.125em;
    padding: 0.375em 0.75em;
    font-weight: 500;
    color: #11263c;
    box-shadow: none !important;
    outline: none;
    border-radius: 0.625em;
    border: 0px;
  }

  .travel_required {
    display: flex;
    align-items: center;

    .input-lebel-switch {
      white-space: nowrap;
    }
  }

  // suggestion_box

  .suggestion_box {
    padding: 0;
    position: absolute;
    background: white;
    z-index: 2;
    max-width: 500px;
    width: fit-content;
    max-height: 150px;
    overflow-y: scroll;
    // margin-top: -0.75em;
    box-shadow: 0px 7px 8px -4px lightgrey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
      padding: 10px;
      border-bottom: solid 2px #F5F6F7;
    }
  }

  .suggestion_box::-webkit-scrollbar {
    display: none;
  }

  .travel_percentage_div {

    input {
      padding: 10px;
      border-radius: var(--border-radius);
      margin: 10px 10px;
      background-color: var(--white);
      border: 1px solid var(--gray5);
    }

  }

  // .form-check-input {
  //   height: 1.25em;
  //   width: 1.25em;
  // }

  .job-description {
    border: none !important;
  }

  .new_checkbox {
    padding: 5px 0 5px 0;
    display: grid;
  }

  .new_checkbox input[type="checkbox"] {
    /*margin-left: 10px;*/
    display: none;

  }

  .new_checkbox-yellow label:before {
    background-color: #ECECEE !important;
  }

  .new_checkbox label:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 0px;
    position: absolute;
    left: 0;
    bottom: 1px;
    background-color: #ECECEE;
    border-radius: 3px;
    /*box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .3), 0px 1px 0px 0px rgba(255, 255, 255, .8);*/
    box-shadow: inset 0px 2px 3px 0px #e8eaed5c, 0px 1px 0px 0px #e8eaedcc;
  }

  .new_checkbox label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    font-size: 1.125em;
    color: var(--gray8);
    font-weight: 500;
    margin-bottom: 10px;
  }

  .new_checkbox input[type="checkbox"]:checked+label:before {
    content: "\2713";
    text-shadow: 1px 1px 1px rgb(7, 7, 7);
    font-size: 18px;
    background-color: rgb(201, 200, 200) !important;
    text-align: center;
    line-height: 18px;
  }

  .job_search {
    border-right: 2px solid var(--gray5);
    flex: 0.4 1;
    max-width: 100%;
    border-top: 2px solid var(--gray5);
    border-bottom: 2px solid var(--gray5);
    border-left: 2px solid var(--gray5);
    border-radius: var(--border-radius);

    .search_box {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 1.25em;
        margin: 0 5px;
        flex: 0.06 !important;
      }

      input {
        flex: 0.9;
        border: none;
        outline: none;
        background-color: transparent;
        box-shadow: none;
        height: 3.125em;
        color: var(--gray4);
      }
    }
  }

  .location_search {
    border: 1px solid var(--gray5);
    border-radius: var(--border-radius);

    .search_box {
      display: flex;
      align-items: center;

      img {
        height: 1em;
        margin: 0 10px;
        flex: 0;
      }

      input {
        flex: 1;
        border: none;
        outline: none;
        background-color: transparent;
        box-shadow: none;
        height: 3.125em;
        color: var(--gray4);
      }
    }
  }


  @media only screen and (max-width: 800px) {

    .form_buttons {

      button,
      input {
        margin: 5px 5px;
      }
    }

    .content {
      .row {
        flex-direction: column;

        .div,
        .col-4,
        .col-6 {
          width: 100% !important;
        }

        .s_e {
          width: 100%;
        }


      }

      hr {
        visibility: hidden;
        margin: 20px 0;
      }

      .con_1 {
        margin-bottom: 40px;
      }

      .checkform {
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
      }
    }
  }



  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (max-width: 1600px) {}

  @media only screen and (max-width: 767px) {


    .fixed-content .content {
      flex-direction: column;
      width: 100%;
      padding: 20px 0;
    }

    .main_t {
      margin-bottom: 10px;
    }
  }

}

.ql-editor {
  height: 350px !important;
  max-height: 350px !important;
  overflow: auto;
}