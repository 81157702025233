 .skill_box {
    display: flex;
    margin: 20px 0;
    flex-direction: column;
    gap: 30px;

    .accordion-parent {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .delete-btn {
      button {
        border: none;
        background-color: #fff;
      }
    }

    .accordion-item {
      border: 1px solid var(--gray5) !important;
      flex: 1;
      border-radius: 8px;
    }

    .accordion-button {
      .experience {
        position: absolute;
        top: 40px;
      }
    }
    .accordion-item:first-of-type .accordion-button{
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
      transform: rotate(-180deg);
    }

    .accordion-collapse.collapse.show {
      border-radius: 8px;
      border: none;
    }

    .skill_box_det:has(.collapsed) {
      border: none;
      border-radius: 8px !important;
    }

    .skill_box_det {
      text-align: left;
      display: flex;
      align-items: center;
      padding: 0 0 20px 0;
      flex: 0.6 1;
      justify-content: space-between;
      border: none;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      // border-radius: 5px;
      text-align: left;
      background-color: #fff !important;

      button {
        color: #1e2532 !important;
        font-size: var(--p1) !important;
        font-weight: 600 !important;
        background-color: #fff !important;
        box-shadow: none !important;
      }

      button:focus {
        border-color: #fff !important;
        box-shadow: none !important;
      }

      .heading {
        color: #1e2532 !important;
        font-size: 1em !important;
        font-weight: 600 !important;
      }
    }
  }