.footer_fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.qr-code-image-box {
  padding: 0.8rem 1.1rem;
  border: 1px solid rgba(128, 128, 128, 0.241);
  width: fit-content;
  border-radius: 12px;
  position: fixed;
  bottom: 11px;
  left: 10px;
  background: white;
  z-index: 9999999;
  box-shadow: 0px 10px 36px 0px var(--green);
}

.qr-code-image-box small {
  text-wrap: wrap;
  font-size: 10px;
  font-weight: 700;
  word-break: break-all;
  text-align: center;
  margin-bottom: 0.1rem;
}

button.cross-btn {
  position: absolute;
  top: -8px;
  right: -18px;
  font-weight: 900;
  color: var(--red);
  font-size: var(--h7);
  background: transparent;
  border: none;
}

.qr-code-image {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_upper {
  font-size: 16px;
  margin-top: 30px;

  // margin-top: 100px;
  // padding-top: 1.75em;

  footer {
    background-color: var(--black4);
    color: var(--white);
  }

  .container__box {
    margin: auto;
    max-width: 1660px;
    padding: 0 20px;
  }

  .f_list_heading {
    margin-bottom: 20px;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .f_list {
    list-style: none;
    margin-left: 0;
    padding: 0;

    p {
      padding-bottom: 5px;
    }
  }

  .div_footer {
    // width: 90%;
    width: 100%;
    margin: auto;
    // padding: 50px 0;
    display: flex;
    // align-items: center;

    .div_f_c {
      display: flex;
      margin-left: auto;
      align-items: center;
      justify-content: flex-end;
    }

    .home-footer {
      margin-top: 25px;
      height: auto;

      .heading {
        color: #9398a1;
      }

      a {
        text-decoration: none;
        color: #fff;
      }
    }

    .list-group.footer-list {
      flex-direction: column;
      background: var(--black4);
      overflow-x: visible;
      height: auto;

      .list-group-item {
        background: var(--black4);
        color: #fff;
      }

      .list-group-item:hover {
        background: var(--black4);
        color: #fff;
        border: none;
      }

      .heading {
        color: #9398a1;
      }
    }
  }

  .div_footer h5 {
    font-size: 1.25em;
    font-weight: 500;
    color: var(--white);
  }

  li.nav-item.mb-2 {
    display: inline-block;
    margin-right: 50px;
  }

  // .div_footer {
  //   padding: 0px 10rem;
  // }

  .text-muted {
    font-size: 0.875em;
  }

  .div_footer .nav {
    column-gap: 36px;
  }

  .div_footer .nav li a {
    font-size: 0.875em;
    // margin: 0 20px;
    font-weight: 300;
    color: var(--white) !important;
    opacity: 0.8;
    padding: 10px 0 !important;
  }

  .footer_btm {
    border-top: 1px solid #173860;
    font-size: var(--p1);
  }

  .footer_btm p {
    color: white;
  }

  .icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    margin: 0px;

    li {
      // background-color: white;
      list-style: none;
      min-width: 10px !important;
      min-height: 20px !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;

      a {
        font-size: 0.875em;
        color: white;
        text-decoration: none;
        margin-top: auto;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .div_footer {
      flex-direction: column;

      div {
        width: 100%;
        margin: 13px 0px;
      }

      .div_f_c {
        flex-direction: column;
      }

      .icons {
        // flex-wrap: wrap;
        margin: 20px 0;
        justify-content: flex-start;

        li {
          min-width: 30px !important;
          min-height: 30px !important;
          margin: 0;
          margin-right: 10px;
        }
      }

      .nav {
        justify-content: center;

        li {
          a {
            margin: 0 10px;
          }
        }
      }
    }

    .footer_btm {
      p {
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (min-width: 2100px) {
    .div_footer {
      padding: 0px 28em;
    }
  }

  @media only screen and (max-width: 576px) {
    .div_footer {
      // padding: 0px 2em;
    }
  }
}
