.work-profile {

  .switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 25px;
    margin: 10px 0;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .main_t{
    font-size: var(--h3);
    display: flex;
    align-items: center;
    gap: 1rem;
    h2{
      margin-bottom: 0 !important;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: 0px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: var(--green);
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }


  .emp_box {
    display: flex;
    margin: 20px 0px;

    button {
      background: none;
      outline: none;
      border: none;
      height: 100%;
      margin: auto 5px;
    }
  }

  .emp_box div {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 3px 10px;
  }

  .emp_box_det {
    flex: 1;
    justify-content: space-between;
    border: 1px solid var(--gray5);
    border-radius: var(--border-radius);
    text-align: left;
  }

  .emp_box:hover .emp_box_det {
    border: 1px solid var(--green);
  }

  .emp_box p {
    font-size: var(--p1);
    font-weight: 500;
    color: var(--gray4);
  }

  .emp_box .green_text {
    color: var(--black3);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: var(--p1);
    font-weight: 600;
  }

  .emp_box.active .green_text {
    color: var(--green);
  }

  .emp_box_det div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .trash {
    width: 15px;
  }

  .emp_box img {
    margin-right: 3px;
  }

  .skills_add {
    display: flex;
    width: 62%;
    flex-wrap: wrap;
  }

  .skills_btn {
    display: flex;
    background-color: var(--gray2);
    border-radius: 20px;
    padding: 2px 15px;
    width: fit-content;
    margin: 5px 15px 5px 0px;

    button {
      border: none;
      background: none;
      // transform: rotate(-45deg);
    }
  }

  .skills_btn img {
    width: 12px;
  }

  .skills_btn p {
    margin-right: 10px;
    font-size: var(--p1);
    font-weight: 500;
    color: var(--black3);
  }

  .add-skills {
    h5 {
      font-size: var(--p2);
    }
  }



  .pdf {
    height: 31.25em;
    width: 25em;
    // border: 1px solid var(--gray5);
  }

  .preview {
    display: flex;
    background-color: var(--gray2);
    padding: 5px 15px;
    width: fit-content;
    margin: 20px auto;
    border-radius: var(--border-radius);
  }

  .preview img {
    width: 20px;
    margin: auto 10px;
  }

  .preview a {
    font-size: var(--h3);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    text-decoration: none;
    color: #000;
  }

  .preview p {
    font-size: var(--h3);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    margin-left: 10px;
  }

  .modal_top {
    display: flex;
  }

  img.cvimg {
    width: 400px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }


  .css-2b097c-container {
    width: 100%;
  }

  .suggestion_box {
    padding: 0;

    background: white;
    z-index: 200;
    padding: 0px;
    max-height: 150px;
    overflow-y: scroll;
    box-shadow: 0px 7px 8px -4px lightgrey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
      padding: 10px;
      border-bottom: solid 2px #f5f6f7;
    }
  }

  .suggestion_box::-webkit-scrollbar {
    display: none;
  }

}

.PhoneInputCountry {
  margin-left: 10px;
}

