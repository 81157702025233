.btn-primary:hover {
  background-color: var(--green) !important;
  color: white !important;
}

.boost-job {

  label {
    font-size: var(--p1);
    color: #263238;
    font-weight: 600;
  }
  input.form-control {
    font-size: var(--p1);
    font-weight: 500;
    color: var(--text-color);
    box-shadow: none !important;
    outline: none;
    border-radius: var(--border-radius);
    border: 1px solid var(--gray5);
    height: 3em;
  }

  select.form-select.form-select-lg {
      border-radius: var(--border-radius);
      border: 1px solid var(--gray5);
      font-size: var(--p1);
      font-weight: 500;
      color: #11263c;
      box-shadow: none;
      height: 3em;
    }

  .cost-duration {
    font-size: var(--p1);
    margin-top: 32px;
  }

  .info {
    color: #9398a1;
  }


  .cash-summary {
    height: 100px;
    padding: 20px 10px 20px 10px;

    h6 {
      font-size: var(--p1);
      font-weight: 700;

      a {
        color: var(--green) !important;
        margin-left: 10px;
      }
    }
  }

  .left {
    span {
      color: #2c2c2c;
      font-size: var(--p1);
      font-weight: 800;
    }

    p {
      text-align: justify;
    }
  }
}