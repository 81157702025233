.company_users {
  // min-height: 100vh;
  // max-height: 100vh;
  // width: 100vw;
  // overflow-y: hidden;
  // padding-top: 6.0em;
  font-size: 16px;

  .number-div {
    width: 50px !important;
    text-align: center;
  }

  .block-account {
    color: #D92D20;
    text-decoration: none;
    background-color: #efcfcf;
    border: 0px;
    outline: none;
    padding: 0.813em 1.25em;
    font-family: "Plus Jakarta Sans", sans-serif;
    border-radius: var(--border-radius);
    margin-left: 1.25em;
    font-size: var(--p1);
    font-weight: 500;
    height: 3em;
  }

  .body_section {
    background-color: var(--white);
    width: 100%;
    display: flex;
    flex-direction: column;
    // margin-left: 68px;
  }


  /* Search bar section */

  .search {
    display: flex;
    // padding-top: 3.238em;    
    // padding: 0px 10rem;

    button {
      margin-right: auto;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .edit {
      width: 1.5em;
      margin: auto 10px;
    }
  }

  .cash {
    max-width: 390px !important;
    display: flex;
    // align-items: center;
    // justify-content: center;
    background-color: #F8F8F8;
    padding: 10px 10px;
    border-radius: var(--border-radius);
    align-items: center;


    div {

      p {
        font-size: var(--h5);
        font-weight: 600;
        margin-right: 16px;

        .pill {
          background-color: var(--green);
          color: white;
          padding: 0.375em 1.25em;
          border-radius: 5px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    a.buy-cash {
      text-decoration: none;
      // color: var(--green);
      color: var(--gray4);
      font-weight: 600;
    }
  }

  .search h2 {
    font-size: 1.25em;
    color: var(--black3);
    font-weight: 600;
    margin: auto 30px auto 0;
  }

  .search_section {
    width: 90%;
    height: 3.75em;
    background-color: var(--white);
    margin: auto;
    display: flex;
  }

  .search_section {
    display: flex;
    border-radius: var(--border-radius);
    border: 2px solid var(--gray5);
    margin: 20px auto 15px auto;

    .add_pills {
      display: flex;
      max-width: 50%;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      span {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 0.3 1;
        border: 1px solid var(--gray5);
        padding: 5px;

        /* margin: auto 10px; */
        margin-right: 13px;
        border-radius: var(--border-radius);

        p {
          font-weight: 500;
          color: var(--green);
          font-size: var(--p1);
        }

        button {
          border: none;
          background: none;

          img {
            margin-right: 0px;
            width: 0.625em;
          }
        }
      }
    }

    .add_pills::-webkit-scrollbar {
      display: none;
    }
  }

  .job_search {
    border-right: 2px solid var(--gray5);
    flex: 0.4;
    max-width: 40%;

    .search_box {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 1.25em;
        margin: 0 10px;
        flex: 0.1;
      }

      input {
        outline: none;
        height: 100%;
        flex: 0.9;
        border: 0px;
        border-radius: 20px;
        font-size: 1.125em;
        font-weight: 500;
        color: var(--gray4);
      }
    }
  }



  .no_jobs {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 3.125em;
    }

    h2 {
      font-size: 1.5em;
      font-weight: 600;
      color: #263238;
      margin: 1.25em;
    }
  }



  /*  Result section */

  .result_section {
    width: 100%;
    background-color: var(--white);
    // margin: 1.875em auto 0px auto;
    // padding: 0px 10rem;
    display: flex;

    .main-div {
      width: 100%;
    }

    // table {
    //   height: 100%;
    // }
  }

  .feature-job {
    .section_1 {
      display: flex;
      justify-content: center;

      img {
        width: auto;
        height: 64px;
      }

      h5 {
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
        color: #000000;

      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #6E7176;

      }
    }

    .section_2 {
      padding: 15px;
      // width: 70%;
      margin: auto;
      border-radius: 16px;
      border: 1px solid #EAECF0;
      box-shadow: 0 1px 10px rgba(49, 65, 88, .1215686275);

      .buy-btn {
        width: 8.75em;
        height: 3em;
        border-radius: 8px;
        border: 1px solid #14BC9A;
        background: #14BC9A;
        padding: 0.75em 1.25em;
        color: #fff;
        font-size: var(--p1);
        font-weight: 600;
      }
    }

    .section_3 {
      padding: 15px;
      margin: auto;
      border-radius: 16px;
      border: 1px solid #EAECF0;
      box-shadow: 0 1px 10px rgba(49, 65, 88, .1215686275);
    }
  }

  .buy-more-btn {
    height: 3em;
    border-radius: 8px;
    border: 1px solid #14BC9A;
    background: #14BC9A;
    padding: 0.75em 1.25em;
    color: #fff;
    font-size: var(--p1);
    font-weight: 600;
  }

  .item-info {
    font-size: "1em";
    font-weight: "400";
    color: "#475467";
    margin: "auto";
    margin-left: 5px;
  }




  footer {
    position: fixed;
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }


  @media only screen and (max-width: 576px) {
    .result_section {
      padding: 0px 0.5em;
    }

    .search {
      padding: 0px 2em !important;
    }
  }

  @media only screen and (max-width: 800px) {
    font-size: 12px !important;

    .location_search {
      visibility: hidden;
      position: absolute;
    }

    .job_type_search {
      visibility: hidden;
      position: absolute;
    }

    .job_search {
      flex: 0.7;
      max-width: 70%;
    }

    .search_button {
      flex: 0.3;
    }

    .suggestion_box {
      width: 90% !important;
    }

    .buy-cash {
      margin-left: auto;
    }

    .cash {
      width: 90%;

      padding: 15px 10px;

      .pill {
        margin-top: 20px;
      }
    }


  }


  .suggestion_box {
    padding: 0;
    position: absolute;
    background: white;
    z-index: 200;
    width: 32.5em;
    max-height: 150px;
    overflow-y: scroll;
    margin-top: 0.75em;
    box-shadow: 0px 7px 8px -4px lightgrey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
      padding: 10px;
      border-bottom: solid 2px #F5F6F7;
    }
  }

  .suggestion_box::-webkit-scrollbar {
    display: none;
  }


  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: 0px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.188em;
    width: 1.188em;
    left: 0.25em;
    bottom: 0.188em;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: var(--green);
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(1.625em);
    -ms-transform: translateX(1.625em);
    transform: translateX(1.625em);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }


  .input-lebel-switch {
    margin-right: 10px;
    text-wrap: nowrap;
  }

  .input-lebel-switch.disabled,
  .switch.disabled {
    opacity: 50%;
  }


  .footer_upper {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

}


.company-fix-table {
  // height: 45vh;
  // overflow-y: auto;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .table {
    thead th {
      position: sticky;
      top: 0;
      z-index: 300;
      background: white;
    }
  }
}

.date-filters {

  .col-4 {
    width: 33.33333333% !important;
  }

  .input-group {
    border: 1px solid var(--gray5);
    border-radius: var(--border-radius);
    width: 90%;
    background-color: white;
  }

  span#basic-addon1 {
    background-color: transparent;
    border: none;
    font-size: 1.25em;
    font-weight: 600;
  }

  .react-datepicker-wrapper {
    flex: 1 1;
    height: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
  }

  input[type="text"] {
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    height: 3.125em;
    font-size: 0.9em;
    color: #11263c;
    font-weight: 600;
    width: inherit;
  }
}

span.css-11nabsz {
  float: right !important;
}


.graph-box {
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  padding: 20px 20px;
  margin: 0 5px;
}

.download-div {
  margin-top: 9%;
  margin-left: 16%;
  position: absolute;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .download-div {
    margin-top: 15%;
    margin-left: 26%;
  }

  .graph-box .col-md-6 {
    margin: 0 !important;
  }
}

// @media screen and (max-width: 767px) {
//   .download-div {
//     margin-top: 15%;
//     margin-left: 26%;
//   }
// }


.download-p {
  font-size: 2em;
  font-weight: 500;
  color: #2E2C34;
}

.download-span {
  color: #2E2C34;
  font-size: 1.18em;
}

.react-datepicker-popper {
  z-index: 10000;
}

.result_section {
  span.react-datepicker__month-read-view--down-arrow {
    top: 2px !important;
    border-color: #ccc !important;
    border-style: solid !important;
    border-width: 3px 3px 0 0 !important;
  }

  .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--scroll {
    left: 20px;
    position: relative;
  }

  span.react-datepicker__year-read-view--down-arrow {
    top: 2px !important;
    border-color: #ccc !important;
    border-style: solid !important;
    border-width: 3px 3px 0 0 !important;
  }

  .react-datepicker__year-dropdown.react-datepicker__year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
    width: 180%;
    top: 10px;
  }
}