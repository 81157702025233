.my-account__wrapper {
  padding-top: 84px;

  /*--------- Accout Info Section -- Style ---------*/
  .my-account__info {
    &_left {
      display: flex;
      align-items: center;
    }

    h2 {
      font-size: 1.25em;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1rem;
    }

    &_left h3 {
      font-size: var(--p1);
      font-weight: 600;
      color: #11263c;
    }

    &_left p {
      font-size: 0.875em;
      font-weight: 500;
      color: var(--gray8);
    }

    .round_profile_body {
      border-radius: 50%;
      overflow: hidden;
      height: 6.25em;
      width: 6.25em;
      margin-right: 20px;
      border: 1px solid #fafafa;

      & img {
        width: inherit;
        object-fit: cover;
        height: -webkit-fill-available;
      }
    }
  }

  #add_img {
    margin-top: -3.5em;
    background: none;
    border: none;
    position: absolute;
    margin-left: 4.5em;

    input {
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      left: 0;
    }

    img {
      width: 2.25rem;
    }
  }

  /*--------- Accout Detail Section -- Style ---------*/
  .my-account__detail {
    padding-top: 2em;

    .tab-content {
      .form-input-group .input-group-text {
        background: transparent;
        border: none;
        height: 100%;
      }
    }
  }

  .list-group {
    display: flex;
    flex-direction: row;
    column-gap: 32px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      border-radius: 0px !important;
      color: var(--gray8);
      font-size: var(--p1);
      font-weight: 300;
      border: none;
      width: auto;
      margin-right: 0;
      padding: 0.5em 0px;

      &.active {
        background-color: white;
        font-weight: 600;
        color: var(--black3);
        border-bottom: 2px solid var(--green);
      }

      &:hover {
        background-color: white;
        border-bottom: 2px solid var(--green);
      }
    }
  }

  /* tab pane 2 */
  .preferences__tab-pane {
    .suggestion_box {
      padding: 0px 50px;
      width: 100%;
      margin: -3px;
    }
  }

  .preferences__tab-pane form {
    // width: 75%;
    margin-bottom: 3.75em;
  }

  .preferences__tab-pane h2 {
    font-size: var(--p1);
    font-weight: 600;
    color: var(--black3);
  }

  .min_sal_inp {
    width: 70%;
  }

  .preferences__tab-pane hr {
    margin: 1.25em 0px;
    height: 3px;
    background-color: #dbdbdb;
  }

  .list_profile_heading p {
    font-size: 0.875em;
    color: var(--gray8);
    font-weight: 500;
  }

  .preferences__tab-pane .input-group {
    border: 1px solid var(--gray5);
    border-radius: var(--border-radius);
    margin-bottom: 0.5em;
  }

  .preferences__tab-pane .input-group span {
    background-color: transparent;
    border: none;
    font-size: 1.15em;
    font-weight: 600;
  }

  .preferences__tab-pane .input-group input {
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
  }

  .f_t_box {
    flex-wrap: wrap;
    max-width: 100% !important;
    margin-top: 0.5rem;
  }

  .f_t {
    display: flex;
    align-items: center;
    background-color: var(--light-blue);
    width: fit-content;
    padding: 3px 1.25em;
    border-radius: 20px;
    margin-right: 0.938em;
    margin-bottom: 0.5em;
  }

  .f_t p {
    color: var(--green);
    font-size: var(--p1);
    font-weight: 500;
    margin-right: 0.75em;
    margin-bottom: 0;
  }

  .f_t img {
    width: 10px;
  }

  .dot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--green);
    margin-right: 3px;
  }

  .form-check-label {
    font-size: 0.875em;
    color: var(--gray4);
    font-weight: 300;
  }

  /* tab pane 3 */

  .work-profile__tab-pane .input-group {
    border: 1px solid var(--gray5);
    border-radius: var(--border-radius);
    margin: 1.25em 0px;
  }

  .work-profile__tab-pane .input-group span {
    background-color: transparent;
    border: none;
    font-size: 1.25em;
    font-weight: 600;
    padding: 0.375em 1.25em;
  }

  .work-profile__tab-pane .input-group span img {
    width: 1.15em;
  }

  .work-profile__tab-pane .input-group input {
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    height: 2.813em;
  }

  .work-profile__tab-pane h2 {
    font-size: 1.25em;
    font-weight: 600;
    color: var(--black3);
    margin-bottom: 0px;
  }

  .work-profile__tab-pane p {
    font-size: var(--p1);
    font-weight: 600;
    color: var(--gray8);
    margin-top: 1em;
  }

  .suggestion_box {
    padding: 0;
    position: absolute;
    background: white;
    z-index: 2;
    padding: 0px 60px;
    width: 50%;
    max-height: 150px;
    overflow-y: scroll;
    // margin-top: -0.75em;
    box-shadow: 0px 7px 8px -4px lightgrey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .suggestion_box::-webkit-scrollbar {
    display: none;
  }

  .addComp {
    background: none;
    outline: none;
    border: none;
    color: var(--green);
  }

  .suggestion_box p {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: var(--gray8);
    padding: 5px 0px;
  }

  table {
    margin: 2.5em 0px;
  }

  tbody {
    border-top: none !important;
  }

  tbody tr:nth-child(1) {
    background-color: var(--gray2);
    border-left: 3px solid var(--green);
  }

  .table> :not(caption)>*>* {
    border: 0px;
    padding: 0.8rem 1.5rem;
  }

  td.bold {
    font-weight: 600;
  }

  td.red {
    display: flex;
    align-items: center;
  }

  td.red button {
    color: #fd815e;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 1.125em;
    font-weight: 500;
    border: none;
    background: none;
    outline: none;
    width: fit-content;
  }

  td.red img {
    width: 1em;
    margin-right: 0.625em;
  }

  td {
    font-size: 1.125em;
    font-weight: 500;
    color: var(--black5);
  }

  th {
    font-size: 1.125em;
    font-weight: 500;
    color: var(--gray8);
  }

  tr.active {
    background-color: var(--gray2);
    border-left: 3px solid var(--green);
  }

  /* tabpane 4 */

  .change-pass__tab-pane form {
    padding-bottom: 3.75em;

    // .form-control{
    //   margin-bottom: 2em;
    // }

    .form_buttons {
      margin-top: 0.25em !important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;

    .body_content {
      max-width: 100%;
    }

    .profile__tab-pane form {
      width: 100%;
      display: flex;
      flex-direction: column;

      .row {
        // flex-direction: column;
        margin-top: 0px;

        .col {
          margin-bottom: 1em;
        }
      }
    }

    .preferences__tab-pane {
      .suggestion_box {
        padding: 0px 50px;
        width: 75%;
      }

      form {
        width: 100%;
        margin-bottom: 3.75em;

        .row {
          .col {
            margin-bottom: 1.25em;
          }
        }

        .min_sal_inp {
          width: 100%;
        }
      }
    }

    .work-profile__tab-pane {
      .col-8 {
        width: 100%;
      }
    }

    .change-pass__tab-pane {
      .col-4 {
        width: 100%;
      }
    }

    .suggestion_box {
      width: 75%;
    }

    td.red button {
      display: flex;
      align-items: center;

      img {
        width: 0.8em;
      }
    }
  }

  @media only screen and (max-width: 320px) {
    .delete-account {
      padding: 0.1em 1em !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (max-width: 576px) {
    .body_content {
      padding: 0px 2em !important;
    }
  }

  .suggestion_box {
    padding: 0;
    position: absolute;
    background: white;
    z-index: 2;
    padding: 0px !important;
    // width: 25%;
    max-height: 150px;
    overflow-y: scroll;
    margin-top: -0.75em;
    box-shadow: 0px 7px 8px -4px lightgrey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
      padding: 10px;
      border-bottom: solid 2px #f5f6f7;
    }
  }

  .suggestion_box::-webkit-scrollbar {
    display: none;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 3.438em;
    height: 1.563em;
    margin: 10px 0;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: 0px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.188em;
    width: 1.188em;
    left: 0.25em;
    bottom: 0.188em;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: var(--green);
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(1.625em);
    -ms-transform: translateX(1.625em);
    transform: translateX(1.625em);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.account-form {
  &__title {
    font-size: 1rem;
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #000000;
  }

  &__label.label-lg {
    font-size: 1rem;
    margin-inline-end: 0.75rem;
    font-weight: 500;
  }
}

.info-box {
  &--grey-100 {
    background-color: #edf2f7;
  }

  border-radius: 10px;
  padding: 20px;

  ul {
    padding-left: 20px;
  }

  &__title {
    color: #263238;
    font-weight: 700;
    font-size: var(--p);
  }

  &__details {
    color: #718096;
    font-size: 0.75rem;
    margin-top: 1rem;
    text-align: justify;
  }
}

.button-group {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  column-gap: 1.25rem;
  row-gap: 0.75rem;
}

.del_jt {
  width: 10px;
  background: none;
  border: none;
}

.tab-content {
  margin-top: 2.188em;
}

.tab-content .tab-pane {
  min-height: 70vh;
  margin-top: 1.5rem;
}

.tab-content .tab-content .tab-pane {
  margin-top: 0 !important;
}

.gender-dropdown {
  height: 3em;
  font-size: var(--p1);
}

.form-check {
  margin-bottom: 0.125rem;
  min-width: 33%;
  padding-left: 2.5em;
}

// .form-check-input {
//   height: 1.25em;
//   width: 1.25em;
// }

.form-check-label {
  font-size: var(--p1);
  font-weight: 400;
  color: var(--black3);
  white-space: nowrap;
  margin-left: 5px;
}