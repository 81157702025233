.user_profile {
    height: 100%;
    // height: 100vh;
    // width: 100vw;
    padding-top: 55px;
    // padding-top: 6.25em;

    font-size: 16px;

    @media print {
        @page {
            size: landscape;
        }


        .body_content {
            padding-top: 0px !important;
            width: 92% !important;
        }

        h2 {
            font-size: 18px !important;
        }

        p {
            font-size: 16px;
        }

        .body_content .left {

            .details {
                ul {
                    li {
                        img {
                            width: 16px !important;
                        }
                    }
                }
            }

            .profile_img_div .profile_img {
                width: 100px !important;
                height: 100px !important;
            }

        }


        .w_sec {
            margin-top: 60px !important;
        }

    }

    .body_content {
        display: flex;
        width: 85%;
        margin: auto;
        padding-top: 2.25em;
        padding-bottom: 50px;

        hr {
            background-color: #dbdbdb;
            height: 0.25em;
        }

        .left {
            // flex: 0.75;

            .profile_img_div {
                display: flex;
                align-items: center;
                margin-bottom: 1.25em;

                .profile_img {
                    min-width: 8.625em;
                    height: 8.625em;
                    border-radius: 50%;
                    margin-right: 20px;
                    background-color: var(--gray2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img {
                        width: inherit;
                    }
                }

                .profile_txt {
                    h2 {
                        font-size: 2em;
                        color: #263238;
                        font-weight: 700
                    }

                    p {
                        font-size: 1.25em;
                        color: #263238;
                        margin-bottom: 0.625em;
                    }
                }
            }

            .details {
                // margin-top: 2.25em;

                p {
                    font-size: 1.125em;
                    color: #2C2C2C;
                    font-weight: 500;
                    width: 90%;
                }

                ul {
                    list-style: none;
                    padding: 0px;
                    margin: 40px 0;

                    li {
                        display: flex;
                        margin: 1.25em 0;

                        img {
                            width: 1.5em;
                            margin-right: 1.25em;
                        }

                        p {
                            font-size: 1.188em;
                            color: #263238;
                            font-weight: 600;
                        }
                    }
                }
            }

            .work_details {

                .w_sec {
                    margin-top: 6.25em;

                    h2 {
                        font-size: 1.75em;
                        color: #263238;
                        font-weight: 600;
                    }

                    .w_det {
                        margin-top: 2.5em;

                        h2 {
                            font-size: 1.5em;
                            font-weight: 600;
                        }

                        p {
                            margin-top: 1.25em;
                            font-size: 1.125em;
                            color: #2C2C2C;
                        }
                    }
                }


            }
        }

        .right {
            // flex: 0.25;

            .skills {

                padding: 2.5em 0.625em;

                h2 {
                    font-size: 1.5em;
                    font-weight: 600;
                    color: #263238;
                }

                .skill_det {

                    .progress-div {

                        margin-top: 1.25em;

                        .progress-name {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .skill-name {
                                display: flex;
                                margin-bottom: 5px;
                                gap: 5px;
                            }

                            p {
                                font-size: 1.25em;
                                font-weight: 500;
                                color: #263238;
                            }

                            span {
                                color: #000000;
                                opacity: 0.4;
                                font-size: 1.125em;
                            }
                        }

                        .progress {
                            height: 0.625em;
                        }
                    }

                }
            }

            .evaluator {
                padding: 2.5em 0.625em;

                h2 {
                    font-size: 1.5em;
                    font-weight: 600;
                    color: #263238;
                }

                p {
                    font-size: 1.25em;
                    color: #2C2C2C;
                }
            }
        }
    }

    .unlockEvaluation {
        z-index: 1;
        // margin-top: 10%;
        font-size: 2rem;
        // position: absolute;
        color: #6c757d !important;
    }

    .view-resume {
        margin-left: auto;
        display: flex;
        align-items: flex-end;
        padding-right: 30px;

        a {
            margin-left: 10px;
        }
    }

    .print-resume {
        margin-right: 10px;
    }


    @media only screen and (max-width: 1280px) {
        font-size: 12px !important;
    }

    @media only screen and (max-width: 1024px) {
        font-size: 11px !important;

        .view-resume {
            padding: 0;
            flex-direction: column;

            .print-resume {
                margin-right: 0px;
                margin-bottom: 5px;
            }
        }
    }

    @media only screen and (max-width: 1600px) {
        font-size: 13px;
    }

}