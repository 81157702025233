.add_edit_company {
    // height: 100vh;
    // width: 100vw;
    // overflow-y: hidden;
    // padding-top: 55px;
    font-size: 16px;

    .body_content {
        display: flex;
        // padding-left: 54px;
        // padding-right: 65px;
        // padding: 0px 10rem;
    }

    .left {
        // flex: 0.7;

        // height: 90vh;
        // overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        // margin-top: 10em;
    }

    // .left::-webkit-scrollbar {
    //     display: none;
    // }



    /* left content */

    .left_content_2,
    .left_content_3 {
        margin: 40px auto;
        width: 100%;
    }

    .domains {
        .f_t_box {
            flex-wrap: wrap;
        }

        .f_t {
            display: flex;
            align-items: center;
            background-color: var(--light-blue);
            width: -moz-fit-content;
            width: fit-content;
            padding: 3px 1.25em;
            border-radius: 20px;
            margin-right: 0.938em;
            margin-bottom: 0.5em;

            p {
                color: var(--green);
                font-size: var(--p1);
                font-weight: 500;
                margin-right: 0.75em;
            }
        }

        .del_jt {
            background: none;
            border: none;
        }

    }

    /* left content 1 */

    .left_content_1 h2 {
        font-size: 1.25em;
        font-weight: 600;
        color: var(--black3);
        margin-bottom: 30px;
        margin-top: 8px;
    }

    .left_content_1 p {
        font-size: var(--p1);
        font-weight: 400;
        color: var(--gray8);
    }

    .red {
        font-size: var(--p1);
        font-weight: 600;
        color: #fd815e;
    }

    /* left content 2 */
    .uploadCv {
        width: 100%;
        display: inline-block !important;
        position: relative;
    }

    .setDeleteClass {
        position: absolute;
        top: 0.6rem;
        right: 0.6rem;
        background-color: #efefef;
        border-radius: 50%;
        padding: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .upload_cv {
        display: flex;
        justify-content: center;
        padding: 5em;
        border: 3px dotted #DBDBDB;
        border-radius: 1.25em;
        margin-bottom: 2.5em;
        // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23DBDBDBFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 0.625em;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        img.cover-pic-preview {
            width: 100% !important;
            margin-right: 0px !important;
            margin-left: 9em;
        }

        img {
            width: 1.25em;
            margin-right: 0.625em;
        }
    }

    .upload_cv {
        div {
            margin: 0 1.25em;
        }
    }



    .utxt {
        width: 90%;
    }



    .upload_resume {
        position: relative;
        margin: 0 0.5em;

        input {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        a {
            font-size: 1.125em;
            font-family: 'Plus Jakarta Sans', sans-serif;
            font-weight: 600;
            color: var(--green);
            text-decoration: none;
        }
    }

    .left_content_2 h2 {
        font-size: 1.25em;
        font-weight: 600;
        color: var(--black3);
        margin-bottom: 25px;
    }

    .left_content_2 div {
        align-items: center;
        display: flex;
        color: var(--green);
        white-space: nowrap;
    }

    .left_content_2 div p {
        font-size: 1.125em;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-weight: 600;
        color: var(--green);
    }

    .profile_img {
        width: 5.625em;
        height: 5.625em;
        border-radius: 50%;
        margin-right: 20px;
        background-color: var(--gray2);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .profile_img img {
        width: inherit;

    }

    /* content 3 */

    .left_content_3 form {
        padding-bottom: 20px;
    }

    .left_content_3 h2 {
        font-size: 1.25em;
        margin-bottom: 10px;
        margin-top: 20px;
        font-weight: 600;
        color: var(--black3);
    }

    .left_content_3 p {
        font-size: 0.875em;
        margin: 5px 0;
        font-weight: 400;
        color: var(--gray4);
    }

    .left_content_3 label {
        font-size: 0.875em;
        font-weight: 400;
        color: var(--gray4);
    }

    .left_content_3 .input-group {
        border: 1px solid var(--gray5);
        border-radius: var(--border-radius);
    }

    .input-group.mb-3 {
        margin-bottom: 1.5em !important;
    }

    .left_content_3 .input-group span {
        background-color: transparent;
        border: none;
        height: 100%;
    }

    .left_content_3 .input-group span img {
        width: 15px;
    }

    .left_content_3 .input-group input {
        border: none;
        outline: none;
        background-color: transparent;
        box-shadow: none;
        height: 3em;
        font-size: var(--p1);
        color: #11263c;
        font-weight: 500;
        width: inherit;
    }

    a.pref-loc {
        color: var(--green) !important;
    }

    textarea {
        border: 1px solid #DBDBDB;
        box-shadow: none;
        font-size: 1.125em;
        font-weight: 500;
        color: #11263c;
        width: 100%;
        border-radius: 0.625em;
        padding: 0.625em 1.25em;
    }

    .emp_box {

        button {
            background: none;
            outline: none;
            border: none;
            height: 100%;
            margin: auto 5px;
        }
    }

    .emp_box div {
        text-align: left;
        display: flex;
        align-items: center;
        padding: 3px 10px;
    }

    .emp_box_det {
        // flex: 0.;
        justify-content: space-between;
        border: 1px solid var(--gray5);
        border-radius: var(--border-radius);
        text-align: left;
    }

    .emp_box:hover .emp_box_det {
        border: 1px solid var(--green);
    }

    .emp_box p {
        font-size: 0.875em;
        font-weight: 500;
        color: var(--gray4);
    }

    .emp_box .green_text {
        color: var(--black3);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: var(--p1);
        font-weight: 600;
    }

    .emp_box.active .green_text {
        color: var(--green);
    }

    .emp_box_det div {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .trash {
        width: 15px;
    }

    .emp_box img {
        margin-right: 3px;
    }

    .skills_add {
        display: flex;
        width: 62%;
        flex-wrap: wrap;
    }

    .skills_btn {
        display: flex;
        background-color: var(--gray2);
        border-radius: 20px;
        padding: 2px 15px;
        width: fit-content;
        margin: 5px 15px 5px 0px;

        button {
            border: none;
            background: none;
            // transform: rotate(-45deg);
        }
    }

    .skills_btn img {
        width: 12px;
    }

    .skills_btn p {
        margin-right: 10px;
        font-size: var(--p1);
        font-weight: 500;
        color: var(--black3);
    }

    /* right */

    .right {
        padding: 20px;
    }

    .pdf {
        height: 31.25em;
        width: 25em;
        // border: 1px solid var(--gray5);
    }

    .preview {
        display: flex;
        background-color: var(--gray2);
        padding: 5px 15px;
        width: fit-content;
        margin: 20px auto;
        border-radius: var(--border-radius);
    }

    .preview img {
        width: 20px;
        margin: auto 10px;
    }

    .preview a {
        font-size: 0.875em;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-weight: 500;
        text-decoration: none;
        color: #000;
    }

    .preview p {
        font-size: 1.375em;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-weight: 500;
        margin-left: 10px;
    }

    .modal_top {
        display: flex;
    }

    img.cvimg {
        width: 400px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .photos {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-gap: 10px;

        .photo {
            width: 100%;
            // max-width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 2px;
            height: 200px;
            border: dashed 2px #B6B4BA;

            &.active {
                border: solid 2px #B6B4BA;
            }

            i {
                font-size: 2em;
                color: var(--gray6);
            }

            a {
                color: var(--green);
                font-size: 1.25em;
                font-weight: 600;
            }
        }
    }

    @media only screen and (max-width: 800px) {

        overflow-y: auto;

        img.cvimg {
            width: 100%;
        }

        .body_content {
            flex-direction: column;
        }

        .upload_cv {
            border: 2px dotted #dbdbdb;
        }

        // textarea {
        //     width: inherit;
        // }

        .emp_box_det {
            flex: 1;
        }

        .pdf {
            width: 100%;
        }

        .left_content_3 form {
            .row {
                flex-direction: column;
            }

            .col-6 {
                width: 100%;
            }
        }
    }

    .css-2b097c-container {
        width: 100%;
    }

    @media only screen and (max-width: 1280px) {
        font-size: 12px !important;
    }

    @media only screen and (max-width: 1024px) {
        font-size: 11px !important;

        .left {
            margin-top: 12em;
        }
    }

    @media only screen and (max-width: 1600px) {
        font-size: 13px;

        .body_content {
            // padding-left: 80px;
            // padding-right: 65px;
        }
    }

    .suggestion_box {
        padding: 0;
        background: white;
        z-index: 200;
        padding: 0px;
        max-height: 150px;
        overflow-y: auto;
        box-shadow: 0px 7px 8px -4px lightgrey;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        p {
            padding: 10px;
            border-bottom: solid 2px #F5F6F7;
        }
    }

    .suggestion_box::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (min-width: 768px) and (max-width: 1199px) {
        .left_content_1 {
            h2 {
                margin-bottom: 10px;
            }
        }

        .body_content {
            // padding: 0px 9em;
        }
    }

    @media only screen and (max-width: 767px) {
        .left_content_1 {
            h2 {
                margin-bottom: 15px;
                font-size: 1.6em;
            }
        }

        .back-arrow {
            width: 18px;
            margin-right: 5px;
        }

        // .body_content {
        //     padding: 0px 1em;
        // }

        .form_buttons button {
            margin-right: 10px;
        }

        .fixed-content .content {
            width: 100%;
        }

        .emp_box {
            align-items: center;
        }

        .emp_box>div {
            overflow: hidden;
            position: unset !important;
            padding-left: 0;
        }

        .add-edit-progress {
            margin-bottom: 10px;
        }

        .left {
            margin-top: 16.5em;
        }
    }

    a.add-more {
        text-decoration: none;
        color: var(--green);
        font-size: var(--p1);
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .add-more img {
        width: 15px;
        margin-right: 5px;
    }

}

.PhoneInputCountry {
    margin-left: 10px;
}

.upload-container {
    display: flex;
    align-items: center;
    gap: 1rem;


    .file-input {
        display: none;
    }

    .upload-button {
        background-color: white;
        border: 1px dashed black;
        padding: 10px 20px;
        min-height: 90px;
        height: 90px;
        width: 90px;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .preview-container {
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }

    .preview-image {
        width: 90px;
        min-width: 90px;
        height: 90px;
        border-radius: 5px;
        padding: 0.3rem;
        border: 1px solid var(--gray5);
        position: relative;

        .hover-box {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.3rem;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.55);
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.3s ease-in-out;

            svg {
                stroke: white;
                fill: white;
            }
        }

        &:hover {
            .hover-box {
                opacity: 1;
            }
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 3px;
        }

    }
}