.btn {
  border: none;
  outline: none;
  font-size: var(--p1);
  border-radius: var(--border-radius);
  font-weight: 500;
  padding: 10px 16px;

  &.big {
    font-size: var(--p2);
    font-weight: 600;
  }

  &.tt-btn {
    &__primary {
      background-color: var(--green);
      color: var(--white);
    }

    &__secondary {
      background-color: var(--light-blue);
      color: var(--green);
    }

    &__danger {
      color: #d92d20;
      background-color: #efcfcf;
    }

    &__text {
      padding: unset !important;
      border-radius: unset;
      border: none;
      background-color: transparent;
      color: var(--green) !important;
    }

    &__light {
      border: none;
      background-color: var(--white);
    }
  }
}

.primary-outline {
  border: 1px solid var(--green);
  color: var(--green);
}