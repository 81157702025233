.terms_page {
  // width: 100vw;
  font-size: 16px;
  background: #ffffff;
  padding-top: 83px;
  // padding-top: 3.438em;
  // overflow-x: hidden;
  text-align: left !important;

  h2 {
    font-size: 2em !important;
    font-weight: 600;
  }

  p {
    color: #263238;
  }

  .header {
    height: 65vh;
    background: #ffffff;
    display: flex;
    width: 100vw;
    overflow: hidden;
    flex-direction: row;
    padding: 0 5%;
    justify-content: center;
    align-items: center;
    position: relative;

    .header-image {
      position: relative;
      width: 40%;

      img {
        width: 100%;
      }
    }

    .header-content {
      position: relative;
      width: 60%;
      display: flex;
      flex-direction: column;
    }

    h2 {
      z-index: 1;
      font-size: 1.25em;
      font-weight: 600;
      color: black;
      width: 100%;
    }

    button {
      margin-top: 0.875em;
      z-index: 1;
      background-color: var(--green);
      color: white;
      padding: 10px 20px;
      margin-right: auto;
      border-radius: var(--border-radius);
      border: none;
      // width: 10%;
      font-size: 1.375em;
      font-weight: 600;
    }
  }

  .body {
    // padding-top: 3.5em;

    .card_sec {
      display: flex;
      width: 50%;
      margin: auto;
      justify-content: space-between;
      padding: 1.75em 0px;

      .card {
        width: 40%;
        border-radius: 20px;
        box-shadow: 0px 3px 15px -2px var(--black);

        .card_content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 1.25em 1.875em;
          border-radius: 20px;
          background-color: white;
          z-index: 3;
          height: 100%;

          h2 {
            font-size: var(--p1);
            font-weight: 600;
          }

          .img_circle {
            width: 9.375em;
            height: 9.375em;
            border-radius: 50%;
            // border: 1px solid black;

            img {
              width: inherit;
            }
          }

          p {
            margin-top: 2.5em;
            font-size: 0.875em;
          }
        }

        .card_overlay {
          background: #df6951;
          position: absolute;
          height: 6.25em;
          width: 6.25em;
          border-top-left-radius: 15px;
          border-bottom-right-radius: 15px;
          z-index: 2;
          left: -5%;
          bottom: -5%;
        }
      }
    }

    .post_sec {
      // width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      // padding: 0.75em 0px;

      .post_left {
        flex: 0.4;
        margin: auto 0px;

        h2 {
          font-size: 3.125em;
        }

        .underline {
          background: cornflowerblue;
          width: 70px;
          height: 5px;
          border-radius: 40px;
          margin-bottom: 15px;
        }

        p {
          font-size: 1.25em;
        }
      }

      .post_full {
        flex: 1;
        margin: auto 0px;

        h1 {
          z-index: 1;
          font-size: 3.125em;
          font-weight: 600;
          color: black;
          width: 100%;
        }

        h2 {
          font-size: 1.25em;
        }

        h5 {
          font-size: 1.125em;
          font-weight: bold;
        }

        .underline {
          background: cornflowerblue;
          width: 70px;
          height: 5px;
          border-radius: 40px;
          margin-bottom: 15px;
        }

        p {
          font-size: var(--p1);
        }
      }

      .post_right {
        flex: 0.45;
        //border: 1px solid black;
        border-radius: var(--border-radius);
        height: 31.25em;
        width: 100%;
        display: flex;
        margin-top: auto;

        img {
          width: inherit;
          width: inherit;
          margin-top: auto;
        }

        .cvimg {
          margin: 0;
          width: auto;
          height: inherit;
        }
      }
    }

    .works_sec {
      width: 100vw;
      background: #f8f8f8;
      padding: 1.75em 0px;

      .post_left {
        flex: 0.5;

        ul {
          list-style: none;
          padding: 0px;
        }
      }

      .post_right {
        flex: 0.3;
        margin-right: 10%;
      }
    }

    .team_sec {
      width: 100vw;
      background-color: lightgray;
      display: flex;
      flex-direction: column;
      padding: 1.75em 0px;

      h2 {
        margin: auto;
        font-size: 3.125em;
      }

      .card_content {
        h2 {
          font-size: 1.875em !important;
        }

        h6 {
          font-size: 1.25em;
          color: #263238;
          font-size: 400;
        }

        p {
          font-size: 1.25em;
          font-weight: 400;
          color: #263238;
        }
      }
    }

    .testimonial {
      width: 100vw;
      padding: 1.75em 0px;
      display: flex;
      flex-direction: column;

      background-color: #f8f8f8;

      h2 {
        margin: auto;
        font-size: 3.125em;
      }

      .testi_content {
        background: #fefaf8;
        width: 70%;
        padding: 1px 0px;
        margin: 12.25em auto 8em auto;
        padding: 0 20px;

        .circle {
          left: calc(50% - 4.688em);
          position: absolute;
          height: 9.375em;
          width: 9.375em;
          border-radius: 50%;
          margin-top: -6.25em;

          img {
            width: inherit;
          }
        }

        .t_c_c {
          display: flex;
          flex-direction: column;
          padding: 50px 0;

          h2 {
            font-size: 1.875em !important;
          }

          h6 {
            font-size: 1.25em;
            color: #263238;
            margin: 10px auto 0 auto;
          }

          .quotes {
            flex: 0.1;
          }

          .mid {
            margin-top: 30px;
            flex: 0.8;
            text-align: center;

            p {
              font-size: 1.125em;
            }

            h6 {
              font-size: 1.125em;
              font-weight: 600;
              font-style: italic;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .header {
      height: 60vh !important;
    }
  }

  @media only screen and (max-width: 800px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      height: auto !important;
      padding-top: 100px;

      h2 {
        width: 100% !important;
        font-size: 2.5em;
      }

      button {
        width: auto;
      }

      .header-content,
      .header-image {
        width: 100%;
        padding: 20px 0;
      }

      .header-image img {
        width: 100%;
        margin: auto;
      }
    }

    .body {
      .card_sec {
        width: 80%;
        flex-direction: column;

        .card {
          width: 100%;
          margin: 1.25em 0;
        }
      }

      .post_sec {
        flex-direction: column;
      }
    }

    .testi_content {
      width: 80% !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (max-width: 767px) {
    .body {
      .post_sec {
        .post_full {
          h1 {
            font-size: 2.5em !important;
          }
        }
      }

      .post_full h2 {
        font-size: 2.5em !important;
      }
    }
  }
}