@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");

@font-face {
  font-family: "Plus Jakarta Sans", sans-serif !;
  src: url(./fonts/PlusJakarta.ttf);
}
 body {
    font-family: "Plus Jakarta Sans", sans-serif !important;
  }
@media print {
  body {
    font-family: "Plus Jakarta Sans", sans-serif !important;
    font-size: 8px !important;
  }

  .hide-btn {
    visibility: hidden;
    opacity: 0;
  }

  h2 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    font-size: 10px;
  }

  p {
    font-size: 8px;
  }

  .user_profile .body_content .left {
    flex: 0.65 1 !important;
  }

  .user_profile .body_content .right {
    flex: 0.35 1 !important;
  }
}