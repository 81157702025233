.company_dashboard_page {
  font-size: 13px;
  // overflow-x: hidden;
  // padding-top: 3.438em;
  .feature_content {
    p {
      color: var(--gray7);
    }

    .candidate-card-basic {
      flex: 0.9 1;
      margin-bottom: 0.5rem !important;
      p {
        word-break: break-word;
        margin-bottom: 0.4rem;
      }
    }
    .paragraph_1 {
      margin-bottom: 0 !important;
      color: var(--black1);
      height: auto;
    }
  }

  .topTitle {
    margin-top: 10px !important;
  }

  .bg_green {
    background: #14bc9a09;
  }

  .bg_green_absolute {
    position: absolute;
    height: calc(100% - 20px);
    top: 0;
    width: 200vw;
    left: -30%;
    background: #14bc9a09;
    z-index: -200;
  }

  .bg_container_box {
    padding: 10px 0;
    position: relative;
  }

  .company {
    border: solid 1px var(--gray);
    border-radius: 16px;
    padding: 30px 30px;
    flex-direction: column;
    display: flex;

    img {
      margin: auto;
    }

    span {
      font-size: 1.125em;
      font-weight: 500;
      text-decoration: underline;
    }

    p {
      font-size: 0.82em;
      font-weight: 500;
    }

    a {
      font-size: 0.94em;
      font-weight: 500;
      color: var(--green);
      text-decoration: underline;
    }
  }

  .company-details {
    border: solid 1px var(--gray);
    border-radius: 16px;
    padding: 20px 20px;

    h4 {
      font-size: 1.3em;
      font-weight: 500;
    }

    p {
      font-size: 0.875em;
      font-weight: 500;
      color: #1b2124;
    }

    span {
      font-size: 0.875em;
      font-weight: 400;
      color: #5e6d55;
    }

    a {
      font-size: 0.94em;
      font-weight: 500;
      color: var(--green);
      text-decoration: underline;
    }
  }

  .content-title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    // letter-spacing: 4px;
    text-align: left;
    margin-bottom: 20px;
    color: #7f879e;
  }

  .section-third.text-center {
    margin-top: 10%;
  }

  .content-sub-title {
    font-size: 2.25em;
    font-weight: 700;
    // letter-spacing: -2px;
    text-align: left;
    margin-bottom: 20px;
  }

  h2 {
    // font-family: Plus Jakarta Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: left;
  }

  h3 {
    // font-family: Plus Jakarta Sans;
    font-size: var(--h3);
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0px;
  }

  h5 {
    //styleName: Headings/H5 - 24 Bold - Lh 130%;
    // font-family: Plus Jakarta Sans;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: center;
  }

  .why-hire {
    //styleName: Paragraph/P1/18 Regular - Lh 160%;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: center;
    font-size: 1.2rem;
  }

  .content-paragraph {
    font-size: var(--p1);
    font-weight: 400;
    line-height: 28px;
    // letter-spacing: -0.4000000059604645px;
    text-align: left;
    color: #7f879e;
  }

  .section1 {
    // margin-top: 5%;
  }

  .content-paragraph1 {
    //styleName: Paragraph/P1/18 Regular - Lh 160%;

    font-size: var(--p1);
    font-weight: 400;
    line-height: 28px;
    // letter-spacing: -0.4000000059604645px;
    text-align: center;
    color: #7f879e;
    // width: 94%;
  }

  .emp-button {
    cursor: pointer;
  }

  .why-true {
    //styleName: Label/L3 - 18 Semi Bold - Lh 130%;
    // font-family: Plus Jakarta Sans;
    font-size: 1.25;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0px;
  }

  .employee-button:hover {
    background-color: rgb(20, 188, 154);
    // opacity: 0.1;
    color: #fff !important;
    border-radius: 10px !important;
  }

  .candidate-button:hover {
    background-color: rgb(20, 188, 154);
    // opacity: 0.1;
    color: #fff !important;
    border-radius: 10px !important;
  }

  .employee-button:hover p.emp-button,
  .candidate-button:hover p.emp-button {
    color: #fff !important;
  }

  .employee-button hr {
    margin-top: 0px;
  }

  p.emp-button {
    padding: 10px;
  }

  p.emp-button + hr {
    margin-top: 0px;
  }

  .emp-section {
  }

  .exp-hr {
    // font-family: Plus Jakarta Sans;
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0px;
    text-align: center;
  }

  .exp-pra {
    //styleName: Paragraph/P1/18 Regular - Lh 160%;
    text-align: center;
    color: #7f879e;
    width: 80%;
    margin-left: 12%;
  }

  .header {
    height: 65vh;
    background: #ffffff;
    display: flex;
    width: 100vw;
    overflow: hidden;
    flex-direction: row;
    padding: 0 5%;
    justify-content: center;
    align-items: center;
    position: relative;

    .header-image {
      position: relative;
      width: 40%;

      img {
        width: 100%;
      }
    }

    .header-content {
      position: relative;
      width: 60%;
      display: flex;
      flex-direction: column;
    }

    h2 {
      z-index: 1;
      font-size: 3.125em;
      font-weight: 600;
      color: black;
      width: 100%;
    }

    button {
      margin-top: 1.25em;
      z-index: 1;
      background-color: var(--green);
      color: white;
      padding: 10px 20px;
      margin-right: auto;
      border-radius: var(--border-radius);
      border: none;
      // width: 10%;
      font-size: 1.375em;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 900px) {
    .right-section.px-5 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .body1 {
      width: 80%;
      align-items: center;
      margin-left: 10%;
    }

    img.image {
      width: 85%;
      display: flex;
    }

    .section2 {
      margin-top: 5%;
    }

    img.image2 {
      height: 75%;
    }
  }

  // .body {
  // padding-top: 3.5em;

  .card_sec {
    display: flex;
    width: 50%;
    margin: auto;
    justify-content: space-between;
    padding: 3.75em 0px;

    .card {
      width: 40%;
      border-radius: 20px;
      box-shadow: 0px 3px 15px -2px var(--black);

      .card_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1.25em 1.875em;
        border-radius: 20px;
        background-color: white;
        z-index: 3;
        height: 100%;

        h2 {
          font-size: 2.5em;
          font-weight: 600;
        }

        .img_circle {
          width: 9.375em;
          height: 9.375em;
          border-radius: 50%;
          // border: 1px solid black;

          img {
            width: inherit;
          }
        }

        p {
          margin-top: 2.5em;
          font-size: 1.125em;
        }
      }

      .card_overlay {
        background: #df6951;
        position: absolute;
        height: 6.25em;
        width: 6.25em;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        z-index: 2;
        left: -5%;
        bottom: -5%;
      }
    }
  }

  .post_sec {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 3.75em 0px;

    .post_left {
      flex: 0.4;
      margin: auto 0px;

      h2 {
        font-size: 3.125em;
      }

      .underline {
        background: cornflowerblue;
        width: 70px;
        height: 5px;
        border-radius: 40px;
        margin-bottom: 15px;
      }

      p {
        font-size: 1.25em;
      }
    }

    .post_full {
      flex: 1;
      margin: auto 0px;

      h2 {
        font-size: 3.125em;
      }

      .underline {
        background: cornflowerblue;
        width: 70px;
        height: 5px;
        border-radius: 40px;
        margin-bottom: 15px;
      }

      p {
        font-size: 1.25em;
      }
    }

    .post_right {
      flex: 0.45;
      //border: 1px solid black;
      border-radius: var(--border-radius);
      height: 31.25em;
      width: 100%;
      display: flex;
      margin-top: auto;

      img {
        width: inherit;
        width: inherit;
        margin-top: auto;
      }

      .cvimg {
        margin: 0;
        width: auto;
        height: inherit;
      }
    }
  }

  .works_sec {
    width: 100vw;
    background: #f8f8f8;
    padding: 3.75em 0px;

    .post_left {
      flex: 0.5;

      ul {
        list-style: none;
        padding: 0px;
      }
    }

    .post_right {
      flex: 0.3;
      margin-right: 10%;
    }
  }

  .team_sec {
    width: 100vw;
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    padding: 3.75em 0px;

    h2 {
      margin: auto;
      font-size: 3.125em;
    }

    .card_content {
      h2 {
        font-size: 1.875em !important;
      }

      h6 {
        font-size: 1.25em;
        color: #263238;
        font-size: 400;
      }

      p {
        font-size: 1.25em;
        font-weight: 400;
        color: #263238;
      }
    }
  }

  .testimonial {
    width: 100vw;
    padding: 3.75em 0px;
    display: flex;
    flex-direction: column;

    background-color: #f8f8f8;

    h2 {
      margin: auto;
      font-size: 3.125em;
    }

    .testi_content {
      background: #fefaf8;
      width: 70%;
      padding: 1px 0px;
      margin: 12.25em auto 8em auto;
      padding: 0 20px;

      .circle {
        left: calc(50% - 4.688em);
        position: absolute;
        height: 9.375em;
        width: 9.375em;
        border-radius: 50%;
        margin-top: -6.25em;

        img {
          width: inherit;
        }
      }

      .t_c_c {
        display: flex;
        flex-direction: column;
        padding: 50px 0;

        h2 {
          font-size: 1.875em !important;
        }

        h6 {
          font-size: 1.25em;
          color: #263238;
          margin: 10px auto 0 auto;
        }

        .quotes {
          flex: 0.1;
        }

        .mid {
          margin-top: 30px;
          flex: 0.8;
          text-align: center;

          p {
            font-size: 1.125em;
          }

          h6 {
            font-size: 1.125em;
            font-weight: 600;
            font-style: italic;
            margin-top: 30px;
          }
        }
      }
    }
  }

  // }

  @media only screen and (max-width: 500px) {
    .header {
      height: 60vh !important;
    }

    .why-true {
      margin-top: 10px;
    }

    .exp-hr,
    h3 {
      // font-size: 2.25em;
      line-height: normal;
    }

    img.image {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 1200px) {
    img.image {
      width: 100%;
    }

    p.content-title {
      font-family: Plus Jakarta Sans;
      margin-top: 15px;
    }
  }

  @media only screen and (max-width: 575px) {
    img.image {
      width: 100%;
      height: 100%;
    }

    p.content-title {
      margin-top: 15px;
      font-family: Plus Jakarta Sans;
    }

    .why-true {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 766px) {
    img.image {
      width: 100%;
      height: 100%;
    }

    .about_page .body1 {
      width: 100%;
      margin: 0px;
    }

    .section-third.text-center .col-md-12 .row,
    .section-fifth.text-center .col-md-12 .row,
    .section-seventh.text-center .col-md-12 .row {
      flex-direction: column-reverse;
    }

    .section-third1.text-center .col-md-12 .row,
    .section-fifth1.text-center .col-md-12 .row,
    .section-seventh1.text-center .col-md-12 .row {
      flex-direction: column-reverse;
    }

    .about_page .content-title {
      // letter-spacing: 2px;
    }

    .col-md-12.mt-5.about-banner img.image2 {
      width: 100%;
    }
  }

  @media only screen and (max-width: 800px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      height: auto !important;
      padding-top: 100px;

      h2 {
        width: 100% !important;
      }

      button {
        width: auto;
      }

      .header-content,
      .header-image {
        width: 100%;
        padding: 20px 0;
      }

      .header-image img {
        width: 100%;
        margin: auto;
      }
    }

    .body {
      .card_sec {
        width: 80%;
        flex-direction: column;

        .card {
          width: 100%;
          margin: 1.25em 0;
        }
      }

      .post_sec {
        flex-direction: column;
      }
    }

    .testi_content {
      width: 80% !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (max-width: 767px) {
    .body {
      .post_sec {
        .post_full {
          h1 {
            font-size: 2.5em !important;
          }
        }
      }

      .post_full h2 {
        font-size: 2.5em !important;
      }
    }
  }
}
