.LoggedNav {
  font-size: 16px;

  #dropdownMenuLink {
    padding-left: 0;
  }

  .navbar-nav {
    margin-left: 5em;
    gap: 20px;
  }

  .dropdown-item.active {
    background-color: transparent;
    color: #000;
  }

  @media only screen and (max-width: 576px) {
    .navbar {
      // padding: 0px 1em !important;
    }
  }

  .become_pro_btn {
    border: none;
    width: 120px;
    height: 34px;
    font-size: var(--p1);
    font-weight: 400;
    border-radius: 6px;
    background: #14bc9a;
    color: #ffffff;
    outline: none;
    box-shadow: none;
    margin-right: 1rem;
  }

  .navbar {
    background-color: var(--white);
    -webkit-box-shadow: 6px 0px 6px var(--black);
    -moz-box-shadow: 6px 0px 6px var(--black);
    box-shadow: 6px 0px 6px var(--black);
    position: fixed;
    width: 100vw;
    z-index: 999;
    // padding: 0px 2rem !important;

    // .container-fluid {
    //   max-width: 82% !important;
    // }
  }

  .navbar-collapse {
    align-items: initial !important;
  }

  nav .container-fluid {
    padding: 0px;
  }

  .nav-item {
    margin-right: 20px;
    display: flex;
  }

  .right-nav-item,
  .msg-nav {
    margin-right: 0px !important;
  }

  .nav-link {
    cursor: pointer;
    padding: 1em;
    font-weight: 400;
    font-size: var(--p1);
    color: var(--gray4);
    border-bottom: solid 3px transparent;
  }

  .nav-link.active {
    border-bottom: 3px solid var(--red);
    color: var(--black2);
    font-weight: 500;
  }

  .nav-link.active-green {
    border-bottom: 3px solid var(--green);
    color: var(--green);
    font-weight: 600;
  }

  .nav-link.active-green:hover {
    color: var(--green);
  }

  .nav-item .nav_round {
    margin: auto;
    /* background: gray; */
    display: flex;
    justify-content: center;
    border-radius: 50%;
    /* padding: 6px; */
  }

  .nav_round img {
    width: 30px;
  }

  .profile_icon {
    display: flex;
    margin-left: 10px;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    border-left: 2px solid #f5f6f7;
    border-bottom: 3px solid transparent;

    &.active {
      border-bottom: 3px solid var(--green);
    }
  }

  .round_profile {
    border-radius: 50%;
    overflow: hidden;
    height: 2.813em;
    width: 2.813em;
    margin-right: 20px;
    margin: auto;
  }

  .round_profile img {
    width: inherit;
    object-fit: cover;
    height: -webkit-fill-available;
  }

  .dropdown {
    display: flex;
    align-items: center;
    margin-left: 5px;
    border: none;

    li.active {
      border-bottom: 3px solid var(--red);
      color: var(--black2);
      font-weight: 600;
      background-color: white;
    }
  }

  .logged-naving.active {
    #dropdownMenuLink {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  #dropdownMenuLink {
    color: gray;
    text-decoration: none;
  }

  .dropdown-menu {
    padding: 0px;
    // margin-left: -3.438em;
    position: absolute;
    left: auto;
    right: 0;
    margin-top: 0.5em;

    .log-out {
      color: #fd5755;
    }
  }

  .dropdown-menu li {
    display: flex;
    border-bottom: 1px solid var(--gray5);
    padding: 5px 10px;

    a {
      font-size: 1.125em;
      font-weight: 600;
    }

    a:hover {
      color: black;
      background: none;
      cursor: pointer;
    }

    .float-right {
      float: right;
    }

    @media only screen and (max-width: 1280px) {
      font-size: 12px !important;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 11px !important;
    }

    @media only screen and (max-width: 1600px) {
      font-size: 13px;
    }
  }

  .dropdown-menu img {
    width: 1.125em;
    cursor: pointer;
  }

  .navbar-toggler {
    color: rgba(0, 0, 0, 0.55);
    border: 0px;
    outline: none;
    box-shadow: none;
  }

  .navbar-brand {
    cursor: pointer;
    display: flex;

    img {
      margin: auto;
      width: 6.5em;
    }
  }

  @media only screen and (max-width: 990px) {
    .navbar-nav {
      margin-left: 0px;
    }

    .nav-item {
      margin: 0px;

      .nav-link {
        width: 100%;
      }

      .nav_round {
        margin: 0px !important;
      }
    }

    .profile_icon {
      display: flex;
      margin-left: 0px;
      padding-left: 0px;
      border: 0px;
      padding-bottom: 10px;
    }

    .round_profile {
      margin: 0px;
    }

    .dropdown-menu {
      margin-top: 0.8em;
    }

    .navbar-toggler {
      border: 0px;
    }

    .navbar-brand {
      img {
        width: 5.375em;
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 10px !important;
  }

  @media only screen and (max-width: 768px) {
    .navbar .container-fluid {
      max-width: 100% !important;
    }
  }


}