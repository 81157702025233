.resume-upload-section {
  border: 2px dashed #dbdbdb;
  padding: 1.5rem 1rem;

  &-container {
    display: flex;
  }

  &.square {
    width: 100px;
    height: 100px;
  }

  &-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &-delete {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .1607843137);
    border-radius: 50%;
    cursor: pointer;
  }

  .resume-filename {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 200px;
    text-wrap: wrap;
  }

  .file-name-upload {
    // font-size: 0.875em;
    font-size: 13px;
    font-weight: 600;
    color: var(--green);
    display: flex;
    align-items: center;
    gap: 8px;
    word-break: break-word;
    flex: 1 1 60%;
    justify-content: center;

    .upload-input {
      opacity: 0;
      position: absolute;
      width: 140px;
      cursor: pointer;
    }

    &.square {
      flex-direction: column;
    }
  }

  .delete-button {
    cursor: pointer;
    display: inline-block;

    img {
      pointer-events: none;
    }
  }
}

.profile-upload {

  &-section {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &-img {
    height: 51.5px;
    width: 51.5px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-input {
    position: relative;
    font-size: 0.875em;
    font-weight: 600;
    color: var(--green);

    input {
      position: absolute;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.resume-upload-component {
  display: flex;
  align-items: center;
  justify-content: center;

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 4.5rem;

    .back-arrow {
      width: auto;
      max-width: 30px;
      margin-right: 0;
      height: auto;
    }

    h2 {
      flex-grow: 1;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }
}

.upload-resume {
  .header {
    display: flex;
    align-items: center;
  }

  h2 {
    //  font-size: 1.5em;
    font-weight: 600;
    //  flex: 0.9 1;
  }

  .head-text {
    font-size: var(--p1);
    padding: 0 25% 0 25%;
  }

  p {
    font-size: var(--p1);
    font-weight: 400;
  }

  a {
    color: var(--green);
    font-weight: 600 !important;
  }
}

.file-upload {
  &-box {
    height: 215px;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 10px 12px;
  }

  &-icon {
    width: 40px;

    img {
      width: 100%;
    }
  }

  &-text {}

  &-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}



// For or line effect

.or-line {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 0.6rem;

  &:before {
    z-index: 1;
    position: absolute;
    content: "";
    background-color: #dcdcdc;
    height: 1px;
    width: 44%;
    left: auto;
    right: 0;
    bottom: 10px;
  }

  &:after {
    position: absolute;
    content: "";
    background-color: #dcdcdc;
    height: 1px;
    width: 44%;
    left: 0;
    right: auto;
    bottom: 10px;

  }
}