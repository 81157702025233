.table {
  border-width: 1px;
  border-color: #f2f2f2;
  border-style: solid;
  &__section {
  }

  &__wrapper {
    width: 100% !important;
    overflow-x: auto;
    min-height: 66vh;

    /* width */
    &::-webkit-scrollbar {
      height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      // background: #f1f1f1;
      box-shadow: inset 0 0 1px var(--gray5);
      border-radius: var(--border-radius);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--gray5);
      border-radius: var(--border-radius);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--gray4);
      cursor: pointer;
    }

    /// Table css
    .results td div {
      max-width: 225px;
    }

    tr {
      border-left: 1px solid #f2f2f2 !important;
    }
    tbody {
      border-top: none !important;
    }

    .table > :not(caption) > * > * {
      border: 0px;
      padding: 0.8em 1.5em;
    }

    td.bold {
      font-weight: 600;
    }

    td {
      font-size: var(--p);
      font-weight: 500;
      color: var(--gray8);
    }

    th {
      font-size: var(--p1);
      color: var(--black5);
      font-weight: 600;
      white-space: nowrap;
    }
    .results {
      cursor: pointer;
      border-left: 1px solid var(--input-border);
    }

    .results:hover {
      background-color: var(--gray2);
      border-left: 3px solid var(--green) !important;
    }

    .hover {
      cursor: pointer;
      border-left: 1px solid var(--input-border);
    }

    .hover:hover {
      background-color: var(--gray2);
      border-left: 3px solid var(--green);
    }
  }
}
