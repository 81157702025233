// .upload {
//     display: flex;
//     justify-content: space-between;
//     padding: 1.25em;
//     border: 3px dotted #dbdbdb;
//     border-radius: 1.25em;
//     margin-bottom: 0.75em;
//     border-radius: 0.625em;

//     div {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         cursor: pointer;
//         margin: 0 1.25em;
//     }

//     img {
//         width: 1.25em;
//         margin-right: 0.625em;
//     }
// }

// .upload_resume {
//     gap: 5px;
//     position: relative;
//     margin: 0 0.5em;

//     input {
//         position: absolute;
//         opacity: 0;
//         width: 100%;
//     }

//     a {
//         font-size: 0.875em;
//         font-weight: 600;
//         color: var(--green);
//         text-decoration: none;
//     }

//     .deleteIcon {
//         border-radius: 50%;
//         padding: 9px 11px;
//         width: 40px;
//         height: 40px;
//         box-shadow: 0px 3px 6px #00000029;
//         display: flex;
//         justify-content: center;
//         align-items: center
//     }
// }

.help-text-left {
    color: var(--black3);
    display: flex;
    justify-content: start;
    margin-bottom: 1rem;
    font-size: 14px;
    gap: 5px;

    p {
        color: var(--green);
        font-weight: 700
    }
}

.upload_picture {
    position: relative;
    cursor: pointer;
    margin-left: 1rem;

    input {
        position: absolute;
        opacity: 0;
        width: 100%;
        cursor: pointer;
    }

    p {
        color: var(--green);
    }
}

.help-text-right {
    color: var(--black3);
    display: flex;
    justify-content: end;
    margin-bottom: 1rem;
    font-size: 14px;
    gap: 5px;

    p {
        color: var(--green);
        font-weight: 700
    }
}