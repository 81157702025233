.react-calendar {
  background-color: white;
  border: none !important;
  //   width: 400px !important;
  width: 292px !important;
}

abbr[title] {
  text-decoration: none !important;
}

.react-calendar__navigation {
  position: relative !important;
  align-items: start !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__navigation__prev-button {
  position: absolute;
  top: 0;
  right: 40px;
  border: 1px solid #edeeff !important;
  border-radius: 50%;
  height: 26px !important;
  width: 26px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex: none !important;
  padding: 0 !important;
}

.react-calendar__navigation__label {
  text-align: start !important;
}

.react-calendar__navigation__label:hover {
  background-color: transparent !important;
}

.react-calendar__tile {
  width: 26px !important;
  height: 26px !important;
  border-radius: 50%;
  border: 1px solid #edeeff !important;
  flex: none !important;
  padding: 0 !important;
}

.react-calendar__tile:hover,
.react-calendar__tile:focus {
  background-color: #0f9d81 !important;
}

.react-calendar__month-view__weekdays__weekday {
  height: 26px !important;
  width: 26px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex: none !important;
  padding: 0 !important;
}

.react-calendar__navigation button {
  min-width: 0 !important;
}

.react-calendar__navigation__next-button {
  height: 26px !important;
  width: 26px !important;
  border-radius: 50%;
  border: 1px solid #edeeff !important;
  border: 1px solid;
  min-width: none !important;
}

.react-calendar__month-view__weekdays {
  column-gap: 18px !important;
  margin-bottom: 16px;
}

.react-calendar__month-view__days {
  column-gap: 18px !important;
  row-gap: 16px !important;
}

.react-calendar__navigation__label__labelText--from {
  color: black;
  font-size: 18px;
  font-weight: 700;
}

.react-calendar__tile--active {
  background: var(--green) !important;
  color: white !important;
}

.react-calendar__tile--now {
  background-color: transparent !important;
  color: black !important;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}