.posted_jobs {
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 6em;
  font-size: 16px;
  margin: 0;

  .body_section {
    background-color: var(--white);
    width: 100vw;
    display: flex;
    flex-direction: column;
    min-height: 70vh;
    // max-height: 70vh;
    // overflow-y: scroll;
  }

  .list_2_active_content,
  .list_2_closed_content,
  .list_2_draft_content {
    height: 24.625em;
    overflow: scroll;
    overflow-x: auto;
  }

  .no_jobs {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 3.125em;
    }

    h2 {
      font-size: 1.5em;
      font-weight: 600;
      color: #263238;
      margin: 1.25em;
    }
  }

  .dropdown {
    display: flex;
    align-items: center;
  }

  #dropdownMenuLink {
    background-color: transparent;
    border: none;
    color: black;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 0;
    padding-left: 10px;
  }

  .dropdown-menu {
    padding: 0px;
    margin-left: -3.438em;
    margin-top: 0.5em;
  }

  .dropdown-menu li {
    display: flex;
    border-bottom: 1px solid var(--gray5);
    padding: 5px 10px;

    a {
      font-size: 0.75em;
      font-weight: 600;
    }

    a:hover {
      color: black;
      background: none;
      cursor: pointer;
    }
  }

  .result_header_sort {
    display: flex;

    P {
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 0.875em;
      display: flex;
      white-space: nowrap;
      align-items: center;
    }

    .dropdown {
      box-shadow: none;

      .dropdown-toggle {
        font-size: 0.875em;
        font-weight: 600;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    // padding-left: 1rem;
    // padding: 0px 10rem;
    // margin-bottom: 30px;

    .header_left {
      align-items: center;

      button {
        background-color: var(--green);
        color: var(--white);
        border: 0px;
        outline: none;
        padding: 0.75em 1.5em;
        font-family: "Plus Jakarta Sans", sans-serif;
        border-radius: 8px !important;
        font-size: 0.875em;
        font-weight: 500;
      }

      button.btn.btn-primary {
        background-color: #effffc !important;
        color: var(--green) !important;
        margin-left: 10px;
      }

      h2 {
        margin-bottom: 0px;
        margin-right: 1.25em;
        font-size: 1.25em;
      }
    }

    .filter_tab_header {
      .list-group {
        height: auto;
        display: flex;
        flex-direction: row;

        .round {
          width: 3.125em;
          height: 3.125em;
          border-radius: 50%;
          width: 3.125em;
          height: 3.125em;
          display: flex;
          // border-radius: 50%;
          justify-content: center;
          align-items: center;
          opacity: 0.2;
          // background-color: #eef5f7;

          // img{
          //     width: 50%;
          // }
        }

        .list-group-item {
          &.active {
            .round {
              // background-color: #0b264c;
              opacity: 1;

              // img{
              //     filter:  invert(1) brightness(5);
              // }
            }
          }
        }

        // #list_1 {
        //     .round {
        //         background-color: #eef5f7;
        //     }
        // }

        // #list_2 {
        //     .round {
        //         background-color: #0b264c;
        //     }
        // }
        .list-group-item.active {
          border: none;
          background-color: white !important;
        }

        .list-group-item:hover {
          border: none;
          background-color: white;
        }

        .list-group-item {
          margin: 0;
          margin-left: 20px;
          border: none;
          padding: 0;
        }
      }
    }
  }

  .search_section {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .toggle-header {
      p {
        font-size: 0.875em;
      }
    }

    .input-group {
      border: 1px solid var(--gray5);
      border-radius: var(--border-radius);
      flex: 0.8;

      span {
        background-color: transparent;
        border: none;
        font-size: 1.25em;
        font-weight: 600;
      }

      input {
        border: none;
        outline: none;
        background-color: transparent;
        box-shadow: none;
        height: 2.5em;
      }
    }

    .sort_by {
      margin-left: auto;

      p {
        white-space: nowrap;
        font-weight: 400;

        strong {
          font-weight: 600;
          font-family: "Plus Jakarta Sans", sans-serif;
        }
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 3.438em;
      height: 1.563em;
      margin: auto 10px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked+.slider {
      background-color: var(--green);
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--green);
      transition: 0.4s;
      margin-bottom: 0px;
    }

    input:checked+.slider:before {
      transform: translateX(1.625em);
    }

    .slider.round:before {
      border-radius: 50%;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 1.188em;
      width: 1.188em;
      left: 0.25em;
      bottom: 0.188em;
      background-color: white;
      transition: 0.4s;
    }
  }

  /* Search bar section */

  // /*  Result section */

  .result_section {
    // margin-top: -20px;
    background-color: var(--white);
    // margin: 1.875em auto 0px auto;
    // padding: 0px 10rem;
    display: flex;

    div {
      margin-top: 0;
      width: 100%;
    }
  }

  .list-group-child {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    border-bottom: 1px solid  var(--input-border);
  }

  .list-group-item-child.active {
    background-color: white;
    border: none;
    font-size: var(--p1);
    font-weight: 600;
    color: var(--black3);
    border-bottom: 2px solid var(--green);
  }

  .list-group-item-child:hover {
    background-color: white;
    border-bottom: 2px solid var(--green);
  }

  .list-group-item-child {
    border-radius: 0px !important;
    color: var(--gray8);
    font-size: var(--p1);
    font-weight: 300;
    font-family: "Plus Jakarta Sans", sans-serif;
    border: 0px;
    width: auto;
    margin-right: 37px;
    padding: 0.5em 0px;
  }

  .tab-content-child {
    margin-top: 2.188em;
  }

  .tab-content-child>.tab-pane {
    height: auto;
    // overflow-y: scroll;
  }

  .tab-content-child>.tab-pane::-webkit-scrollbar {
    display: none;
  }

  .tab-content-child>.tab-pane {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  //-------------------------------- list_1_active_content

  .card_section {
    display: flex;
    flex-wrap: wrap;

    .card {
      width: 100%;
      // margin: 1.25em 0;
      // padding: 0 1em;
      border: none;

      .card_main {
        border-radius: 1.25em;
        padding: 1.25em 0;
        height: 100%;
      }

      .card_header {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: auto;
        align-items: center;
        margin-bottom: 0.625em;

        .days-left {
          font-size: var(--p1);
          background: #efcfcf 0% 0% no-repeat padding-box;
          border-radius: 3px;
          margin: 5px;
          padding: 2px 10px 2px 10px;
          color: #ea3e3e;
        }

        h3 {
          font-size: 0.875em;
          font-weight: 600;
        }
      }

      .card_content {
        width: 90%;
        margin: auto;
        margin-bottom: 1.25em;

        h2 {
          margin-bottom: 0.625em;
          font-size: var(--p1);
        }

        p {
          font-size: 0.875em;
          margin-bottom: 0.625em;
          font-weight: 500;
        }

        span {
          color: #9498a0;
          font-size: 0.75em;
          font-weight: 500;
        }
      }

      .card_buttons {
        width: 90%;
        margin: auto;
        display: flex;
        gap: 1rem;

        div {
          padding: 0.625em 0.625em;
          background-color: var(--light-blue);
          border-radius: 0.625em;
          width: fit-content;

          p {
            color: var(--green);
            font-weight: 500;
            font-size: var(--p0);
          }
        }
      }
    }

    .card:hover {
      cursor: pointer;
    }
  }

  tr {
    width: 100%;
    cursor: pointer;
    border-left: 1px solid var(--input-border);
  }

  .hovering:hover {
    background-color: var(--gray2);
    border-left: 3px solid var(--green);
  }

  #nav-tabContent_1,
  #nav-tabContent_2 {
    .tab-pane {
      min-height: 50vh;
      overflow: scroll;
    }

    .tab-pane::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .tab-pane {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }

  footer {
    position: relative;
    width: 100%;
  }

  @media only screen and (max-width: 576px) {
    .header {
      padding: 0px 2em;
      padding-top: 4em !important;
    }

    .search_section {
      padding: 0px 2em;
    }

    .result_section {
      padding: 0px 2em;
    }
  }

  @media only screen and (max-width: 800px) {
    overflow-x: hidden;

    .header {
      .header_left {
        margin-bottom: 0.625em;
      }

      .list-group {
        .list-group-item {
          margin-left: 0px !important;
        }
      }
    }

    #nav-tabContent {
      overflow-x: auto;
    }

    .card_section {
      .card {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  .footer_upper {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .card_section {
      .card {
        .card_buttons {
          div {
            margin-right: 1em;

            p {
              font-size: var(--p0);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .header {
      flex-direction: column;

      .header_left h2 {
        margin-right: 1em;
        font-size: 1.8em;
      }

      .filter_tab_header .list-group {
        justify-content: flex-end;

        .list-group-item {
          width: auto;
        }
      }
    }

    .card_section .card {
      padding: 0;
    }

    .card_section .card .card_buttons {
      flex-direction: column;
    }

    .card_section .card .card_buttons div:first-child {
      margin-bottom: 5px;
    }

    .list-group-child {
      overflow-y: auto;
    }

    .list-group-child::-webkit-scrollbar {
      display: none;
    }

    .list-group-child {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  @media only screen and (max-width: 340px) {
    .search_section input:checked+.slider:before {
      transform: translateX(1.4em);
    }
  }
}
.number-div{
  width: 60px !important;
  text-align: right;
}