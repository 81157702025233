.candidate-registration {
    .nav-item {
        img {
            width: 25px;
            height: 25px;
            margin-top: 6px;
        }

        p {
            color: #344054;
            font-weight: 700;
            font-size: var(--p1);
        }
        span {
            color: #475467;
            font-weight: 400;
            font-size: 0.875em;
        }

        .active {
            p {
                color: var(--green);
            }

            span {
                color: var(--green);
            }
        }

        .detail {
            margin-left: 10px;
        }
    }

    // .step-content {
    //     margin-top: 1.7em;
    // }

    // .left-steps {
    //     margin-top: 1.4em;
    // }
}