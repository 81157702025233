.user_profile {
  height: 100%;
  // height: 100vh;
  // width: 100vw;
  padding-top: 55px;
  font-size: 16px;

  .layoff-container {
    background-color: rgba(253, 129, 94, 0.05);
    border-radius: 19px;
    text-align: center;
    margin-top: 15px;
    padding: 0 2rem;

    p {
      color: #fd815e !important;
      padding: 6px;
      font-size: 0.9em;
    }
  }

  .body_content {
    // display: flex;
    width: 100%;
    // padding-left: 10rem;
    // padding-right: 10rem;
    // margin: auto;
    // padding-top: 6.25em;
    // padding-bottom: 50px;

    hr {
      background-color: #dbdbdb;
      height: 0.25em;
    }

    .left {
      // flex: 0.75;

      .video-player {
        & > div {
          width: 100%;
          height: auto;
          max-height: 670px;

          & > video {
          }
        }
      }

      .profile_img_div {
        display: flex;
        align-items: center;
        margin-bottom: 1.25em;
        justify-content: space-between;
        padding-right: 2rem;

        .profile_img {
          width: 8.625em;
          height: 8.625em;
          border-radius: 50%;
          // margin-right: 20px;
          background-color: var(--gray2);
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: inherit;
          }
        }

        .profile_txt {
          h2 {
            font-size: 1.5em;
            color: #263238;
            font-weight: 700;
          }

          p {
            font-size: var(--p1);
            color: #263238;
            margin-bottom: 0.625em;
          }

          .profile_txt_id {
            // display: inline-flex;

            .last-updated {
              // margin-left: 22em;
            }
          }
        }
      }

      .details {
        // margin-top: 2.25em;
        text-align: justify;
        width: 95%;

        p {
          font-size: 1.125em;
          color: #2c2c2c;
          font-weight: 500;
          width: 90%;
        }

        ul {
          list-style: none;
          padding: 0px;
          margin: 20px 0;

          li {
            display: flex;
            margin: 0.1em 0;

            img {
              width: 2.5rem;
              margin-right: 0.8rem;
            }

            p {
              font-size: var(--p1);
              color: #263238;
              font-weight: 600;
              display: flex;
              align-items: center;

              a {
                font-size: var(--p1);
                color: #263238;
                font-weight: 600;
                display: flex;
                align-items: center;
                text-decoration: none;

                &:hover {
                  color: var(--green);
                }
              }
            }
          }
        }
      }

      .wp-details {
        ul {
          list-style-type: none;
          display: flex;
          justify-content: start;
          gap: 16px;
          flex-wrap: wrap;

          li {
            background: rgba(20, 188, 154, 0.05);
            border-radius: 12px;
            padding: 8px 15px;

            p {
              letter-spacing: 0px;
              color: #9398a1;
              font-size: 0.7em !important;
            }

            span {
              letter-spacing: 0px;
              color: #263238;
              font-size: 0.9em;
              font-weight: 700;
            }
          }
        }
      }

      .work_details {
        .w_sec {
          margin-top: 3em;

          h2 {
            font-size: 1.25em;
            color: #263238;
            font-weight: 600 !important;
            margin-bottom: 0.3rem;
          }

          .w_det {
            margin-top: 2.5em;
            word-break: break-word;

            h2 {
              font-size: 1.125em;
              font-weight: 600;
            }

            p {
              margin-top: 1.25em;
              font-size: var(--p1);
              color: #2c2c2c;
            }

            .awarded_by {
              font-weight: 800;
            }
          }

          .additional-details {
            ul {
              display: flex;
              justify-content: flex-start;
              list-style-type: none;
              padding-left: 0px !important;

              li {
                background: #f2f2f2;
                border-radius: 19px;
                padding: 8px 15px;
                margin-right: 20px;

                p {
                  letter-spacing: 0px;
                  color: #263238;
                  font-size: 0.7em !important;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }

    .right {
      // flex: 0.25;

      .download-resume-btn {
        width: 100%;
        padding: 10px 20px;
        border-radius: 20px;
        border: solid 2px var(--green);
        background: transparent;
        color: var(--green);
        font-weight: 600;

        img {
          height: 1em;
          margin-right: 5px;
        }
      }

      .skills {
        padding: 2.5em 0.625em;

        h2 {
          font-size: 1.25em;
          font-weight: 600;
          color: #263238;
        }

        .skill_det {
          .progress-div {
            margin-top: 1.25em;

            .progress {
              .progress-bar {
                background-color: var(--blue) !important;
              }
            }

            .progress-name {
              p {
                font-size: var(--p1);
                font-weight: 500;
                color: #263238;
                float: right;
              }

              span {
                color: #000000;
                opacity: 0.4;
                font-size: var(--p1);
                float: right;
              }
            }

            .skill-name {
              display: flex;
              width: 100%;
              gap: 5px;
            }

            .progress {
              height: 0.625em;
            }
          }
        }
      }

      .evaluator {
        padding: 2.5em 0.625em;

        h2 {
          font-size: 1.5em;
          font-weight: 600;
          color: #263238;
        }

        p {
          font-size: 1.25em;
          font-weight: 500;
          color: #263238;
        }

        .progress-div .progress {
          height: 0.625em;
        }
      }
    }
  }

  .unlockEvaluation {
    z-index: 1;
    font-size: 2rem;
    color: #6c757d !important;
  }

  @media screen and (max-width: 800px) {
    .body_content {
      // flex-direction: column;

      .right {
        margin-top: 20px;
      }
    }
  }

  .unlockRatingEvaluation {
    z-index: 1;
    margin-top: 1%;
    font-size: 2rem;
    position: absolute;
    color: #6c757d !important;
  }

  section.fade-section {
    opacity: 0.12;
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (max-width: 576px) {
    .body_content {
      padding-left: 2em;
      padding-right: 2em;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .body_content .left {
      .work_details .w_sec {
        margin-top: 3em;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .body_content .left {
      .work_details .w_sec {
        margin-top: 3em;
      }

      .profile_img_div {
        flex-direction: column;

        .profile_img {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      .view-resume {
        margin-left: 0;

        a {
          margin-left: 0;
        }
      }
    }
  }
}

button.swal-button.swal-button--confirm.swal-yes {
  background-color: var(--green);
}

.send-message-button {
  position: relative;
  width: 100%;

  button {
    width: 100%;
    color: var(--green);
  }

  .send-message-options {
    position: absolute;
    right: 0;
    top: 76%;
    z-index: 10;
    display: none;
    background: var(--white);
    width: 60%;
    border: 2px solid var(--gray5);
    border-radius: 10px;
    text-align: center;
    overflow: hidden;

    ul {
      padding: 0;
      margin: 0;

      :last-child {
        border-bottom: none;
      }

      li {
        list-style: none;
        border-bottom: 1px solid var(--gray4);
        padding: 0.8rem;

        button {
          padding: 0.2rem 0;
          color: var(--black2) !important;
          display: flex;
          align-items: center;
          justify-content: start;
          flex-direction: row-reverse;
          gap: 0.8rem;

          i {
            font-size: var(--h4) !important;
          }

          &:hover {
            color: var(--green) !important;
          }
        }

        a {
          text-decoration: none;
          color: var(--black2);
          display: flex;
          align-items: center;
          gap: 0.8rem;
          font-size: var(--p1);

          i {
            font-size: var(--h4) !important;
          }

          &:hover {
            color: var(--green);
          }
        }
      }
    }
  }
}

.send-message-button:hover .send-message-options {
  display: block;
}
