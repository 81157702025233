// .steps {
//   .head {
//     .main-heading {
//       color: #263238;
//       font-size: 1.25em;
//       font-weight: 700;
//       text-align: left !important;
//     }

//     .header-btns {
//       button {
//         padding: 10px 16px 10px 16px;
//         border-radius: 8px;
//         border: 1px solid #d0d5dd;
//       }

//       .back {
//         background-color: #fff;
//         margin-right: 5px;
//         color: #344054;
//       }

//       .next {
//         background-color: var(--green);
//         color: #fff;
//         margin-left: 20px;
//       }
//     }
//   }
// }

.personal-details {
  .form-input-group {
    margin: 0.4rem 0;
  }

  .suggestion_box {
    padding: 0;
    position: absolute;
    background: #fff;
    z-index: 2;
    max-width: 450px;
    padding: 0 !important;
    max-height: 150px;
    overflow-y: scroll;
    margin-top: -0.75em;
    box-shadow: 0 7px 8px -4px #d3d3d3;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
      padding: 10px;
      border-bottom: 2px solid #f5f6f7;
    }
  }

  .head {
    .main-heading {
      color: #263238;
      font-size: 1.25em;
      font-weight: 700;
    }

    .header-btns {
      button {
        padding: 10px 16px 10px 16px;
        border-radius: 8px;
        border: 1px solid #d0d5dd;
      }

      .cancel {
        background-color: #fff;
      }

      .back {
        background-color: #fff;
        margin-right: 5px;
        color: #344054;
      }

      .next {
        background-color: var(--green);
        color: #fff;
      }
    }
  }

  .upload_cv {
    display: flex;
    justify-content: center;
    padding: 1.25em;
    border: 1px dotted #dbdbdb;
    border-radius: 1.25em;
    margin-bottom: 2.5em;
    border-radius: 0.625em;
    align-items: center;
    display: flex;
    color: var(--green);

    div {
      margin: 0 1.25em;
      align-items: center;
      display: flex;
      color: var(--green);
    }

    img {
      // width: 1.25em;
      margin-right: 0.625em;
    }

    .upload_resume {
      a {
        font-size: 0.875em;
        font-weight: 600;
        color: var(--green);
        text-decoration: none;
      }

      input {
        position: absolute;
        opacity: 0;
        width: 10%;
      }
    }
  }

  .avatar {
    align-items: center;

    .profile_img {
      width: 5.625em;
      height: 5.625em;
      border-radius: 50%;
      margin-right: 20px;
      background-color: var(--gray2);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: inherit;
      }
    }

    .upload_picture {
      position: relative;

      input {
        position: absolute;
        opacity: 0;
        width: 100%;
      }

      p {
        font-size: 0.875em;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-weight: 600;
        color: var(--green);
      }
    }
  }

  label {
    font-size: var(--p);
    font-weight: 400;
    color: var(--gray4);
    text-wrap: nowrap;
  }

  .input-group {
    border: 1px solid var(--gray5);
    border-radius: var(--border-radius);
    margin: 0.3rem 0;

    input {
      border: none;
      outline: none;
      background-color: transparent;
      box-shadow: none;
      height: 3em;
      font-size: var(--p1);
      font-weight: 500;
      color: #11263c;
    }

    span {
      background-color: transparent;
      border: none;
      height: 100%;
    }
  }

  .form-select {
    border-radius: var(--border-radius);
    border: 1px solid var(--gray5);
    font-size: var(--p1);
    font-weight: 500;
    color: #11263c;
    height: 3em;
  }

  .p-gray {
    font-size: 0.875rem;
    color: var(--gray4);
    margin-bottom: 10px;
  }

  .f_t_box {
    flex-wrap: wrap;
    max-width: 100% !important;
  }

  .f_t {
    display: flex;
    align-items: center;
    background-color: var(--light-blue);
    width: fit-content;
    padding: 3px 1.25em;
    border-radius: 20px;
    margin-right: 0.938em;
    margin-bottom: 0.5em;
  }

  .f_t p {
    color: var(--green);
    font-size: var(--p1);
    font-weight: 500;
    margin-right: 0.75em;
    margin-bottom: 0;
  }

  .f_t img {
    width: 10px;
  }

  .del_jt {
    background: none;
    border: none;
  }
}

.add-skills {
  h5 {
    font-weight: 600;
    font-size: var(--h5);
    color: #263238;
  }

  p {
    font-size: 0.875em;
    font-weight: 400;
    color: #9398a1;
  }

  .top-skill {
    width: auto;
    background-color: #f8f8f8;
    display: flex;
    gap: 17px;
    padding: 8px;
    margin-right: 15px;
    border-radius: var(--border-radius);
    color: #263238;
    font-size: 0.81em;
    font-family: Plus Jakarta Sans;
    font-weight: 500;
  }

  .add_btn {
    display: flex;
    align-items: center;
    font-size: 0.875em;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    color: var(--green);
    margin-top: 0px !important;
    border: 0;
    background: none;

    img {
      margin-right: 10px;
      width: 20px;
    }
  }

  // .skill_box {
  //   display: flex;
  //   margin: 20px 0;
  //   flex-direction: column;
  //   gap: 30px;

  //   .accordion-parent {
  //     display: flex;
  //     gap: 10px;
  //     align-items: center;
  //   }

  //   .delete-btn {
  //     button {
  //       border: none;
  //       background-color: #fff;
  //     }
  //   }

  //   .accordion-item {
  //     border: none !important;
  //     flex: 0.9 1;
  //   }

  //   .accordion-button {
  //     .experience {
  //       position: absolute;
  //       top: 40px;
  //     }
  //   }

  //   .accordion-button:not(.collapsed)::after {
  //     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  //     transform: rotate(-180deg);
  //   }

  //   .accordion-collapse.collapse.show {
  //     border: 1px solid var(--gray5);
  //     border-radius: 5px;
  //     border-top: none;
  //     border-top-right-radius: 0px !important;
  //     border-top-left-radius: 0px !important;
  //   }

  //   .skill_box_det:has(.collapsed) {
  //     border: 1px solid var(--gray5);
  //     border-radius: 5px !important;
  //   }

  //   .skill_box_det {
  //     text-align: left;
  //     display: flex;
  //     align-items: center;
  //     padding: 0 0 14px 0;
  //     flex: 0.6 1;
  //     justify-content: space-between;
  //     border: 1px solid var(--gray5);
  //     border-bottom: none;
  //     border-top-right-radius: 5px;
  //     border-top-left-radius: 5px;
  //     // border-radius: 5px;
  //     text-align: left;
  //     background-color: #fff !important;

  //     button {
  //       color: #1e2532 !important;
  //       font-size: 1em !important;
  //       font-weight: 600 !important;
  //       background-color: #fff !important;
  //       box-shadow: none !important;
  //     }

  //     button:focus {
  //       border-color: #fff !important;
  //       box-shadow: none !important;
  //     }

  //     .heading {
  //       color: #1e2532 !important;
  //       font-size: 1em !important;
  //       font-weight: 600 !important;
  //     }
  //   }
  // }
}

.registration-section-header {
  .title {
    font-size: var(--h3);
    margin-bottom: 20px;
    font-weight: 600;
  }
}

.work-profile {
  // #Awards,
  // #Certification,
  // #Education,
  // #Employment,
  // #Experience,
  // #Skills {
  //   margin: 40px 0;
  // }

  .emp_box {
    display: flex;
    margin: 20px 0;

    div {
      text-align: left;
      display: flex;
      align-items: center;
      padding: 3px 10px;
    }

    img {
      margin-right: 3px;
    }

    // .emp_box_det {
    //   flex: 0.6 1;
    //   justify-content: space-between;
    //   border: 1px solid var(--gray5);
    //   border-radius: var(--border-radius);
    //   text-align: left;

    //   div {
    //     display: flex;
    //     align-items: flex-start;
    //     flex-direction: column;
    //   }
    // }

    button {
      background: none;
      outline: none;
      border: none;
      height: 100%;
      margin: auto 5px;
    }

    p {
      font-size: var(--p1);
      font-weight: 500;
      color: var(--gray4);
    }

    .green_text {
      color: var(--black3);
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 0.875em;
      font-weight: 600;
    }
  }

  h2 {
    font-size: 1.125rem !important;
    font-weight: 600 !important;
    color: var(--black3);
    margin-bottom: 10px !important;
  }

  p {
    color: var(--light-text, #9398a1);
    font-size: var(--h7) !important;
  }

  .add_btn {
    display: flex;
    align-items: center;
    font-size: var(--p1);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    color: var(--green);
    margin-top: 20px;
    border: 0;
    background: none;

    img {
      margin-right: 10px;
      width: 20px;
    }
  }
}

.mobile_verify {
  text-align: center;
  min-height: 80vh;

  img {
    width: 56px;
  }

  h5 {
    color: #101828;
    font-size: 1.9em;
    font-weight: 600;
  }

  p {
    color: #475467;
    font-size: var(--p1);
    font-weight: 400;
    padding: 0 25% 0 25%;
  }

  .resend-mobile {
    font-size: 0.875em !important;
  }

  a {
    color: #14bc9a;
    font-size: var(--p1);
    font-weight: 600;
    text-decoration: none;
  }

  // .otp-sec {
  //   display: flex;
  //   gap: 8px;
  //   justify-content: center;

  //   .otp {
  //     display: inline-block;
  //     width: 50px;
  //     height: 50px;
  //     text-align: center;
  //     border-radius: 8px;
  //     padding: 10px 8px 10px 8px;
  //     border: 2px solid var(--green);
  //     color: var(--green);
  //     font-size: 2em;
  //     font-weight: 500;
  //     outline: none;
  //   }
  // }

  .verify-mobile-btn {
    border-radius: 8px;
    width: 47%;
    border: 1px solid;
    color: #fff;
    background-color: var(--green);
    padding: 10px 18px 10px 18px;
  }
}

.email_verify {
  text-align: center;
  min-height: 80vh;

  h5 {
    color: #202020;
    font-size: 1.5em;
  }

  p {
    color: #2c2c2c;
    font-size: var(--p1);
  }

  a {
    color: #14bc9a;
    font-size: var(--p1);
    font-weight: 600;
    text-decoration: none;
  }

  // .otp-sec {
  //   display: flex;
  //   gap: 8px;
  //   justify-content: center;

  //   .otp {
  //     display: inline-block;
  //     width: 50px;
  //     height: 50px;
  //     text-align: center;
  //     border-radius: 8px;
  //     padding: 10px 8px 10px 8px;
  //     border: 2px solid var(--green);
  //     color: var(--green);
  //     font-size: 2em;
  //     font-weight: 500;
  //     outline: none;
  //   }
  // }

  // .verify-mobile-btn {
  //   border-radius: 8px;
  //   width: 47%;
  //   border: 1px solid;
  //   color: #fff;
  //   background-color: var(--green);
  //   padding: 10px 18px 10px 18px;
  // }
}

// Under Review page

.under-review {
  text-align: center;

  img {
    width: 158px;
    height: 122px;
  }

  h5 {
    font-size: 1.5em;
    font-weight: 600;
  }

  p {
    font-size: var(--p1);
    font-weight: 400;
  }
}

@media only screen and (max-width: 425px) {
  .mobile_verify {
    .otp-sec {
      .otp {
        width: 60px;
        height: 60px;
      }
    }
  }
}
