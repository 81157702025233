.auth-modal-content {
    padding: 0;
    overflow: auto;
    height: 75vh;
    margin-block: 10px;
}

.auth-modal-body {
    padding: 0;
    min-height: 65vh;
    display: flex;

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
}

.auth-container-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 10px;

    @media screen and (min-width: 768px) {
        &.register {
            width: 100%;
            // gap: 1rem;
        }

        width: 55%;
        padding-top: 20px;
        padding-bottom: 20px;

        .register-or-section {
            font-size: 1rem;
        }
    }

    .logo {
        width: 200px;
    }

    .title {
        font-size: 1.25rem;
        margin-top: 10px;
        font-weight: 600;
    }

    .form-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;
        gap: 1rem;
        margin-top: 10px;

        .individual-input-form {
            width: 100%;
            display: flex;
            padding: 5px 10px;
            border-radius: 5px;
            align-items: center;
            border: 1px solid #DCDCDC;
            gap: 6px;
        }

        .remember-forget-password {
            display: flex;
            justify-content: space-between;
            font-size: 0.875rem;
        }

        .body-links {
            cursor: pointer;
        }
    }


    .profile-type-wrapper {
        display: flex;
        gap: 16px;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;

        .individual-profile {
            border: 1px solid #E2E8F0;
            border-radius: 10px;
            cursor: pointer;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            max-width: 222px;
            width: 100%;

            &:hover {
                border: 1px solid var(--green);
            }

            &-img {
                max-width: 35px;
                width: 100%;
                height: auto;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &-content {
                .profile-type {
                    font-weight: 600;

                }

                .profile-description {
                    font-size: 0.875rem;
                    color: #9398A1;

                }

            }
        }
    }

    .register-or-section {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 0.875rem;
    }
}