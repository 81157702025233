.referrals {
    // min-height: 100vh;
    // width: 100vw;
    padding-top: 6.0em;
    font-size: 13px;

    // .body_section {
    //     background-color: var(--white);
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     padding: 0px 10rem;
    // }

    .main-heading {
        h2 {
            font-weight: 700;
            font-size: 1.25em;
            line-height: 1.75em;
            color: #263238;
        }
    }

    .heading {
        background: #F9F9F9;
        border-radius: 16px;
        padding: 20px 20px 20px 20px;

        p {
            font-weight: 500;
            font-size: var(--p1);
            line-height: 20px;
            color: #1B2124;
        }

        span {
            font-weight: 500;
            font-size: var(--p1);
            line-height: 20px;
            text-align: justify;
            color: #9398A1;
        }
    }

    .select {
        label {
            font-weight: 700;
            font-size: 0.875em;
            line-height: 18px;
            color: #263238;
        }

        select {
            border: 1px solid #DBDBDB;
            border-radius: 6px;
            font-size: var(--p1);
        }
    }

    .basic-detail {
        p {
            font-weight: 600;
            font-size: 1.125em;
            line-height: 24px;

            color: #202020;
        }

        span {
            font-weight: 400;
            font-size: var(--p1);
            line-height: 20px;
            display: flex;
            align-items: center;

            color: #9398A1;
        }
    }

    .reward {
        p {
            font-weight: 500;
            font-size: var(--p1);
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #1B2124;
        }
    }

    .link {
        position: relative;
        input {
            background: #FFFFFF;
            width: 100%;
            height: 3em;
            border: 1px solid #DCDCDC;
            border-radius: 8px;
            padding: 10px;
        }

        .copy-btn {
            position: absolute;
            right: 13px;
            top: 50%;
            transform: translateY(-50%);
            background: #fff;
            padding: 0 5px 0 5px;
            text-decoration: none;
            font-weight: 500;
            font-size: 0.875rem;
            color: #14BC9A;
        }

        button {
            padding: 8px 16px;
            background: #14BC9A;
            border: 1px solid #14BC9A;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            color: #fff;
        }
    }



    @media only screen and (max-width: 767px) {

        .invite-btn {
            margin-top: 10px;
        }
        // .body_section {
        //     padding: 0px 1em;
        // }


        // .result_section {
        //     overflow-x: auto;
        // }
    }

    // @media (min-width: 768px) and (max-width: 1023px) {

    //     .body_section {
    //         padding: 0px 8em;
    //     }

    //     .result_section {
    //         overflow-x: auto;
    //     }
    // }

}