.footer_container {
  font-size: 16px;

  footer {
    position: relative;
    bottom: 0px;
    width: 100vw;
    background-color: var(--black4);
    color: var(--white);
    font-size: var(--p1);
    text-align: center;
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 11px !important;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }
}