.appointment_main {
  display: flex;
  flex-direction: column;
  padding-top: 83px !important;
  margin-bottom: 28px;
  min-height: calc(100vh - 110px);
}

.form_buttons input {
  background-color: var(--green);
  color: var(--white);
  border: 0px;
  outline: none;
  padding: 8px 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  border-radius: var(--border-radius);
  font-size: 0.875em;
  font-weight: 500;
}

.back_btn {
  font-size: 14px !important;
  font-weight: 500;
  box-shadow: none !important;
}

.back_btn:focus {
  background-color: #14bc9a !important;
}

.back_btn:hover {
  background-color: #14bc9a !important;
  outline: none;
}

.next-btn {
  font-size: 14px !important;
  color: #fff;
  font-weight: 500;
}

.schedule_heading {
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

// .candidate-pills-wrapper {
//   border-bottom: 1px solid #eaecf0;
//   border-radius: 4px;
//   // width: 100%
//   // margin-bottom: 50px !important;
// }
.form-select-lg {
  font-size: 0.875em;
}

.candidate-tab-item {
  .nav-link {
    color: #667085 !important;
    font-size: 16px;
    font-weight: 600;
  }

  .active {
    background-color: transparent !important;
    border-bottom: 2px solid #14bc9a;
    border-radius: 0px !important;
    color: #14bc9a !important;
    font-size: 16px;
    font-weight: 600;
  }
}

.sc_appointment {
  gap: 20px;
  text-wrap: nowrap;
}

.navbar-div {
  font-size: 16px;
  // margin-right: 1rem;

  .navbar {
    // position: fixed;
    // width: 100vw;
    // padding-bottom: 0px;
    // padding-top: 0px;
    // background-color: var(--white);
    // -webkit-box-shadow: 0px 1px 5px var(--black);
    // -moz-box-shadow: 0px 1px 5px var(--black);
    // box-shadow: 0px 1px 5px var(--black);
    // z-index: 1001;
  }

  .navbar-toggler:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

 

  .navbar {
    // padding: 0px 10rem;
  }

  .container-fluid {
    // width: 90%;
    margin: auto;
    max-width: 1660px;
    // padding: 0 20px;
  }

  .nav-item {
    button {
      background-color: #fff;
      border: none;
    }

    button .active-green {
      border-bottom: 4px solid var(--green);
    }
  }

  .nav-link {
    padding: 1rem;
    font-weight: 500;
    font-size: var(--p1);
    color: var(--gray4);
    cursor: pointer;
    margin-right: 1rem;
  }

  .nav-link.active {
    border-bottom: 3px solid var(--red);
    color: var(--black2);
    font-weight: 600;
  }

  .nav-link.active-green {
    border-bottom: 3px solid var(--green);
    color: var(--green);
    font-weight: 600;
  }

  .nav-link.active-green:hover {
    color: var(--green);
  }

  .btn-login {
    border: 1px solid var(--red);
    color: var(--red);
    margin-right: 20px;
    padding: 3px 20px;
    border-radius: var(--border-radius);
    font-weight: 500;
    font-size: var(--p1);
  }

  .btn-login.active {
    background-color: var(--red);
    color: var(--white);
    padding: 3px 20px;
    border-radius: var(--border-radius);
    font-weight: 500;
    font-size: 1.125em;
  }

  .btn-register.active {
    background-color: var(--red);
    color: var(--white);
    border-radius: var(--border-radius);
    font-weight: 500;
    font-size: var(--p1);
  }

  .btn-register {
    border: 1px solid var(--red);
    color: var(--red);
    // margin-right: 20px;
    padding: 3px 20px;
    border-radius: var(--border-radius);
    font-weight: 500;
    font-size: var(--p1);
  }

  .buttons {
    display: flex;
  }

  .navbar-brand {
    cursor: pointer;
    display: flex;

    img {
      margin: auto;
      width: 6.5em;
    }
  }

  @media only screen and (max-width: 375px) {

    .btn-login,
    .btn-register,
    .btn-login.active {
      padding: 3px 10px;
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 990px) {
    font-size: 11px !important;

    .btn-login,
    .btn-register,
    .btn-login.active {
      margin: 10px 10px 10px 0;
    }

    .navbar-toggler {
      border: 0px;
    }

    .navbar-brand {
      margin-right: 0;

      img {
        width: 4.6em;
      }
    }

    .navbar .container-fluid {
      padding: 8px 0;
      flex-wrap: nowrap;
    }
  }

  @media only screen and (max-width: 991px) {
    .navbar-collapse {
      position: absolute;
      top: 100%;
      width: 100%;
      left: 0;
      right: 0;
      padding: 15px 0;
      background: #ffffff;
      box-shadow: 0px 1px 5px var(--black);
    }
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (min-width: 1601px) {
    .container-fluid {
      max-width: unset;
    }
  }
}