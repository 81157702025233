.result_section {
  width: 100%;
  height: 70%;
  background-color: var(--white);
  display: flex;
.main-div{
  width: inherit;
}
  .result_feed {
    flex: 1 1;
    width: 100%;
    position: relative;

    // &::-webkit-scrollbar {
    //   width: 0;
    //   /* Remove scrollbar space */
    //   background: transparent;
    // }

    .result_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      // z-index: 100;
      width: 100%;
      padding-top: 10px;

      h2 {
        font-size: 1.25em;
        font-weight: 600;
        color: var(--black3);
      }

      p {
        font-size: var(--p1);
        white-space: nowrap;
      }

      .result_header_sort {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-right: 1.4em;

        .dropdown {
          display: flex;
          align-items: center;
          padding: 2px 0.5rem;

          .dropdown-toggle {
            font-size: 0.875em;
            font-weight: 600;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 990px) {
  .result_feed {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    position: relative;

    .result_header {
      flex-wrap: wrap;
    }
    .tab-content {
      // overflow-y: scroll;
    }
  }
}