.show_jobs {
  // height: 70vh;
  // width: 100vw;
  // padding-top: 50px;
  // margin-top: 38px;
  // max-height: 100vh;
  // overflow: hidden;

  // .body_section {
    // background-color: var(--white);
    // width: 100vw;
    // min-height: 90vh;
    // max-height: 55vh;
    // overflow: auto;
  // }

  /* Search bar section */

  .search {
    display: flex;
    flex-direction: column;
    // padding-top: 3.238em;
  }

  .search h2 {
    width: 90%;
    // margin: auto;
    // font-size: 1.25em;
    color: var(--black3);
    font-weight: 600;
  }

  .no_jobs {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 3.125em;
    }

    h2 {
      font-size: 1.5em;
      font-weight: 600;
      color: #263238;
      margin: 1.25em;
    }
  }

  .job_type_search {
    border-right: 2px solid var(--gray5);
    flex: 0.3;

    .search_box {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 1.25em;
        margin: 0 10px;
        flex: 0.1;
      }

      input {
        outline: none;
        height: 100%;
        flex: 0.9;
        border: 0px;
        border-radius: 20px;
        font-size: 1.125em;
        font-weight: 500;
        color: var(--gray4);
        min-width: 10%;
      }
    }
  }

  .search_button {
    // flex: none;
    // background-color: var(--green);
    // border-bottom-right-radius: 10px;
    // border-top-right-radius: 10px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // outline: 2px solid var(--green);
    // cursor: pointer;
  }

  // .search_button p {
  //   margin-bottom: 0px;
  //   color: var(--white);
  //   font-weight: 500;
  //   font-size: 0.875em;
  // }

  /*  Result section */

  .result_section {
    width: 100%;
    background-color: var(--white);
    // margin: 1.875em auto 0px auto;
    // padding: 0px 10rem;
    display: flex;
    z-index: 0 !important;
  }


  .search {
    // padding: 0px 10rem;
  }

  @media only screen and (max-width: 576px) {
    .result_section {
      // padding: 0px 2em;
    }

    .search {
      // padding: 0px 2em;
    }

    .search_section {
      padding: 0px 0px;
    }
  }

  footer {
    position: relative;
    bottom: 0;
    width: 100%;
  }

  // @media only screen and (max-width: 1280px) {
  //   font-size: 12px !important;
  // }

  // @media only screen and (max-width: 1024px) {
  //   font-size: 11px !important;
  // }

  // @media only screen and (max-width: 1600px) {
  //   font-size: 13px;
  // }

  .footer_upper {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: 0;
  }

  // @media only screen and (max-width: 767px) {

  //   .list-group-item.active,
  //   .list-group-item {
  //     font-size: 1.2em;
  //   }
  // }
}