.navbar-div {
  font-size: 16px;

  .navbar-nav {
    margin-left: 2em;
    gap: 20px;
  }

  .navbar {
    // position: fixed;
    // width: 100vw;
    // padding-bottom: 0px;
    // padding-top: 0px;
    // background-color: var(--white);
    // -webkit-box-shadow: 0px 1px 5px var(--black);
    // -moz-box-shadow: 0px 1px 5px var(--black);
    // box-shadow: 0px 1px 5px var(--black);
    // z-index: 1001;
  }

  .navbar-toggler:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  @media only screen and (max-width: 768px) {
    .navbar-nav {
      margin-left: 0;
    }
  }

  .navbar {
    // padding: 0px 10rem !important;


  }

  .container-fluid {
    // width: 90%;
    margin: auto;
    max-width: 1660px !important;
    // padding: 0 20px;
  }

  .nav-item {
    button {
      background-color: #fff;
      border: none;
    }

    .logged-naving {
      padding: 1rem 0px !important;

      img {
        margin: auto !important;
      }
    }

    button .active-green {
      border-bottom: 4px solid var(--green);
    }
  }

  .nav-link {
    padding: 1rem;
    font-weight: 500;
    font-size: var(--p1);
    color: var(--gray4);
    cursor: pointer;
  }

  .nav-link.active {
    border-bottom: 3px solid var(--red);
    color: var(--black2);
    font-weight: 600;
  }

  .nav-link.active-green {
    border-bottom: 3px solid var(--green);
    color: var(--green);
    font-weight: 600;
  }

  .nav-link.active-green:hover {
    color: var(--green);
  }

  .btn-login {
    border: 1px solid var(--red);
    color: var(--red);
    margin-right: 20px;
    padding: 3px 20px;
    border-radius: var(--border-radius);
    font-weight: 500;
    font-size: var(--p1);
  }

  .btn-login.active {
    background-color: var(--red);
    color: var(--white);
    padding: 3px 20px;
    border-radius: var(--border-radius);
    font-weight: 500;
    font-size: 1.125em;
  }

  .btn-register.active {
    background-color: var(--red);
    color: var(--white);
    border-radius: var(--border-radius);
    font-weight: 500;
    font-size: var(--p1);
  }

  .btn-register {
    border: 1px solid var(--red);
    color: var(--red);
    // margin-right: 20px;
    padding: 3px 20px;
    border-radius: var(--border-radius);
    font-weight: 500;
    font-size: var(--p1);
  }

  .buttons {
    display: flex;
  }

  .navbar-brand {
    cursor: pointer;
    display: flex;

    img {
      margin: auto;
      width: 6.5em;
    }
  }

  @media only screen and (max-width: 375px) {

    .btn-login,
    .btn-register,
    .btn-login.active {
      padding: 3px 10px;
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 990px) {
    font-size: 11px !important;

    .btn-login,
    .btn-register,
    .btn-login.active {
      margin: 10px 10px 10px 0;
    }

    .navbar-toggler {
      border: 0px;
    }

    .navbar-brand {
      margin-right: 0;

      img {
        width: 4.6em;
      }
    }

    .navbar .container-fluid {
      padding: 8px 0;
      flex-wrap: nowrap;
    }
  }

  @media only screen and (max-width: 991px) {
    .navbar-collapse {
      position: absolute;
      top: 100%;
      width: 100%;
      left: 0;
      right: 0;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 1px 5px var(--black);
    }
  }

  @media only screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media only screen and (min-width: 1601px) {
    .container-fluid {
      max-width: unset;
    }
  }
}